import React from 'react';
import { styled } from '@mui/material/styles';
import { Switch } from "@mui/material";


const MaterialUISwitch = styled(Switch)(({ theme }) => ({

    width: 62,
    height: 34,
    padding: 7,
    '& .MuiSwitch-switchBase': {
        margin: 1,
        padding: 0,
        transform: 'translateX(6px)',
        '&.Mui-checked': {
            color: '#fff',
            transform: 'translateX(30px)',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#ccc',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#0d7e5e',
        width: 25,
        height: 25,
        transform: 'translateY(3px)',
    },
    '& .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#ccc',
        borderRadius: 20 / 2,
    },
}
));



export default MaterialUISwitch