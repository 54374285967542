export default [
    {
        title: "Common variables",
        link: "common-variables",
        list: [
            { name: "logo_url", description: "The store logo uploaded by the merchant." },
            { name: "store_name", description: "The name of the store on Shopyxa." },
            { name: "user_name", description: "The full name of the customer." },
            { name: "support_email", description: "The support email given by the store to customers." },
            { name: "app_name", description: "The platform name which is Shopyxa." },
            { name: "domain", description: "The URL of the store on Shopyxa." },
        ]
    },
    {
        title: "Order place email",
        link: "orderConfirmation",
        list: [
            { name: "order_number", description: "A unique identifier is assigned to each order automatically." },
            { name: "total_price", description: "The sum of all items' prices in an order, representing the total amount to be paid by the customer." },
            { name: "order_item_images", description: "Visual representations of the products purchased within an order." },
            { name: "title", description: `The descriptive name or label of a product, such as "Black shirt".` },
            { name: "variant_name", description: `The specific variation or option of a product, such as "color" or "size".` },
            { name: "quantity", description: "The number of units of a product purchased." },
            { name: "price", description: "The individual price of a single unit of a product." },
            { name: "subtotal_price", description: "The total cost of the items in order before taxes and applying discount codes and shipping charges are applied." },
            { name: "shipping_price", description: "The cost charged for shipping the items in an order." },
            { name: "discount", description: "The price reduction applied to an order, typically expressed as a percentage or fixed amount." },
            { name: "total_tax", description: "The sum of all taxes applied to an order." },
            { name: "tax_type", description: "The category or classification of tax, such as IGST or SGST." },
            { name: "tax_rate", description: "The percentage rate at which tax is applied to a transaction, such as 18% or 10%." },
            { name: "first_name", description: "The given name of the customer." },
            { name: "last_name", description: "The surname or family name of the customer." },
            { name: "company", description: "The name of the company provided by the customer in the address section." },
            { name: "address1", description: "The primary lines of the customer's address." },
            { name: "address2", description: "The secondary lines of the customer's address." },
            { name: "city", description: "City of the customer" },
            { name: "zip_code", description: "Postal code of the customer" },
            { name: "country", description: "Country of the customer" },
            { name: "billing_address.first_name", description: "First name in customer's billing address" },
            { name: "billing_address.last_name", description: "Last name in customer's billing address" },
            { name: "billing_address.company", description: "The name of company in customer's billing address" },
            { name: "billing_address.address1", description: "The primary lines customer's billing address" },
            { name: "billing_address.address2", description: "The secondary lines customer's billing address" },
            { name: "billing_address.city", description: "City in customer's billing address" },
            { name: "billing_address.zip_code", description: "Postal code in customer's billing address" },
            { name: "billing_address.country", description: "Country in customer's billing address" },
            { name: "payment_method", description: "The method of payment chosen by the customer such as COD, Razorpay, etc." },
            { name: "payment_id", description: "The unique identifier associated with a payment transaction of an order." },
        ]
    },
    {
        title: "Order confirmation email",
        link: "confirmation",
        list: [
            { name: "order_number", description: "Order serial number." }
        ]
    },
    {
        title: "Order out for delivery email",
        link: "outfordelivery",
        list: [
            { name: "order_number", description: "Order serial number." },
            { name: "order_item_images", description: "Images of the product purchased." },
            { name: "title", description: "Title of a product like (Black shirt)." },
            { name: "variant_name", description: "Name of the product variant like(small, medium)." },
            { name: "quantity", description: "Quantity of product purchased." },
            { name: "price", description: "Price of product purchased." },
        ]
    },
    {
        title: "Order shipped email",
        link: "orderShipped",
        list: [
            { name: "order_number", description: "Order serial number." },
            { name: "order_item_images", description: "Images of the product purchased." },
            { name: "title", description: "Title of a product like (Black shirt)." },
            { name: "variant_name", description: "Name of the product variant like(small, medium)." },
            { name: "quantity", description: "Quantity of product purchased." },
            { name: "price", description: "Price of product purchased." },
        ]
    },
    {
        title: "Order cancel email",
        link: "cancelOrder",
        list: [
            { name: "order_number", description: "Generated order serial number." },
            { name: "cancel_reason", description: "Cancellation Reason  given by the merchant." },
            { name: "order_item_images", description: "Images of product purchased." },
            { name: "title", description: "Title of a product like (Black shirt)." },
            { name: "variant_name", description: "Name of the product variant like(small, medium)." },
            { name: "quantity", description: "Quantity of product purchased." },
            { name: "price", description: "Price of product purchased." },
        ]
    },
    {
        title: "Order refund email",
        link: "refundOrder",
        subtitle: "Email notification sent to the customer regarding a refund for an order.",
        list: [
            { name: "order_number", description: "Unique identifier assigned to the refunded order." },
            { name: "variant_name", description: "The specific variation or option of the product being refunded." },
            { name: "refundReason", description: "Explanation or justification provided for the refund request." },
        ]
    },
    {
        title: "Order tracking email",
        link: "orderTracking",
        list: [
            { name: "order_number", description: "Unique identifier assigned to the order." },
            { name: "first_name", description: "The given name of the customer." },
            { name: "courier_name", description: "Name of the courier company responsible for shipping the order." },
            { name: "track_number", description: "A unique identifier assigned to track the shipment." },
            { name: "description", description: "Any additional notes or comments entered by the merchant." },
        ]
    },
    {
        title: "Draft order invoice email",
        link: "draftorderTemplete",
        list: [
            { name: "custom_message", description: "Any personalized message entered by the merchant." },
            { name: "name", description: "Invoice number or identifier." },
            { name: "order_item_images", description: "Visual representations of the purchased products." },
            { name: "title", description: `Descriptive name of the product, e.g., "Black shirt."` },
            { name: "variant_name", description: `Specific variation or option of the product, like "small" or "medium."` },
            { name: "quantity", description: "Quantity of the purchased product." },
            { name: "price", description: "Individual price of the product purchased." },
            { name: "subtotal_price", description: "Subtotal cost of the order before taxes and shipping." },
            { name: "shipping_price", description: "Cost charged for shipping the order." },
            { name: "discount", description: "Price reduction applied to the order." },
            { name: "total_tax", description: "Sum of all taxes applied to the order." },
            { name: "tax_type", description: "Category or classification of tax, such as IGST or SGST." },
            { name: "tax_rate", description: "Percentage rate at which tax is applied." },
            { name: "total_price", description: "Total amount to be paid for the order." },
        ]
    },
    {
        title: "Customer invite email ",
        link: "storeinviteTemplete",
        list: [
            { name: "store_name", description: "Name of the store." },
        ]
    },
    {
        title: "Return/exchange request email",
        link: "returnExchangeRequest",
        list: [
            { name: "user_name", description: "First name of the customer initiating the return or exchange request." },
            { name: "mode", description: "The type of request being made, whether it's for a Return or an Exchange." },
            { name: "order_number", description: "Unique identifier assigned to the order associated with the return or exchange." },
            { name: "title", description: `Descriptive name of the product being returned or exchanged, such as "Black shirt."` },
            { name: "variant_name", description: `Specific variation or option of the product, like "small" or "medium."` },
            { name: "order_quantity", description: "Quantity of the product requested to be returned or exchanged." },
            { name: "price", description: "The cost of the product purchased, relevant for processing the return or exchange." },
        ]
    },
    {
        title: "Return/exchange request approved/declined email",
        link: "returnExchangeRequestApproved",
        list: [
            { name: "user_name", description: "The first name of the customer making the request." },
            { name: "mode", description: "The type of request being made, such as Return or Exchange." },
            { name: "order_number", description: "The unique identifier assigned to the order." },
            { name: "reason", description: `The explanation or justification for declining the request.` },
            { name: "title", description: `The descriptive name or title of the product, such as "Black shirt".` },
            { name: "variant_name", description: `The specific variation or option of the product, like "small" or "medium".` },
            { name: "order_quantity", description: "The quantity of the requested product to be changed." }
        ]
    },
    {
        title: "Plan subscription email",
        link: "plan-subscription",
        list: [
            { name: "plan_name", description: "Name of the subscription plan chosen by the user." },
            { name: "next_due_date", description: "The date when the next payment for the subscription plan is due." },
            { name: "store_name", description: "Name of the online store or business." }
        ]
    },
    {
        title: "Account trial Email",
        link: "account-trial",
        list: [
            { name: "customer_name", description: "The name of the customer or user." },
            { name: "store_name", description: "The name of the online store or business." }
        ]
    },
    {
        title: "Abandoned checkout Email",
        link: "abandonedCheckout",
        list: [
            { name: "view_cart", description: "The web page allows customers to review items in their cart before checkout." }
        ]
    },
]