import React, { useEffect, useState, useRef, useCallback } from 'react';
import {
    Box, Button, Grid, Menu, MenuItem, Card, CardContent, CardActionArea, CardMedia,
    FormControl, InputAdornment, Divider, FormControlLabel,
    Checkbox, Radio, FormLabel, RadioGroup, FormHelperText, Typography,
    FormGroup, Select, TextField, InputBase, Container, InputLabel,
    Avatar, ListItemAvatar, ListItemText, ListItemButton, ListItem, List, IconButton, OutlinedInput,
    Chip, Badge, styled, ListItemIcon, LinearProgress, Popover
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { Link, useNavigate, useParams } from "react-router-dom";

// icons 
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import CircleIcon from '@mui/icons-material/Circle';
import CancelIcon from '@mui/icons-material/Cancel';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import PrintIcon from '@mui/icons-material/Print';
import states from "../../../common/CommonJason/states";

import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import MoreVertIcon from '@mui/icons-material/MoreVert';
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import DoneIcon from '@mui/icons-material/Done';

import * as Api from "../../../../Container/config/Apis"
import config from '../../../../Container/config';
import _ from "underscore";
import moment from "moment";
import { useSnackbar } from 'notistack';
import CurrencyFormat from 'react-currency-format';

import DialogAlert from '../../../common/Alerts/Dialog';
import { useSelector } from 'react-redux';
import noImage from '../../../../Assets/images/no_image.jpg';
import PopupModal from '../../../common/PopupModal/PopupModal';


// common components

// file drag drop  
const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 22,
    height: 22,
    border: `1px solid ${theme.palette.background.paper}`,
    objectFit: "contain"
}));


const AbandonedDetail = () => {
    const dataVal = useSelector((state) => state.dataValue)
    console.log("dataVal: ", dataVal)
    const param = useParams()
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar();
    const snack = (msg, variant) => {
        enqueueSnackbar(msg, { variant });
    }

    const [openModal, setOpenModal] = useState(false);
    const [modalContent, setModalContent] = useState({
        type: "",
        modalTitle: ""
    })

    const [loadingBar, setloadingBar] = useState(true)
    const [orderData, setOrderData] = useState({})
    const [copied, setcopied] = useState({
        email: false,
        phone: false,
        view_cart: false
    })
    const [anchorEl, setAnchorEl] = React.useState({
        order: null,
        button1: null,
        button2: null,
        moreActionBtn: null,
        location: null,
    });
    const open3 = Boolean(anchorEl.moreActionBtn);
    
    const [returnData, setReturnData] = useState({})
    const [deleteType, setDeleteType] = useState({})
    const [showDialog, setOpen] = React.useState(false);
    const [content, setContent] = React.useState({
        title: "Are you sure?",
        message: "",
        type: ""
    });

    const closeDialog = () => {
        setOpen(false);
        ModalClose()
    }

    const ModalClose = () => {
        setOpenModal(false)
    }


    const handleClick = (event, data) => {
        setAnchorEl({ ...anchorEl, [data]: event.currentTarget });
    };
    const handleClose = () => {
        setAnchorEl({
            order: null,
            button1: null,
            button2: null,
            moreActionBtn: null,
            location: null,
        });
    };



    const cancelMail = () => {
        setOpen(true)
        setContent({
            ...content,
            message: "You want to cancel reminder mails.",
            type: "cancel"
        })
    }

    const sendMail = () => {
        setOpen(true)
        setContent({
            ...content,
            message: "You want to send reminder mail.",
            type: "send"
        })
    }

    const confirmDialog = () => {
        if (content.type == "send") {
            let data = {
                ...orderData?.storeDetails,
                cart_id: orderData?.cart_id,
            }
            console.log("send data", data)
            updateEmailStatusFn(data)
        }
        if (content.type == "cancel") {
            cancelFn()
        }
    }

    const updateEmailStatusFn = (body) => {
        Api.PostApi(config.CHECKOUT_SEND_MAIL, body).then(res => {
            console.log("res data: ", res)
            if (res.error === true) {
                snack(res.response.data.error, 'error');
            } else {
                if (res.data.data) {
                    snack(res.data.data, 'success');
                    getOrderData(param.id)
                    closeDialog()
                }
            }
        })
    }
    const cancelFn = () => {
        Api.GetApi(`${config.CHECKOUT_CANCEL_MAIL}/${param.id}`).then(res => {
            console.log("res data: ", res)
            if (res.error === true) {
                snack(res.response.data.error, 'error');
            } else {
                if (res.data.data) {
                    snack("Updated successfully", 'success');
                    getOrderData(param.id)
                    closeDialog()
                }
            }
        })
    }
    const getOrderData = (id) => {
        Api.GetApi(`${config.CHECKOUT_ORDERS_DETAIL}/${id}`).then(res => {
            setTimeout(() => {
                setloadingBar(false)
            }, 500);
            console.log("res data: ", res)
            if (res.error === true) {
                snack(res.response.data.error, 'error');
            } else {
                if (res.data.data) {
                    let resData = res.data.data
                    if (resData?.abundant_cart && !_.isEmpty(resData?.abundant_cart)) {
                        resData = {
                            ...resData,
                            ...resData?.abundant_cart
                        }
                    } else if (resData?.abundant_order && !_.isEmpty(resData?.abundant_order)) {
                        resData = {
                            ...resData,
                            ...resData?.abundant_order,
                            cart_items: resData?.abundant_order?.line_items || [],
                            Customer: resData?.abundant_order?.customer || {}
                        }
                    }
                    setOrderData(resData)
                }
            }
        })
    }

    const copiedFn = (type) => {
        setcopied({ ...copied, [type]: true })
        setTimeout(() => {
            setcopied({
                email: false,
                phone: false,
                view_cart: false,
            })
        }, 2000);
    }

    useEffect(() => {
        if (param.id) {
            getOrderData(param.id)
        }
    }, [])


    console.log("orderData: ", orderData)
    console.log("copied: ", copied)


    return (
        <>
            {loadingBar ?
                <LinearProgress className="top_loader" color="info" sx={{ mt: "-25px", mb: "21px" }} />
                :
                !_.isEmpty(orderData) ?
                    <Container maxWidth="lg">
                        <Box sx={{ mb: 2 }}>
                            <Grid container spacing={2} columns={12}>
                                <Grid item md={8}>
                                    <Box>
                                        <Button component={Link} variant="text" to="/admin/orders/checkouts" color="secondary" startIcon={<ArrowBackIosIcon />}>
                                            {param.id}
                                        </Button>
                                        <Chip sx={{ height: "26px", textTransform: "capitalize", fontSize: "12px !important", ml: 3 }}
                                            label={orderData?.email_status && orderData?.email_status.toLowerCase() == "sent" ? `Email sent` : orderData?.email_status.charAt(0).toUpperCase() + orderData?.email_status.slice(1).toLowerCase().split("-").join(" ")}
                                            variant={orderData?.email_status && orderData?.email_status.toLowerCase() == "not sent" || orderData?.email_status.toLowerCase() == "scheduled" ? "outlined" : "contained"}
                                            color={orderData?.email_status && orderData?.email_status.toLowerCase() == "sent" ? 'success' : orderData?.email_status.toLowerCase() == "scheduled" ? 'primary' : orderData?.email_status.toLowerCase() == "not sent" ? 'warning' : 'error'}
                                        />
                                        <Chip sx={{ height: "26px", textTransform: "capitalize", fontSize: "12px !important", ml: 2 }}
                                            label={orderData?.status && orderData?.status == "sent" ? `Email sent` : orderData?.status.charAt(0).toUpperCase() + orderData?.status.slice(1).split("-").join(" ")}
                                            variant={orderData?.status && orderData?.status == "not-sent" || orderData?.status == "scheduled" ? "outlined" : "contained"}
                                            color={orderData?.status && orderData?.status == "sent" ? 'success' : orderData?.status == "scheduled" ? 'primary' : orderData?.status == "not-sent" ? 'warning' : 'error'}
                                        />
                                    </Box>
                                    <Typography variant="subtitle2" component="div" sx={{ mt: 1 }}>{moment(orderData?.created_at).format("llll")}</Typography>
                                </Grid>
                                <Grid item md={4} sx={{ textAlign: "end" }}>
                                    {orderData?.status == "recovered" ?
                                        <Button component={Link} variant="text" color="primary" to={`/admin/orders/${orderData?.order_id}`}>
                                            View order
                                        </Button>
                                        : null
                                    }
                                </Grid>
                            </Grid>
                        </Box>
                        <Box>
                            <Grid container spacing={2} columns={12}>
                                <Grid item md={8}>
                                    {orderData?.status !== "recovered" ?
                                        <Card sx={{ mb: 2 }}>
                                            <CardContent>
                                                <Typography variant='p' component={"strong"}>Email your user this link to recover their cart</Typography>
                                                {orderData?.storeDetails && orderData?.storeDetails?.view_cart ?
                                                    <Box sx={{ mt: 1 }}>
                                                        <Grid container spacing={2} columns={12} >
                                                            <Grid item sm={11} xs={10} sx={{ whiteSpace: "nowrap", overflow: "hidden" }}>
                                                                <input type='text' className='view_cart_input' readOnly value={orderData?.storeDetails?.view_cart} />
                                                            </Grid>
                                                            <Grid item sm={1} xs={2}>
                                                                <IconButton aria-label="copy" sx={{ width: "35px", height: "35px" }}>
                                                                    {/* <ContentCopyOutlinedIcon sx={{ width: "15px" }} /> */}
                                                                    <CopyToClipboard text={orderData?.storeDetails?.view_cart} onCopy={() => copiedFn('view_cart')}>
                                                                        {copied.view_cart ?
                                                                            <DoneIcon sx={{ width: "15px" }} />
                                                                            :
                                                                            <ContentCopyOutlinedIcon sx={{ width: "15px" }} />
                                                                        }
                                                                    </CopyToClipboard>
                                                                </IconButton>
                                                            </Grid>
                                                        </Grid>
                                                        {orderData?.email_status && orderData?.email_status.toLowerCase() == "not-sent" ?
                                                            <Button component={Link} variant="outlined" color="primary" onClick={sendMail}>
                                                                Send cart recovery email
                                                            </Button>
                                                            :
                                                            orderData?.email_status && orderData?.email_status.toLowerCase() == "scheduled" ?
                                                                <Button component={Link} variant="outlined" color="error" onClick={cancelMail}>
                                                                    Cancel email reminder
                                                                </Button>
                                                                : null
                                                        }
                                                    </Box>
                                                    : null
                                                }
                                            </CardContent>
                                        </Card>
                                        : null
                                    }
                                    {orderData && orderData?.cart_items &&
                                        <Card sx={{ mb: 2 }}>
                                            <CardContent>
                                                <Typography variant='p' component={"strong"}>Checkout details</Typography>
                                                {orderData?.cart_items.map((data, index) => {
                                                    return (
                                                        <Box key={index} className="orderProductBox" sx={{ display: "flex", mt: 1 }}>
                                                            <Box className="orderProductBox__img">
                                                                <Badge
                                                                    overlap="circular"
                                                                    // anchorOrigin={{ vertical: "top", horizontal: "right" }}
                                                                    badgeContent={
                                                                        <SmallAvatar sx={{ fontSize: 10, width: "auto", height: "auto", py: 0.5, px: 0.6, objectFit: "contain" }} > {data?.quantity} </SmallAvatar>
                                                                    }  >
                                                                    <Avatar alt="Image" src={data?.product_variant?.Image?.src || (data?.product_variant?.Product?.images && data?.product_variant?.Product?.images.length > 0 && _.sortBy(data?.product_variant?.Product?.images, "position")[0]?.src) || noImage} variant="square" sx={{ objectFit: "contain" }} />
                                                                </Badge>
                                                            </Box>
                                                            <Box className="orderProductBoxCntnt" sx={{ display: "flex" }}>
                                                                <Box className="orderProductBoxCntnt__link">
                                                                    <Link to="" color="inherit">{data?.product_variant?.Product?.title || data?.title}</Link>
                                                                    {(data?.product_variant?.title !== "" && data?.name !== "") && (data?.product_variant?.title !== "Default Title" && data?.name !== "Default Title") ?
                                                                        <Typography variant="caption" component="div">{data?.product_variant?.title || data?.name} </Typography>
                                                                        : null
                                                                    }
                                                                    {data.sku &&
                                                                        <Typography variant="caption" component="div">SKU: {data?.product_variant?.sku || data?.sku} </Typography>
                                                                    }
                                                                </Box>
                                                                <Box className="orderProductBoxCntnt__link">
                                                                    <CurrencyFormat value={parseFloat((data?.product_variant?.price || data?.price)).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={config.CURRENCY} /> x {data?.quantity}
                                                                </Box>
                                                                <Box className="orderProductBoxCntnt__price">
                                                                    <Typography variant="caption" component="div" sx={{ textAlign: "end" }}>
                                                                        Subtotal:
                                                                        <CurrencyFormat value={parseFloat((data?.product_variant?.price || data?.price) * data?.quantity).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={config.CURRENCY} />
                                                                        {(data?.discount_per_item && data?.discount_per_item > 0) || (data?.discount_amount && data?.discount_amount > 0) ? (
                                                                            <>
                                                                                <br />
                                                                                Discount: <span style={{ color: "red" }}>
                                                                                    -<CurrencyFormat value={parseFloat(data?.discount_per_item || data?.discount_amount).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={config.CURRENCY} />
                                                                                </span>
                                                                            </>
                                                                        ) : null
                                                                        }
                                                                    </Typography>
                                                                    {(data?.tax && Number(data?.tax)) || (data?.tax_amount && Number(data?.tax_amount)) ?
                                                                        <Typography variant="caption" component="div" sx={{ textAlign: "end" }}>
                                                                            Tax: <CurrencyFormat value={parseFloat(data?.tax || data?.tax_amount).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={config.CURRENCY} />
                                                                        </Typography>
                                                                        : null
                                                                    }
                                                                    <Typography variant="subtitle2" component="div" sx={{ textAlign: "end" }}>
                                                                        Total: <CurrencyFormat value={parseFloat(((data?.product_variant?.price || data?.price) * data?.quantity) + Number(data?.tax || data?.tax_amount) - Number(data?.discount_per_item || data?.discount_amount)).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={config.CURRENCY} />
                                                                    </Typography>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    )
                                                })}

                                            </CardContent>
                                        </Card>
                                    }

                                    <Card sx={{ mb: 2 }}>
                                        <CardContent>
                                            <Box className="ordertabular" sx={{ display: "flex", mb: 1 }}>
                                                <Box className="ordertabular__title" >
                                                    <Typography variant="subtitle2" component="div"  >Subtotal </Typography>
                                                </Box>
                                                <Box className="ordertabular__cntnt" sx={{ display: "flex", justifyContent: "space-between", flexGrow: 1, }}>
                                                    <Box></Box>
                                                    <Box> <Typography variant="body2" component="div" sx={{ ml: 1 }}>
                                                        <CurrencyFormat value={parseFloat(orderData?.sub_total || orderData?.subtotal_price || 0).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={config.CURRENCY} />
                                                    </Typography> </Box>
                                                </Box>
                                            </Box>
                                            <Box className="ordertabular" sx={{ display: "flex", mb: 1 }}>
                                                <Box className="ordertabular__title" >
                                                    <Typography variant="subtitle2" component="div" >Discount </Typography>
                                                </Box>
                                                <Box className="ordertabular__cntnt" sx={{ display: "flex", justifyContent: "space-between", flexGrow: 1, }}>
                                                    <Box>
                                                    </Box>
                                                    <Box>
                                                        <Typography variant="body2" component="div" sx={{ ml: 1, color: "red" }}>
                                                            {"-"}<CurrencyFormat value={parseFloat(orderData?.discount || orderData?.total_discounts || 0).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={config.CURRENCY} />

                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                            <Box className="ordertabular" sx={{ display: "flex", mb: 1 }}>
                                                <Box className="ordertabular__title" >
                                                    <Typography variant="subtitle2" component="div" >Shipping </Typography>
                                                </Box>
                                                <Box className="ordertabular__cntnt" sx={{ display: "flex", justifyContent: "space-between", flexGrow: 1, }}>
                                                    <Box></Box>
                                                    <Box>
                                                        <Typography variant="body2" component="div" sx={{ ml: 1 }}>
                                                            <CurrencyFormat value={parseFloat(orderData?.delivery_fee || orderData?.shipping_charges || 0.00).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={config.CURRENCY} />
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                            <Box className="ordertabular" sx={{ display: "flex", mb: 1 }}>
                                                <Box className="ordertabular__title" >
                                                    <Typography variant="subtitle2" component="div" >Tax </Typography>
                                                </Box>
                                                <Box className="ordertabular__cntnt" sx={{ display: "flex", justifyContent: "space-between", flexGrow: 1, }}>
                                                    <Box></Box>
                                                    <Box>
                                                        <Typography variant="body2" component="div" sx={{ ml: 1 }}>
                                                            <CurrencyFormat value={parseFloat(orderData?.tax || orderData?.total_tax || 0.00).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={config.CURRENCY} />
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Box>

                                            <Box className="ordertabular" sx={{ display: "flex", mb: 1 }}>
                                                <Box className="ordertabular__title" >
                                                    <Typography variant="p" component="strong" >Total</Typography>
                                                </Box>
                                                <Box className="ordertabular__cntnt" sx={{ display: "flex", justifyContent: "space-between", flexGrow: 1, }}>
                                                    <Box> <Typography variant="subtitle1" component="div" sx={{ ml: 1 }}> </Typography> </Box>
                                                    <Box> <Typography variant="p" component="strong" sx={{ ml: 1 }}>
                                                        <CurrencyFormat value={parseFloat(orderData?.total || orderData?.total_price || 0).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={config.CURRENCY} />
                                                    </Typography> </Box>
                                                </Box>
                                            </Box>

                                        </CardContent>
                                    </Card>


                                </Grid>
                                <Grid item md={4}>
                                    <Card sx={{ mb: 2 }}>
                                        <CardContent>
                                            <Grid container spacing={2} columns={12} justifyContent="space-between" alignItems="center" sx={{ mb: 1 }}>
                                                <Grid item> <Typography variant="h6" component="h6" >User</Typography></Grid>
                                            </Grid>
                                            {/* <Typography variant="subtitle1" component="div" gutterBottom >{orderData?.Customer?.name}</Typography> */}
                                            <Box><Typography variant="caption2">{orderData?.Customer?.name ? orderData?.Customer?.name : `${orderData?.Customer?.first_name || orderData?.cart_billing_address?.first_name || ""} ${orderData?.Customer?.last_name ? orderData?.Customer?.last_name : orderData?.cart_billing_address?.last_name || ""}`}</Typography></Box>
                                            <Box><Typography variant="caption2">{orderData?.Customer?.orders_count || 0} orders</Typography></Box>
                                        </CardContent>
                                        <Divider />
                                        <CardContent>
                                            <Grid container spacing={2} columns={12} justifyContent="space-between" alignItems="center">
                                                <Grid item > <Typography variant="p" component="strong">User information</Typography></Grid>
                                            </Grid>
                                            <Grid container spacing={2} columns={12} justifyContent="space-between" alignItems="center" sx={{ mb: 2 }} >
                                                <Grid item > <Link to="" color="inherit">{orderData?.Customer?.email || orderData?.cart_billing_address?.email} </Link></Grid>
                                                <Grid item >
                                                    <IconButton aria-label="copy" sx={{ width: "35px", height: "35px" }}>
                                                        {/* <ContentCopyOutlinedIcon sx={{ width: "15px" }} /> */}
                                                        <CopyToClipboard text={orderData?.Customer?.email || orderData?.cart_billing_address?.email} onCopy={() => copiedFn('email')}>
                                                            {copied.email ?
                                                                <DoneIcon sx={{ width: "15px" }} />
                                                                :
                                                                <ContentCopyOutlinedIcon sx={{ width: "15px" }} />
                                                            }
                                                        </CopyToClipboard>
                                                    </IconButton>
                                                </Grid>
                                            </Grid>

                                            {orderData?.status == "recovered" && orderData?.billing_address && !_.isEmpty(orderData?.billing_address) ?
                                                <Box sx={{ mb: 2 }}>
                                                    <Typography variant="p" component="strong">Billing address</Typography>
                                                    <Typography variant="subtitle1" component="div" > {orderData?.billing_address?.first_name} {orderData?.billing_address?.last_name}</Typography>
                                                    <Typography variant="subtitle1" component="div" > {orderData?.billing_address?.address1}</Typography>
                                                    <Typography variant="subtitle1" component="div" > {orderData?.billing_address?.address2}</Typography>
                                                    <Typography variant="subtitle1" component="div" > {orderData?.billing_address?.city} {orderData?.billing_address?.province}</Typography>
                                                    <Typography variant="subtitle1" component="div" > {orderData?.billing_address?.country}</Typography>
                                                </Box>
                                                : null
                                            }
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Box>
                    </Container >
                    : null
            }

            <DialogAlert
                showDialog={showDialog}
                closeDialog={closeDialog}
                content={content}
                confirmDialog={confirmDialog}
            />
        </>
    );
}

export default AbandonedDetail