import React, { useEffect, useState } from 'react'
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { alpha } from '@mui/material/styles';
import {
    Grid, Divider, Typography, Paper, ButtonGroup,
    Button, InputBase, Menu, MenuItem, Box, Chip,
    Card, IconButton, Container, Checkbox, Popover, LinearProgress, Pagination, FormControlLabel, Radio, Skeleton,
} from '@mui/material'
import { styled } from '@mui/material/styles';

import SearchIcon from '@mui/icons-material/Search';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ClearIcon from '@mui/icons-material/Clear';
import order from "../../../../Assets/images/no-result/order.svg";

import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import CircleIcon from '@mui/icons-material/Circle';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CurrencyFormat from 'react-currency-format';
import SortIcon from '@mui/icons-material/Sort';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';

import { DataGrid } from '@mui/x-data-grid';
import { useMediaQuery, useTheme } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import * as Api from "../../../../Container/config/Apis"
import config from '../../../../Container/config';
import _ from "underscore";
import moment from "moment";
import { useSnackbar } from 'notistack';

import { useRef } from 'react';
import NoResult from '../../../common/NoResult';

// secrch
const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.0),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.0),
    },
    marginRight: "0",
    marginLeft: "0",
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        border: "1px solid #d9d9d9",
        borderRadius: "5px",
        // background: "#f7f8fa",
        [theme.breakpoints.up('md')]: {
            width: '100%',
        },
    },
}));

// secrch
const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));

// dropdown  

const handleToggleAllCompleted = (e) => {
    console.log("e", e);
};

const handleToggleCompleted = (e) => {
    console.log("e", e);
};


const theme = createTheme({
    palette: {
        neutral: {
            main: '#64748B',
            contrastText: '#fff',
        },
    },
});


const StyledGridOverlay = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
}));
function CustomNoRowsOverlay() {
    return (
        <Box sx={{ mt: 5, mb: 2, textAlign: "center" }}>
            <NoResult
                icon={order}
                title={"Manage the exchange & return requests"}
                subtitle={"Admin can view, approve, and disapprove of the exchange & return requests from the users based on the store guidelines."}
                btn={false}
                btnText={"Create order"}
                link={"/admin/orders/draft/create?type=returns"}
            />
            {/* <img src={failed_vector} alt="" width="100" />
                <Typography variant="h5" component="div" sx={{ mt: 1 }}>No result found!</Typography> */}
        </Box>
    );
}

export default function Exchange() {
    // dropdown btn
    const searchParams = new URLSearchParams(window.location.search)
    let navigate = useNavigate()
    const location = useLocation()
    const [anchorEl, setAnchorEl] = useState({
        action: null,
        customer: null,
        sortby: null,
        date: null,
        return_type: null,
        return_status: null,
    });
    const actionRef = React.useRef();

    const [loadingBar, setloadingBar] = React.useState(true);
    const [loadingData, setloadingData] = React.useState(false);
    const [activeTab, setactiveTab] = useState("all")
    const [selectionModel, setSelectionModel] = useState([]);
    const [limit, setLimit] = useState(process.env.REACT_APP_LIMIT || 20)
    const [params, setParams] = useState({
        search: "",
        return_type: "",
        type: "",
        sort_key: "",
        sort_value: "desc",
        page: 1
    })
    const { enqueueSnackbar } = useSnackbar();
    const snack = (msg, variant) => {
        enqueueSnackbar(msg, { variant });
    }

    //Set/Delete param
    const setparam = (key, val) => {
        searchParams.set(key, val)
        const to = { pathname: location.pathname, search: searchParams.toString() };
        navigate(to, { replace: true });
    }
    const deleteparam = (key) => {
        searchParams.delete(key)
        const to = { pathname: location.pathname, search: searchParams.toString() };
        navigate(to, { replace: true });
    }
    // popover
    const [activeId, setactiveId] = useState("")
    const ref = useRef(null)

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const density = isSmallScreen ? 'standard' : 'standard';

    const [search, setSearch] = useState('')

    const [activeSort, setActiveSort] = useState("")

    const open = Boolean(anchorEl.action);
    const openSort = Boolean(anchorEl.sortby);
    const openDate = Boolean(anchorEl.date);
    const openStatus = Boolean(anchorEl.return_status);
    const openPaymentStatus = Boolean(anchorEl.paymentStatus);

    const popOpen = Boolean(anchorEl.customer);
    // popover

    console.log("anchorEl", anchorEl)
    console.log("activeId", activeId)

    const scroll = (scrollOffset) => {
        ref.current.querySelector(".MuiDataGrid-main").scrollLeft += scrollOffset;
    };
    const [actionObj, setActionObj] = useState({
        isaction: false,
        type: ""
    })
    const [count, setCount] = useState(1)
    const [totalOrders, setTotalOrders] = useState(0)
    const [filters, setfilters] = useState(false)

    const [exchangeOrders, setexchangeOrders] = useState([
        // {
        //     id: 1001, order_id: 1324656, created_at: new Date(), customer_id: 13213, Customer: {
        //         first_name: "Amandeep", last_name: "singh", email: "amandeep@weoinfotech.com", orders_count: 0
        //     }, type: "Exchange", item: { title: "Women winter jackets", variant: "XL", qty: 1, }
        // },
        // {
        //     id: 1002, order_id: 1324677, created_at: new Date(), customer_id: 13213, Customer: {
        //         first_name: "Amandeep", last_name: "singh", email: "amandeep@weoinfotech.com", orders_count: 0
        //     }, type: "Returns", item: { title: "Women winter jackets", variant: "XL", qty: 1, }
        // },
    ])

    const tabs = [
        { label: "All", name: "all" },
        { label: "Return", name: "return" },
        { label: "Exchange", name: "exchange" },
    ]

    const [statusArray, setStatusArray] = useState([
        { name: "All", key: "return_status", value: "all", active: false },
        { name: "Pending", key: "return_status", value: "PENDING", active: false },
        { name: "In progress", key: "return_status", value: "IN_PROGRESS", active: false },
        { name: "Completed", key: "return_status", value: "COMPLETED", active: false },
        { name: "Rejected", key: "return_status", value: "REJECTED", active: false },
        { name: "Closed", key: "return_status", value: "CLOSED", active: false },
    ])
    const [sortby, setSortby] = useState([
        { name: "Oldest first", key: "createdAt", value: "asc", active: false },
        { name: "Newest first", key: "createdAt", value: "desc", active: false },
    ])
    const [sortDate, setSortDate] = useState([
        { name: "Today", key: "today", value: "asc", active: false },
        { name: "Last 7 days", key: "last_7_days", value: "asc", active: false },
        { name: "Last 30 days", key: "last_30_days", value: "asc", active: false },
        { name: "Last 90 days", key: "last_90_days", value: "asc", active: false },
        { name: "Last 12 months", key: "last_12_month", value: "asc", active: false },
    ])
    
    const openPopover = (event, id) => {
        setAnchorEl({ ...anchorEl, 'customer': event.currentTarget });
        setactiveId(id)
        // setAnchorEl(event.currentTarget);
    };

    const popoverClose = (event, id) => {
        setAnchorEl({ ...anchorEl, ['customer']: null });
        setactiveId(null)
        // setAnchorEl(null);
    };



    const columns = [
        {
            field: 'returnExchangeId', headerName: 'Request ID ',
            renderCell: (params) => <Button component={Link} to={`/admin/orders/returns/${params?.row?.orderId}/${params?.value}`} color="secondary" className='noHover d-block' sx={{ p: 0, pl:1 }}>
                <Typography variant="subtitle2" component="div" sx={{ m: 0, lineHeight: "1.2" }} >
                    {params.value}
                </Typography>
                <Typography variant="caption" component="div" sx={{ m: 0, lineHeight: "1.2" }} >
                    Order: {params.row.orderId}
                </Typography>
            </Button>, width: 200, height: 40, sortable: false,
        },
        {
            field: 'lineItems', headerName: 'Product',
            renderCell: (params) =>
                <Button color="secondary" className='noHover d-block' component={Link} to={`/admin/orders/returns/${params?.row?.orderId}/${params?.row?.returnExchangeId}`} sx={{ textAlign: "start", p: 0 }}>
                    <Typography variant="button" component="div" sx={{ m: 0, lineHeight: "1.2" }}>
                        {params.value.title}
                    </Typography>
                    <Typography variant="caption" component="div" sx={{ m: 0, lineHeight: "1.2" }}>
                        {params.value.name} <strong>(Qty: {params.row?.quantity})</strong>
                    </Typography>
                </Button>, sortable: false, width: 300,
        },
        {
            field: 'createdAt', headerName: 'Date',
            renderCell: (params) => <Box>{moment(params.value).format("lll")}</Box>, sortable: false, width: 150,
        },
        {
            field: 'Customer', headerName: 'User', sortable: false, width: 200,
            renderCell: (params) =>
                <>
                    <Button aria-describedby={params.id} variant="text" color="secondary" onClick={(e) => openPopover(e, params.id)} endIcon={<ArrowDropDownIcon />}>
                        <span className='text-truncate'>{params?.value?.first_name ? `${params?.value?.first_name} ${params?.value?.last_name ? params?.value?.last_name : ""}` : "Guest user"}</span>
                    </Button>
                    <Popover
                        id={params.id}
                        open={activeId == params.id}
                        anchorEl={anchorEl.customer}
                        onClose={(e) => popoverClose(e, params.id)}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }} >
                        <Typography component="div" sx={{ p: 2 }}>
                            <Typography variant="caption" component="div"> {params?.value?.orders_count} orders</Typography>
                            <Typography variant="caption" sx={{ mb: 1 }} component="div">
                                <a href={`mailto:${params?.value?.email}`} color="secondary">{params?.value?.email}</a>
                            </Typography>
                            <NavLink to={`/admin/users/${params?.row?.orders?.customer_id}`} className="link "><small>View user</small></NavLink>
                        </Typography>
                    </Popover>
                </>
        },
        {
            field: 'mode', headerName: 'Type', sortable: false, width: 130,
            renderCell: (params) => <Chip sx={{ textTransform: "capitalize" }} icon={
                params.value == "return" ? <CircleIcon sx={{ fontSize: "12px !important" }} /> : <CircleOutlinedIcon sx={{ fontSize: "12px !important" }} />}
                label={params?.value}
                variant={params.value == "return" ? "contained" : "outlined"}
                color='error'
            />
        },
        {
            field: 'status', headerName: 'Status', sortable: false, width: 130, 
            renderCell: (params) => <Chip sx={{ textTransform: "capitalize" }} icon={
                params.value == "Completed" ? <CircleIcon sx={{ fontSize: "12px !important" }} /> : <CircleOutlinedIcon sx={{ fontSize: "12px !important" }} />}
                label={params?.value}
                variant={params.value == "Completed" ? "contained" : "outlined"}
                color={params.value == "Pending" ? 'primary' : params.value == "Completed" ? 'success' : 'warning'}
            />
        },
    ]


    const handleClick = (event, data) => {
        setAnchorEl({ ...anchorEl, [data]: event.currentTarget });
    };
    const handleClose = () => {
        setAnchorEl({
            action: null,
            customer: null,
            sortby: null,
            date: null,
            return_type: null,
            return_status: null,
        });
        // setAnchorEl({...anchorEl, data : null});
    };
    const clickTab = (name) => {
        setloadingData(true)
        setactiveTab(name);
        if (name !== "all") {
            setParams({ ...params, 'page': 1, 'return_type': name })
            setparam("page", 1)
            setparam("return_type", name)
        } else {
            setParams({ ...params, 'page': 1, 'return_type': "" })
            deleteparam("return_type")
        }
    };

    //Search change
    const handleSearchChange = (e) => {
        setSearch(e.target.value)
        if (e.target.value == "") {
            setParams({ ...params, 'page': 1, 'search': "" })
            deleteparam("search")
        } else {
            setparam("search", e.target.value)
        }
    }

    React.useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (search !== "") {
                console.log("search:", search);
                setloadingBar(true)
                setParams({ ...params, 'page': 1, 'search': search })
                setparam("search", search)
            }
        }, 1000);

        return () => clearTimeout(delayDebounceFn);
    }, [search]);
    // function handleKeyPress(e) {
    //     if (e.key === 'Enter') {
    //         setloadingBar(true)
    //         if (search !== "") {
    //             setParams({ ...params, 'page': 1, 'search': search })
    //             setparam("page", 1)
    //             setparam("search", search)
    //         } else {
    //             setParams({ ...params, 'page': 1, 'search': "" })
    //             deleteparam("search")
    //         }
    //     }
    // }
    // const handleSearchBlur = (e) => {
    //     if (search !== "") {
    //     setloadingBar(true)
    //         setParams({ ...params, 'page': 1, 'search': search })
    //         setparam("page", 1)
    //         setparam("search", search)
    //     } 
    //     // else {
    //     //     setParams({ ...params, 'page': 1, 'search': "" })
    //     //     deleteparam("search")
    //     // }
    // }

    //Sort by on change
    const handleSortChange = (val, type) => {
        setloadingBar(true)
        let newArr = []
        if (type == "sort") {
            sortby.map((opt) => {
                if (opt.name === val.name) {
                    newArr.push({ ...opt, 'active': true })
                    setParams({ ...params, "sort_key": val.key, "sort_value": val.value, 'page': 1 })
                    setparam("page", 1)
                    setparam("sort_key", val.key)
                    setparam("sort_value", val.value)
                } else {
                    newArr.push({ ...opt, 'active': false })
                }
            })
            setSortby(newArr)
        } else if (type == "status") {
            statusArray.map((opt) => {
                if (opt.name === val.name) {
                    newArr.push({ ...opt, 'active': true })
                    setParams({ ...params, [val.key]: val.value, 'page': 1 })
                    setparam("page", 1)
                    setparam([val.key], val.value)
                } else {
                    newArr.push({ ...opt, 'active': false })
                }
            })
            setStatusArray(newArr)
        } else {
            sortDate.map((opt) => {
                if (opt.name === val.name) {
                    newArr.push({ ...opt, 'active': true })
                    setParams({ ...params, "type": val.key, "sort_value": val.value, 'page': 1 })
                    setparam("page", 1)
                    setparam("type", val.key)
                    setparam("sort_value", val.value)
                } else {
                    newArr.push({ ...opt, 'active': false })
                }
            })
            setSortDate(newArr)
        }
        handleClose()
    }

    //Clear sort by filter
    const clearSortBy = () => {
        setloadingBar(true)
        let newArr = []
        let newArr2 = []
        setParams({ ...params, 'page': 1, "sort_key": "", "sort_value": "", type: "" })
        deleteparam("sort_key")
        deleteparam("type")
        deleteparam("sort_value")
        sortby.map((opt) => {
            newArr.push({ ...opt, 'active': false })
        })
        sortDate.map((opt) => {
            newArr2.push({ ...opt, 'active': false })
        })
        setSortby(newArr)
        setSortDate(newArr2)
    }
    const handleChangePage = (_event, newPage) => {
        setloadingBar(true)
        console.log("newPage: ", newPage)
        setParams({ ...params, "page": newPage })
        setparam("page", newPage)
    };
    const handleSortbyChange = (e, data) => {
        setActiveSort(data)
    }
    const handleSortValueChange = (e, data) => {
        let arr = []
        sortby.map((opt) => {
            if (opt?.key == data?.key) {
                let sortType = []
                opt.sortType.map((x) => {
                    if (x?.value == e.target.value) {
                        sortType.push({ ...x, active: e.target.checked })
                    } else {
                        sortType.push({ ...x, active: false })
                    }
                })
                arr.push({ ...opt, value: e.target.value, 'active': true, sortType })
                setParams({ ...params, "sort_key": opt?.key, "sort_value": e.target.value, 'page': 1 })
                setparam("page", 1)
                setparam("sort_key", opt?.key)
                setparam("sort_value", e.target.value)
            } else {
                arr.push({ ...opt, 'active': false })
            }
            setSortby(arr)
            handleClose()
        })
    }
    console.log("sortby: ", sortby)


    // const handleActionChange = (val) => {
    //     console.log(val)
    //     setContent({ ...content, 'message': `You want to ${val.value} selected discounts.` })
    //     setActionObj({isaction: true, type: val.value})
    //     setOpen(true)
    // }


    //Get order list
    const getOrdersList = (param) => {
        Api.GetApi(`${config.EXCHANGE_ORDERS_LIST}${param}`).then(res => {
            setTimeout(() => {
                setloadingBar(false)
                setloadingData(false)
            }, 500);
            console.log("res list: ", res)
            if (res.error === true) {
                snack(res.response.data.error, 'error');
            } else {
                if (res.data.data) {
                    let resData = res.data.data
                    let arr = []
                    resData?.rows.map((opt) => {
                        arr.push({ ...opt, Customer: opt?.orders?.customer, id: opt?.returnExchangeId })
                    })
                    setexchangeOrders(arr)

                    setTotalOrders(resData?.count)
                    let newCount = Math.ceil(parseInt(resData?.count) / limit)
                    if (newCount !== 0) {
                        setCount(newCount)
                    } else {
                        setCount(1)
                    }
                }
            }
        })
    }

    useEffect(() => {
        let newArr = []
        const to = { pathname: location.pathname, search: searchParams.toString() };
        navigate(to, { replace: true });
        getOrdersList(location.search)
        setactiveTab(searchParams.get('return_type') || "all")
    }, [limit, params])

    console.log("exchangeOrders: ", exchangeOrders)
    const toggleShowFilter = () => {
        setfilters(!filters)
    }


    // dropdown btn
    return (
        <React.Fragment>
            {/* <Box className="smallContainer"> */}

            {loadingBar ?
                <LinearProgress className="top_loader" color="info" sx={{ mt: "-25px", mb: "21px" }} />
                :
                null
            }
            <Container maxWidth="lg" className={`orderPage ${exchangeOrders.length == 0 ? 'no_order_overlay' : ''}`}>
                <Box sx={{ mb: 2 }}>
                    <Grid container spacing={1} columns={12} sx={{ alignItems: "center" }}>
                        <Grid item xs={10}>
                            <Typography variant="h5" component="div"> Exchange & returns </Typography>
                        </Grid>
                        <Grid item xs={2} sx={{ display: "flex", justifyContent: 'flex-end', flexShrink: { sm: 0 } }}>
                            <IconButton sx={{ display: { xs: "block", sm: "none", }, p: 0 }} aria-label="delete" onClick={() => toggleShowFilter()}>
                                {filters ? <FilterAltOffIcon /> : <FilterAltIcon />}
                            </IconButton>
                        </Grid>
                    </Grid>
                </Box>
                <Divider sx={{ mb: 2, display: { xs: "none", md: "block" } }} />
                <Card sx={{ mb: 2 }}>
                    <Box sx={{ p: { xs: filters ? 2 : 0, sm: 2, lg: 2 }, borderBottom: "#eee 1px solid" }}>
                        <Grid container spacing={{ xs: filters ? 1 : 0, sm: 1 }} columns={12} alignItems="center">
                            <Grid item xs={12} sm={5} md={7} lg={7} >
                                <Box sx={{ display: "flex", gap: "10px", flexWrap: { sm: "wrap", md: "no-wrap" }, alignItems: "center" }}>
                                    <Box className="responsiveFixed">
                                        <ButtonGroup variant="outlined" color="primary" aria-label="medium success button group">
                                            {tabs.map((tab) => {
                                                return (
                                                    <Button key={tab.name} sx={{ whiteSpace: "nowrap" }} variant={`${activeTab === tab.name ? 'contained' : 'outlined'}`} onClick={() => clickTab(tab.name)}>{tab.label}</Button>
                                                )
                                            })}
                                        </ButtonGroup>
                                    </Box>
                                    <Box sx={{ display: { xs: filters ? "block" : "none", sm: 'inlline-flex' }, width: { xs: "100%", sm: "auto" } }}>
                                        <Search className='filter_search_panel searchPanelOrder ml-0'>
                                            <SearchIconWrapper>
                                                <SearchIcon color="grey" />
                                            </SearchIconWrapper>
                                            <StyledInputBase
                                                placeholder="Search…"
                                                inputProps={{ 'aria-label': 'search', value: search }}
                                                // onBlur={handleSearchBlur}
                                                // onKeyUp={handleKeyPress}
                                                onChange={handleSearchChange}
                                            />
                                        </Search>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={7} md={5} lg={5}>
                                <Box className='mobilescrollMenu' sx={{ textAlign: { sm: 'right' }, display: { xs: filters ? "block" : "none", sm: 'flex' }, flexWrap: { sm: "wrap", md: "no-wrap" }, justifyContent: { sm: "flex-end" } }}>
                                    {searchParams.get("sort_key") || searchParams.get("sort_value") ?
                                        <Button variant='text' color="error" size="small" sx={{ mr: 2 }} endIcon={<ClearIcon />} onClick={clearSortBy}>
                                               <Box sx={{ display: { xs: "none", md: "block" } }}>Clear</Box>
                                        </Button>
                                        : null
                                    }
                                    <Box sx={{ mr: 1 }}>
                                        <Button
                                            id="discountCodeType"
                                            aria-controls={openStatus ? 'discountCodeTypeArea' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={openStatus ? 'true' : undefined}
                                            variant="text"
                                            disableElevation
                                            size="small"
                                            color="secondary"
                                            onClick={(e) => handleClick(e, "return_status")}
                                            endIcon={<KeyboardArrowDownIcon />}>

                                            {statusArray.filter((x) => x.active)[0]?.name || "Status"}
                                        </Button>

                                        <StyledMenu
                                            id="discountCodeTypeArea"
                                            MenuListProps={{ 'aria-labelledby': 'discountCodeType', }}
                                            anchorEl={anchorEl.return_status}
                                            open={openStatus}
                                            onClose={handleClose}>

                                            {statusArray.map((data, index) => {
                                                return (
                                                    <MenuItem key={index} onClick={() => handleSortChange(data, "status")} disableRipple sx={{ fontSize: "12px" }}>{data.name}</MenuItem>
                                                )
                                            })}
                                        </StyledMenu>
                                    </Box>
                                    <Box sx={{ mr: 1 }}>
                                        <Button
                                            id="discountCodeType"
                                            aria-controls={openDate ? 'discountCodeTypeArea' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={openDate ? 'true' : undefined}
                                            variant="text"
                                            disableElevation
                                            size="small"
                                            color="secondary"
                                            onClick={(e) => handleClick(e, "date")}
                                            endIcon={<KeyboardArrowDownIcon />}>

                                            {sortDate.filter((x) => x.active)[0]?.name || "Date"}
                                        </Button>

                                        <StyledMenu
                                            id="discountCodeTypeArea"
                                            MenuListProps={{ 'aria-labelledby': 'discountCodeType', }}
                                            anchorEl={anchorEl.date}
                                            open={openDate}
                                            onClose={handleClose}>

                                            {sortDate.map((data, index) => {
                                                return (
                                                    <MenuItem key={index} onClick={() => handleSortChange(data, "date")} disableRipple sx={{ fontSize: "12px" }}>{data.name}</MenuItem>
                                                )
                                            })}
                                        </StyledMenu>
                                    </Box>
                                    <Box>
                                        <Button
                                            id="discountCode"
                                            aria-controls={openSort ? 'discountCode' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={openSort ? 'true' : undefined}
                                            variant="text"
                                            disableElevation
                                            size="small"
                                            color="secondary"
                                            onClick={(e) => handleClick(e, "sortby")}
                                            endIcon={<KeyboardArrowDownIcon sx={{ display: { sm: "none" } }} />}
                                            sx={{ minWidth: "auto", ml: 0 }}
                                        >


                                            {sortby.filter((x) => x.active)[0]?.name ||
                                                <>
                                                    <Box sx={{ display: { sm: "none" } }}>Sort by</Box>
                                                    <SortIcon sx={{ display: { xs: "none", sm: "block" } }} />
                                                </>
                                            }
                                        </Button>

                                        <StyledMenu
                                            id="discountCode"
                                            MenuListProps={{ 'aria-labelledby': 'discountCode', }}
                                            anchorEl={anchorEl.sortby}
                                            open={openSort}
                                            onClose={handleClose}>

                                            {sortby.map((data, index) => {
                                                return (
                                                    <MenuItem key={index} onClick={() => handleSortChange(data, "sort")} disableRipple sx={{ fontSize: "12px" }}>{data.name}</MenuItem>
                                                )
                                            })}
                                        </StyledMenu>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box className="discountTable" sx={{ width: '100%' }}>
                        {/* <Box className='discountTable__cntnt' sx={{ display: "flex", alignItems: "center" }}>
                                <Box className='discountTable__cntntInr' >
                                    {selectionModel.length > 0 ?
                                        <Box sx={{ display: "flex", alignItems: "center" }}>
                                            <Typography variant="p" component="strong"> {selectionModel.length} selected  </Typography>
                                            <Button
                                                id="action"
                                                ref={actionRef}
                                                aria-controls={open ? 'actionArea' : undefined}
                                                aria-haspopup="true"
                                                aria-expanded={open ? 'true' : undefined}
                                                variant="outlined"
                                                disableElevation
                                                onClick={(e) => handleClick(e, "action")}
                                                endIcon={<KeyboardArrowDownIcon />}
                                                sx={{ ml: 2, }}
                                            >
                                                Action
                                            </Button>
                                            <StyledMenu
                                                id="actionArea"
                                                MenuListProps={{ 'aria-labelledby': 'action', }}
                                                anchorEl={anchorEl.action}
                                                open={open}
                                                onClose={handleClose}>
                                                {actions.map((opt) => {
                                                    return (
                                                        <MenuItem key={opt.name} onClick={() => handleActionChange(opt)} disableRipple className={`action_list ${opt.active ? "active" : ""}`} sx={{ fontSize: "12px" }}>
                                                            {opt.name}
                                                        </MenuItem>
                                                    )
                                                })}
                                            </StyledMenu>
                                        </Box> : <Typography variant="p" component="strong"> Showing {rows.length} discount codes</Typography>
                                    }
                                </Box>
                            </Box> */}
                        {loadingBar ?
                            <Skeleton variant="text" height={40} width={"100%"} />
                            :
                            <Box>
                                <DataGrid
                                    rows={exchangeOrders}
                                    columns={columns}
                                    pageSize={limit}
                                    rowsPerPageOptions={[]}
                                    onPageSizeChange={(e) => onPageSizeChange(e)}
                                    checkboxSelection={false}
                                    disableColumnMenu
                                    disableSelectionOnClick={true}
                                    autoHeight={true}
                                    density={density}
                                    ref={ref}
                                    hideFooterPagination={true}
                                    hideFooter={true}
                                    // onSelectionModelChange={(newSelectionModel) => {
                                    //     setSelectionModel(newSelectionModel);
                                    // }}
                                    // selectionModel={selectionModel}
                                    components={{
                                        NoRowsOverlay: CustomNoRowsOverlay,
                                        // LoadingOverlay: LinearProgress,
                                    }}
                                    sx={{
                                        '.MuiDataGrid-columnSeparator': {
                                            display: 'none',
                                        },
                                        '&.MuiDataGrid-root': {
                                            border: 'none',
                                        },
                                    }}
                                    loading={loadingData}
                                    className="ordersTable"
                                    selectionModel={selectionModel}
                                />
                                {exchangeOrders.length > 0 ?
                                    <Box sx={{ display: "flex", justifyContent: "space-between", gap: "10px", flexWrap: "wrap", p: 2, alignItems: "center" }}>
                                        <Typography variant='body2' component={"div"} sx={{ mb: 0 }}>Showing {exchangeOrders.length} of {totalOrders} orders</Typography>
                                        <Pagination count={count} page={params.page} showFirstButton showLastButton onChange={handleChangePage} />
                                    </Box>
                                    : null
                                }
                            </Box>
                        }
                        {/* <Pagination count={count} page={params.page} showFirstButton showLastButton sx={{ p: 2, float: "right" }} onChange={handleChangePage} /> */}
                    </Box>
                </Card>
            </Container>
        </React.Fragment >
    )
}

