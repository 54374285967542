import { appURL } from "../../../Container/config/Apis";

export default {
    products: [
        {
            product_id: 123,
            title: "Example title here",
            handle: "",
            options: [],
            variants: [],
            price: 100,
            images: `https://cdn.${appURL}/s/files/gallery/gallery-812/gallery_1692856000447uM6ZL.jpg`,
        },
        {
            product_id: 234,
            title: "Example title here",
            handle: "",
            options: [],
            variants: [],
            price: 100,
            images: `https://cdn.${appURL}/s/files/gallery/gallery-812/gallery_1692856000447uM6ZL.jpg`,
        },
        {
            product_id: 456,
            title: "Example title here",
            handle: "",
            options: [],
            variants: [],
            price: 100,
            images: `https://cdn.${appURL}/s/files/gallery/gallery-812/gallery_1692856000447uM6ZL.jpg`,
        },
        {
            product_id: 678,
            title: "Example title here",
            handle: "",
            options: [],
            variants: [],
            price: 100,
            images: `https://cdn.${appURL}/s/files/gallery/gallery-812/gallery_1692856000447uM6ZL.jpg`,
        },
    ],
    banner: `https://cdn.${appURL}/s/files/gallery/gallery-233/gallery_1692855850127QzCH9.jpg`,
    img400: `https://cdn.${appURL}/s/files/gallery/gallery-867/gallery_1692856035555esDci.jpg`,
    img800: `https://cdn.${appURL}/s/files/gallery/gallery-812/gallery_1692856000447uM6ZL.jpg`,
    img150: `https://cdn.${appURL}/s/files/gallery/gallery-989/gallery_1692856102747DLqV7.jpg`,
    img1920: `https://cdn.${appURL}/s/files/gallery/gallery-139/gallery_1692855964019iaYhh.jpg`,
}