import React from "react";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";

export default function CheckBox({ data, handleCheckboxChange, main }) {
    console.log("CheckBox data: ", data)
    return (
        <FormGroup sx={{ mb: 1 }}>

            {data.name == "show_controls" && main.filter((x) => x?.name == "video_type").length > 0 && main.filter((x) => x?.name == "video_type")[0]?.value
                == "embedded" ?
                null
                :
                <FormControlLabel
                    value={data.value}
                    name={data.name}
                    control={
                        <Checkbox
                            checked={data.value}
                            onChange={(e) => handleCheckboxChange(e)}
                        />
                    }
                    label={data.label}
                    sx={{color: `var(--black)`}}
                />                
            }
        </FormGroup>
    )
}