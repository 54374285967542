import React, { useRef, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
// import { ColorPicker } from 'material-ui-color';
import { SketchPicker } from 'react-color';


export default function Colors({ data, handleColorChange }) {
    const myRef = useRef();
    console.log("color data", data)
    const [active, setActive] = useState("")
    const [offset, setoffset] = useState(0)
    const colorClick = (val) => {
        if (active == val?.id) {
            setActive("")
        } else {
            setActive(val.id)
            setTimeout(() => {
                console.log("myRef: ", myRef.current && myRef.current.offsetTop)
                if (myRef.current) {
                    setoffset(myRef.current.offsetTop)
                }
            }, 500);
        }
    }

    const styles = {
        cover: {
            position: 'fixed',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
            width: "250px"
            // backgroundColor: "red"
        },
        color_picker_popup: {
            // position: "relative",
            // zIndex: "99"
        },
        sketchPicker: {
            // position: "absolute",
            // top: offset > 500 ? "auto" : 0,
            // bottom: offset > 500 ? "100%" : "auto"
        }
    }
    return (
        <Box sx={{ mb: 3, display: 'flex', alignItems: 'center' }}>
            {/* <ColorPicker value={data.value} hideTextfield onChange={val => handleColorChange(data.name, val)} disableAlpha /> */}
            <Box sx={{ ml: 1 }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box sx={{ width: 25, height: 25, backgroundColor: data.value, mr: 1, border: "#eee 1px solid" }} onClick={() => colorClick(data)}></Box>
                    <Typography variant="caption" gutterBottom component="div" sx={{ color: `var(--black)`}}>
                        {data.label}<br />
                        <strong>{data.value}</strong>
                    </Typography>
                </Box>
                {active == data?.id ?
                    <Box className="color_picker_popup" style={styles.color_picker_popup} ref={myRef}>
                        <div style={styles.cover} onClick={() => colorClick("")}></div>
                        <div style={styles.sketchPicker}>
                            <SketchPicker
                                color={data.value}
                                onChangeComplete={val => handleColorChange(data.name, val)}
                            />
                        </div>
                    </Box>
                    : null
                }
            </Box>
        </Box>
    )
}