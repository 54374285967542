import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './Container/App';
import reportWebVitals from './reportWebVitals';
import store from './Container/store'
import { Provider } from 'react-redux'
import { SnackbarProvider } from 'notistack';

if (process.env.REACT_APP_MODE !== 'prod') {
  const meta = document.createElement('meta');
  meta.name = "robots";
  meta.content = "noindex, nofollow";
  document.getElementsByTagName('head')[0].appendChild(meta);
}
if (process.env.REACT_APP_MODE == 'dev') {
  const meta = document.createElement('meta');
  meta.name = "google-site-verification";
  meta.content = "2MdeM2_deSFwQosElXmnIDcYH05tGBObmkIZoPoaokY";
  document.getElementsByTagName('head')[0].appendChild(meta);
}

if (process.env.REACT_APP_MODE == 'prod') {
  const meta = document.createElement('meta');
  meta.name = "google-site-verification";
  meta.content = "kXBkaZcVzkkams2ANTA8dQqjQxzmOByml9N6KGryrrg";
  document.getElementsByTagName('head')[0].appendChild(meta);
}
// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

ReactDOM.render(
  <Provider store={store}>
    <SnackbarProvider maxSnack={3}>
      <App />
    </SnackbarProvider>
  </Provider>,
  document.getElementById('root')

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

reportWebVitals();
