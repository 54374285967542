import React, { useCallback, useEffect, useState, } from 'react';
import {
    Box, Button, Grid, Card, CardContent, Typography, Container,
    LinearProgress
} from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Link, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import * as Api from "../../../../../Container/config/Apis";
import config from "../../../../../Container/config";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import HTMLEditor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/themes/prism-solarizedlight.css';
import PopupModal from '../../../../common/PopupModal/PopupModal';
import DialogAlert from '../../../../common/Alerts/Dialog';

const PreviewEmail = () => {
    const { enqueueSnackbar } = useSnackbar();
    const snack = (msg, variant) => {
        enqueueSnackbar(msg, { variant });
    }
    const param = useParams();
    const [loadingBar, setloadingBar] = useState(true)
    const [iframeHeight, setIframeHeight] = useState(0)
    const [data, setData] = useState("")
    const [value, setvalue] = useState("")
    const [previewData, setpreviewData] = useState("")
    const [openModal, setopenModal] = useState(false)
    const ModalClose = () => {
        setopenModal(false)
    }
    const [showDialog, setOpen] = React.useState(false);
    const [content, setContent] = React.useState({
        title: "Are you sure?",
        message: ""
    });
    const closeDialog = () => {
        setOpen(false);
    }


    const getEmailTemplate = () => {
        Api.GetApi(`${config.EMAIL_TEMPLATE}/${param.id}`).then(res => {
            console.log("res email: ", res)
            if (res.error === true) {
                snack(res.response.data.error, 'error');
            } else {
                setData(res.data.data)
                if (res.data.data && res.data.data?.template && res.data.data?.template?.body) {
                    setvalue(res.data.data?.template?.body)
                }

                Api.GetApi(`${config.EMAIL_TEMPLATE_PREVIEW}/${param.id}`).then(resp => {
                    console.log("res email: ", resp)
                    if (resp.error === true) {
                        snack(resp.response.data.error, 'error');
                    } else {
                        setpreviewData(resp.data.data)
                    }
                })
            }
        })
    }


    useEffect(() => {
        getEmailTemplate()
    }, [param.id])

    useEffect(() => {
        setTimeout(() => {
            setloadingBar(false)
            const iframe = document.getElementById('myIframe');
            if (iframe) {
                setIframeHeight(iframe.contentWindow.document.body.scrollHeight + 50)
                let btn = iframe.contentWindow.document.querySelectorAll("a")
                for (var i = 0; i < btn.length; i++) {
                    btn[i].removeAttribute("href")
                }
            }
        }, 2000);
    }, [iframeHeight])

    return (
        <>
            {loadingBar ?
                <LinearProgress className="top_loader" color="info" sx={{ mt: "-25px", mb: "21px" }} />
                :
                <Container maxWidth="lg">
                    <Box sx={{ mb: 2 }}>
                        <Grid container spacing={2} columns={12}>
                            <Grid item xs={8}>
                                <Button component={Link} variant="text" color="secondary" to={`/admin/settings/email/templates`} startIcon={<ArrowBackIosIcon />}>
                                    {data.title}
                                </Button>
                            </Grid>
                            <Grid item xs={4} sx={{ textAlign: "end" }}>
                                <Button component={Link} to={`/admin/settings/email/edit/${data.templateName}`} variant='contained' color="primary" > Edit code</Button>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box>
                        <Card sx={{ mb: 2 }}>
                            <CardContent>
                                <Box sx={{ mb: 2 }}>
                                    <Typography variant='h6' component="div">Preview</Typography>
                                </Box>
                                <Box>
                                    {previewData ?
                                        <iframe allowfullscreen="" id='myIframe' width="100%" frameborder="0" title="Subject: Order #9999 confirmed" srcdoc={`${previewData}`} sandbox="allow-same-origin" style={{ height: `${iframeHeight}px` }}></iframe>
                                        : null
                                    }
                                </Box>
                            </CardContent>
                        </Card>
                    </Box>
                </Container>
            }
        </>
    );
}

export default PreviewEmail