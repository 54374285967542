export default {
    hero_slider: [
        {
            type: "image_file",
            id: "image_src",
            name: "image_src",
            label: "Slide image",
            note: "",
            height: "150px",
            width: "100%",
            alt_name: "slide_img_alt",
            alt: "",
            value: ""
        },
        {
            type: "heading",
            text: "Mobile image"
        },
        {
            type: "image_file",
            id: "mobile_image_src",
            name: "mobile_image_src",
            label: "Mobile image",
            note: "",
            height: "150px",
            width: "100%",
            alt_name: "mobile_slide_img_alt",
            alt: "",
            value: ""
        },
        {
            type: "textarea",
            name: "heading",
            label: "Heading",
            value: "",
            placeholder: ""
        },
        {
            type: "select",
            label: "Main heading",
            name: "headingType",
            value: "",
            items: [
                { name: "h1", value: "h1" },
                { name: "h2", value: "h2" },
                { name: "h3", value: "h3" },
                { name: "h4", value: "h4" },
                { name: "h5", value: "h5" },
                { name: "h6", value: "h6" },
            ]
        },
        {
            type: "textarea",
            name: "subheading",
            id: "subheading",
            label: "Subheading",
            value: "",
            placeholder: ""
        },
        {
            type: "text",
            name: "link",
            label: "Link",
            value: "",
            placeholder: ""
        },
        {
            type: "text",
            name: "button_label",
            label: "Button label",
            value: "",
            placeholder: ""
        }
    ],
    text_column_with_image: [
        {
            type: "image_file",
            id: "image_src",
            name: "image_src",
            label: "Image",
            note: "",
            height: "150px",
            width: "100%",
            alt_name: "img_alt",
            alt: "",
            value: ""
        },
        {
            type: "textarea",
            name: "heading",
            label: "Heading",
            value: "",
            placeholder: ""
        },
        {
            type: "select",
            label: "Main heading",
            name: "headingType",
            value: "",
            items: [
                { name: "h1", value: "h1" },
                { name: "h2", value: "h2" },
                { name: "h3", value: "h3" },
                { name: "h4", value: "h4" },
                { name: "h5", value: "h5" },
                { name: "h6", value: "h6" },
            ]
        },
        {
            type: "textarea",
            name: "text",
            label: "Text",
            value: "",
            placeholder: ""
        },
        {
            type: "text",
            name: "link",
            label: "Link",
            value: "",
            placeholder: ""
        },
    ],
    gallery: [
        {
            type: "image_file",
            id: "image_src",
            name: "image_src",
            label: "Gallery image",
            note: "",
            height: "150px",
            width: "100%",
            alt_name: "img_alt",
            alt: "",
            value: ""
        },
        {
            type: "text",
            name: "caption",
            label: "Caption",
            value: "Lorem ipsum dolor",
            placeholder: ""
        },
        {
            type: "text",
            name: "link",
            label: "Link",
            value: "",
            placeholder: "(Optional)"
        },
    ],
    brands_list: [
        {
            type: "image_file",
            id: "image_src",
            name: "image_src",
            label: "Brand image",
            note: "",
            height: "70px",
            width: "100%",
            alt_name: "img_alt",
            alt: "",
            value: ""
        },
        {
            type: "text",
            name: "link",
            label: "Link",
            value: "",
            placeholder: ""
        },
        {
            type: "text",
            name: "title",
            label: "Title",
            value: "",
            placeholder: ""
        },
    ],
    testimonials: [
        {
            type: "image_file",
            id: "image_src",
            name: "image_src",
            label: "Client image",
            note: "",
            height: "150px",
            width: "100%",
            alt_name: "slide_img_alt",
            alt: "",
            value: ""
        },
        {
            type: "textarea",
            name: "text",
            label: "Message text",
            value: "",
            placeholder: ""
        },
        {
            type: "text",
            name: "author",
            id: "author",
            label: "Author",
            value: "",
            placeholder: ""
        }
    ],
    list_style: [
        {
            type: "text",
            name: "text",
            id: "text",
            label: "Text",
            value: "",
            placeholder: ""
        }
    ],
    footer: [
        {
            type: "text",
            name: "heading",
            label: "Title",
            value: "Quick links",
            placeholder: ""
        },
        {
            type: "select",
            label: "Main heading",
            name: "headingType",
            value: "",
            items: [
                { name: "h1", value: "h1" },
                { name: "h2", value: "h2" },
                { name: "h3", value: "h3" },
                { name: "h4", value: "h4" },
                { name: "h5", value: "h5" },
                { name: "h6", value: "h6" },
            ]
        },
        {
            type: "select",
            label: "",
            name: "nav_menu",
            value: "",
            items: []
        },        
    ],
    other: [
        {
            type: "image_file",
            id: "image_src",
            name: "image_src",
            label: "Image",
            note: "",
            height: "150px",
            width: "100%",
            alt_name: "item_img_alt",
            alt: "",
            value: ""
        },
        {
            type: "text",
            name: "heading",
            label: "Heading",
            value: "Lorem ipsum dolor",
            placeholder: ""
        },
        {
            type: "select",
            label: "Main heading",
            name: "headingType",
            value: "",
            items: [
                { name: "h1", value: "h1" },
                { name: "h2", value: "h2" },
                { name: "h3", value: "h3" },
                { name: "h4", value: "h4" },
                { name: "h5", value: "h5" },
                { name: "h6", value: "h6" },
            ]
        },
        {
            type: "editor",
            name: "text",
            id: "text",
            label: "Text",
            value: "<p></p>",
        },
        {
            type: "text",
            name: "btn_label",
            label: "Button label",
            value: "Shop now",
            placeholder: ""
        },
        {
            type: "text",
            name: "link",
            label: "Link",
            value: "",
            placeholder: ""
        },
    ]
}