import React from "react";
import { Box, Typography } from "@mui/material";

export default function HeadingFlex({ data }) {
    return (
        <Box sx={{ display: 'flex', justifyContent: "space-between", mb: 3 }}>
            <Typography variant="button" component="div">
                {data.text} ({data.value || 0})
            </Typography>
        </Box>
    )
}