export default {
    header: [
        {
            type: "heading",
            text: "Logo image"
        },
        {
            type: "image_file",
            id: "logo_image",
            name: "logo_image",
            label: "Logo image",
            note: "",
            height: "70px",
            width: "200px",
            alt_name: "logo_alt",
            alt: "Logo",
            value: ""
        },
        {
            type: "heading",
            text: "Logo Layout"
        },
        {
            type: "select",
            label: "Choose layout",
            name: "layout",
            value: "layout1",
            items: [
                { name: "Layout 1", value: "layout1" },
                { name: "Layout 2", value: "layout2" },
            ]
        },
        {
            type: "heading",
            text: "Logo alignment"
        },
        {
            type: "radio",
            name: "logo_alignment",
            options: [
                {
                    id: "logo_align_left",
                    label: "Left",
                    checked: true,
                    value: "left",
                },
                {
                    id: "logo_align_center",
                    label: "Center",
                    checked: false,
                    value: "center",
                }
            ]
        },
        {
            type: "heading",
            text: "Logo width"
        },
        {
            type: "range",
            id: "logo_width",
            name: "logo_width",
            label: "",
            value: 100,
            min: 50,
            max: 250,
            step: 10,
            unit: "px"
        },
        {
            type: "heading",
            text: "Header spacing"
        },
        {
            type: "range",
            id: "spacing",
            name: "spacing",
            label: "",
            value: 2,
            min: 0,
            max: 5,
            step: 1,
            unit: ""
        },
        {
            type: "heading",
            text: "Colors"
        },
        {
            type: "color_picker",
            name: "header_color",
            label: "Background color",
            value: "#FFFFFF"
        },
        {
            type: "color_picker",
            name: "header_text_color",
            label: "Text color",
            value: "#000000"
        },
        {
            type: "heading",
            text: "Dark mode colors"
        },
        {
            type: "color_picker",
            name: "dm_header_color",
            label: "Background color",
            value: "#000000"
        },
        {
            type: "color_picker",
            name: "dm_header_text_color",
            label: "Text color",
            value: "#FFFFFF"
        },
        {
            type: "heading",
            text: "Navigation"
        },
        {
            type: "nav_list",
            label: "Static menu",
        },
        {
            type: "select",
            label: "",
            name: "nav_menu",
            value: "",
            items: []
        },
        {
            type: "heading",
            text: "Announcement bar"
        },
        {
            type: "checkbox",
            id: "show_announcement",
            name: "show_announcement",
            label: "Show",
            value: true
        },
        {
            type: "textarea",
            name: "announcement_text",
            label: "Text",
            value: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
            placeholder: "Enter announcement text"
        },
        {
            type: "text",
            name: "announcement_link",
            label: "Link",
            value: "",
            placeholder: "Enter redirect URL"
        },
        {
            type: "heading",
            text: "Announcement colors"
        },
        {
            type: "color_picker",
            name: "bar_color",
            label: "Bar color",
            value: "#000000"
        },
        {
            type: "color_picker",
            name: "bar_text_color",
            label: "Text color",
            value: "#ffffff"
        },
        {
            type: "heading",
            text: "Dark mode colors"
        },
        {
            type: "color_picker",
            name: "dm_bar_color",
            label: "Bar color",
            value: "#000000"
        },
        {
            type: "color_picker",
            name: "dm_bar_text_color",
            label: "Text color",
            value: "#ffffff"
        },
        {
            type: "heading",
            text: "Bar spacing"
        },
        {
            type: "range",
            id: "bar_spacing",
            name: "bar_spacing",
            label: "",
            value: 15,
            min: 0,
            max: 30,
            step: 1,
            unit: "px"
        },
    ],
    footer: [
        {
            type: "list_item",
            label: "Columns",
        },
        {
            type: "heading",
            text: "Logo image"
        },
        {
            type: "image_file",
            id: "logo_image",
            name: "logo_image",
            label: "Logo image",
            note: "",
            height: "70px",
            width: "200px",
            alt_name: "logo_alt",
            alt: "Logo",
            value: ""
        },
        {
            type: "heading",
            text: "Logo width"
        },
        {
            type: "range",
            id: "logo_width",
            name: "logo_width",
            label: "",
            value: 100,
            min: 50,
            max: 250,
            step: 10,
            unit: "px"
        },
        {
            type: "heading",
            text: "Footer content"
        },
        {
            type: "textarea",
            name: "footer_content",
            label: "",
            value: "",
            placeholder: ""
        },
        {
            type: "range",
            label: "Section spacing top",
            id: "section_spacing_top",
            name: "section_spacing_top",
            value: 5,
            min: 0,
            max: 10,
            step: 1,
            unit: "px"
        },
        {
            type: "range",
            label: "Section spacing bottom",
            id: "section_spacing_bottom",
            name: "section_spacing_bottom",
            value: 5,
            min: 0,
            max: 10,
            step: 1,
            unit: "px"
        },
        {
            type: "color_picker",
            name: "section_color",
            label: "Footer color",
            value: ""
        },
        {
            type: "color_picker",
            name: "footer_text_color",
            label: "Text color",
            value: "#000000"
        }
    ],
    typography: [
        {
            type: "heading",
            text: "Headings"
        },
        {
            type: "font_family",
            label: "Font family",
            id: "heading_font",
            name: "heading_font",
            value: "Roboto"
        },
        {
            type: "range",
            label: "Size",
            id: 'heading_base_size',
            value: 30,
            min: 20,
            max: 60,
            step: 2,
            unit: "px"
        },
        {
            type: "select",
            label: "Font weight",
            name: "heading_font_weight",
            value: "",
            items: [
                { name: "Regular", value: "400" },
                { name: "Medium", value: "500" },
                { name: "Semi bold", value: "600" },
                { name: "Bold", value: "700" }
            ]
        },
        {
            type: "divider"
        },
        {
            type: "heading",
            text: "Body text"
        },
        {
            type: "font_family",
            label: "Font family",
            id: "body_font",
            name: "body_font",
            value: "Roboto"
        },
        {
            type: "select",
            label: "Font weight",
            name: "body_font_weight",
            value: "",
            items: [
                { name: "Regular", value: "400" },
                { name: "Medium", value: "500" },
                { name: "Semi bold", value: "600" },
                { name: "Bold", value: "700" }
            ]
        },
        {
            type: "range",
            id: "body_text_base_size",
            label: "Base size",
            value: 16,
            min: 12,
            max: 24,
            step: 1,
            unit: "px"
        },
        {
            type: "heading",
            text: "Button style"
        },
        {
            type: "range",
            id: "button_radius",
            label: "Button radius",
            value: 0,
            min: 0,
            max: 100,
            step: 1,
            unit: "px"
        },
        {
            type: "range",
            id: "button_spacing_y",
            label: "Button spacing(Top/Bottom)",
            value: 0,
            min: 5,
            max: 50,
            step: 5,
            unit: "px"
        },
        {
            type: "range",
            id: "button_spacing_x",
            label: "Button spacing(Left/Right)",
            value: 0,
            min: 5,
            max: 50,
            step: 5,
            unit: "px"
        },
        {
            type: "select",
            label: "Font weight",
            name: "button_font_weight",
            value: "",
            items: [
                { name: "Regular", value: "400" },
                { name: "Medium", value: "500" },
                { name: "Semi bold", value: "600" },
                { name: "Bold", value: "700" }
            ]
        }
    ],
    colors: [
        {
            type: "heading",
            text: "Text colors"
        },
        {
            type: "color_picker",
            id: "heading_color",
            name: "heading_color",
            label: "Headings",
            value: "#000000"
        },
        {
            type: "color_picker",
            id: "body_text_color",
            name: "body_text_color",
            label: "Body text",
            value: "#000000"
        },
        {
            type: "heading",
            text: "Button colors"
        },
        {
            type: "color_picker",
            id: "button_color",
            name: "button_color",
            label: "Button color",
            value: "#000000"
        },
        {
            type: "color_picker",
            id: "button_border_color",
            name: "button_border_color",
            label: "Button border color",
            value: "#000000"
        },
        {
            type: "color_picker",
            id: "button_text_color",
            name: "button_text_color",
            label: "Button text color",
            value: "#ffffff"
        },
        {
            type: "color_picker",
            id: "button_hover_color",
            name: "button_hover_color",
            label: "Button hover color",
            value: "#000000"
        },
        {
            type: "color_picker",
            id: "button_text_hover_color",
            name: "button_text_hover_color",
            label: "Button hover text color",
            value: "#ffffff"
        },
        {
            type: "color_picker",
            id: "button_hover_border_color",
            name: "button_hover_border_color",
            label: "Button hover border color",
            value: "#ffffff"
        },
        {
            type: "heading",
            text: "Body colors"
        },
        {
            type: "color_picker",
            id: "body_bg_color",
            name: "body_bg_color",
            label: "Background",
            value: "#ffffff"
        },
        {
            type: "heading",
            text: "Overlay color"
        },
        {
            type: "color_picker",
            id: "overlay_bg_color",
            name: "overlay_bg_color",
            label: "Background",
            value: "#000000"
        },
        {
            type: "color_picker",
            id: "overlay_text_color",
            name: "overlay_text_color",
            label: "Overlay text color",
            value: "#FFFFFF"
        },
        {
            type: "range",
            id: "overlay_opacity",
            name: "overlay_opacity",
            label: "Opacity",
            value: 40,
            min: 0,
            max: 100,
            step: 5,
            unit: "%"
        }
    ],
    favicon: [
        {
            type: "heading",
            text: "Add favicon image"
        },
        {
            type: "image_file",
            id: "image_scr",
            name: "image_scr",
            label: "Add favicon image",
            note: "Recommended size 64 X 64",
            height: "64px",
            width: "100%",
            alt: "",
            value: ""
        }
    ],
    share_button: [
        {
            type: "heading",
            text: "Social sharing"
        },
        {
            type: "checkbox",
            id: "facebook_share",
            name: "facebook_share",
            label: "Share on Facebook",
            value: true
        },
        {
            type: "checkbox",
            id: "twitter_share",
            name: "twitter_share",
            label: "Share on Twitter",
            value: true
        },
        {
            type: "checkbox",
            id: "whatsapp_share",
            name: "whatsapp_share",
            label: "Share on Whatsapp",
            value: false
        },
        // {
        //     type: "checkbox",
        //     id: "pinterest_share",
        //     name: "pinterest_share",
        //     label: "Share on Pinterest",
        //     value: false
        // },
        {
            type: "checkbox",
            id: "linkedIn_share",
            name: "linkedIn_share",
            label: "Share on LinkedIn",
            value: false
        }
    ],
    social_button: [
        {
            type: "text",
            name: "facebook_link",
            label: "Facebook",
            value: "",
            placeholder: ""
        },
        {
            type: "text",
            name: "twitter_link",
            label: "Twitter",
            value: "",
            placeholder: ""
        },
        {
            type: "text",
            name: "pinterest_link",
            label: "Pinterest",
            value: "",
            placeholder: ""
        },
        {
            type: "text",
            name: "instagram_link",
            label: "Instagram",
            value: "",
            placeholder: ""
        },
        {
            type: "text",
            name: "youtube_link",
            label: "Youtube",
            value: "",
            placeholder: ""
        },
        {
            type: "text",
            name: "vimeo_link",
            label: "Vimeo",
            value: "",
            placeholder: ""
        },
        {
            type: "text",
            name: "tumblr_link",
            label: "Tumblr",
            value: "",
            placeholder: ""
        },
        {
            type: "text",
            name: "snapchat_link",
            label: "Snapchat",
            value: "",
            placeholder: ""
        }
    ],
    hero_slider: [
        {
            type: "heading",
            text: "Slide list"
        },
        {
            type: "list_item",
            label: "Slide",
        },
        {
            type: "heading",
            text: "Slider settings"
        },
        {
            type: "select",
            label: "Text alignment",
            name: "slider_text_alignment",
            value: "",
            items: [
                { name: "Left", value: "left" },
                { name: "Center", value: "center" },
                { name: "Right", value: "right" }
            ]
        },
        {
            type: "select",
            label: "Text alignment on mobile",
            name: "slider_text_alignment_mobile",
            value: "left",
            items: [
                { name: "Left", value: "left" },
                { name: "Center", value: "center" },
                { name: "Right", value: "right" }
            ]
        },
        {
            type: "checkbox",
            id: "show_overlay",
            name: "show_overlay",
            label: "Show overlay",
            value: false
        },
        {
            type: "divider"
        },
        {
            type: "checkbox",
            id: "slider_autoplay",
            name: "autoplay",
            label: "Autoplay",
            value: true
        },
        {
            type: "checkbox",
            id: "slider_loop",
            name: "infinite",
            label: "Infinite",
            value: true
        },
        {
            type: "checkbox",
            id: "slider_arrows",
            name: "arrows",
            label: "Arrows",
            value: true
        },
        {
            type: "checkbox",
            id: "slider_dots",
            name: "dots",
            label: "Pagination",
            value: true
        }
    ],
    text_over_image: [
        {
            type: "heading",
            text: "Image"
        },
        {
            type: "image_file",
            id: "image",
            name: "image",
            label: "Image",
            note: "",
            height: "200px",
            width: "100%",
            alt: "",
            value: ""
        },
        {
            type: "heading",
            text: "Mobile image"
        },
        {
            type: "image_file",
            id: "mobile_image_src",
            name: "mobile_image_src",
            label: "Mobile image",
            note: "",
            height: "150px",
            width: "100%",
            alt_name: "mobile_slide_img_alt",
            alt: "",
            value: ""
        },
        {
            type: "select",
            label: "Image layout",
            name: "layout",
            value: "full",
            items: [
                { name: "Full width", value: "full" },
                { name: "Fixed width", value: "fixed" }
            ]
        },
        {
            type: "divider"
        },
        {
            type: "checkbox",
            id: "show_content",
            name: "show_content",
            label: "Show content",
            value: true
        },
        {
            type: "divider"
        },
        {
            type: "heading",
            text: "Text"
        },
        {
            type: "textarea",
            name: "heading",
            label: "Heading",
            value: "",
            placeholder: ""
        },
        {
            type: "select",
            label: "Main heading",
            name: "headingType",
            value: "",
            items: [
                { name: "h1", value: "h1" },
                { name: "h2", value: "h2" },
                { name: "h3", value: "h3" },
                { name: "h4", value: "h4" },
                { name: "h5", value: "h5" },
                { name: "h6", value: "h6" },
            ]
        },
        {
            type: "editor",
            name: "subheading",
            id: "image_caption_text",
            label: "Text",
            value: "<p></p>",
        },
        {
            type: "text",
            name: "link",
            label: "Link",
            value: "",
            placeholder: ""
        },
        {
            type: "select",
            label: "Position",
            name: "alignment",
            value: "center",
            items: [
                { name: "Top", value: "top" },
                { name: "Center", value: "center" },
                { name: "Bottom", value: "bottom" }
            ]
        },
        {
            type: "select",
            label: "Horizontal position",
            name: "horizontal_alignment",
            value: "center",
            items: [
                { name: "Left", value: "left" },
                { name: "Center", value: "center" },
                { name: "Right", value: "right" }
            ]
        },
        {
            type: "select",
            label: "Size",
            name: "size",
            value: "medium",
            items: [
                { name: "Small", value: "small" },
                { name: "Medium", value: "medium" },
                { name: "Large", value: "large" }
            ]
        },
        {
            type: "heading",
            text: "Mobile text position"
        },
        {
            type: "select",
            label: "Vertical Position",
            name: "mobile_alignment",
            value: "top",
            items: [
                { name: "Top", value: "top" },
                { name: "Center", value: "center" },
                { name: "Bottom", value: "bottom" }
            ]
        },
        {
            type: "select",
            label: "Horizontal position",
            name: "mobile_horizontal_alignment",
            value: "center",
            items: [
                { name: "Left", value: "left" },
                { name: "Center", value: "center" },
                { name: "Right", value: "right" }
            ]
        },
        {
            type: "checkbox",
            id: "show_overlay",
            name: "show_overlay",
            label: "Show overlay",
            value: true
        },
        {
            type: "color_picker",
            name: "text_color",
            label: "Text color",
            value: "#000000"
        },
        {
            type: "heading",
            text: "Button"
        },
        {
            type: "text",
            name: "button_label",
            label: "Label",
            value: "",
            placeholder: ""
        },
        {
            type: "text",
            name: "button_link",
            label: "Link",
            value: "",
            placeholder: "eg: /categories/categoryname"
        },
    ],
    product_list: [
        {
            type: "text",
            id: "heading",
            name: "heading",
            label: "Heading",
            value: "",
            placeholder: ""
        },
        {
            type: "select",
            label: "Main heading",
            name: "headingType",
            value: "",
            items: [
                { name: "h1", value: "h1" },
                { name: "h2", value: "h2" },
                { name: "h3", value: "h3" },
                { name: "h4", value: "h4" },
                { name: "h5", value: "h5" },
                { name: "h6", value: "h6" },
            ]
        },
        {
            type: "select",
            label: "Heading alignment",
            name: "heading_alignment",
            value: "left",
            items: [
                { name: "Left", value: "left" },
                { name: "Center", value: "center" },
                { name: "Right", value: "right" },
            ]
        },
        {
            type: "select",
            label: "Choose category",
            name: "product_category",
            value: "",
            items: []
        },
        {
            type: "divider"
        },
        {
            type: "select",
            label: "Choose layout",
            name: "layout",
            value: "layout1",
            items: [
                { name: "Layout 1", value: "layout1" },
                { name: "Layout 2", value: "layout2" },
                { name: "Layout 3", value: "layout3" },
                { name: "Layout 4", value: "layout4" }
            ]
        },
        {
            type: "select",
            label: "Title heading",
            name: "title_heading",
            value: "h5",
            items: [
                { name: "h1", value: "h1" },
                { name: "h2", value: "h2" },
                { name: "h3", value: "h3" },
                { name: "h4", value: "h4" },
                { name: "h5", value: "h5" },
                { name: "h6", value: "h6" },
            ]
        },
        {
            type: "select",
            label: "Price heading",
            name: "price_heading",
            value: "h6",
            items: [
                { name: "h1", value: "h1" },
                { name: "h2", value: "h2" },
                { name: "h3", value: "h3" },
                { name: "h4", value: "h4" },
                { name: "h5", value: "h5" },
                { name: "h6", value: "h6" },
            ]
        },
        {
            type: "range",
            id: "radius",
            label: "Image radius",
            value: 0,
            min: 0,
            max: 50,
            step: 1,
            unit: "%"
        },
        {
            type: "number",
            id: "show_products",
            name: "show_products",
            label: "Show products",
            value: "",
            placeholder: ""
        },
        {
            type: "range",
            label: "Items per row",
            id: "item_per_row",
            name: "item_per_row",
            value: 3,
            min: 2,
            max: 4,
            step: 1,
            unit: ""
        },
        {
            type: "checkbox",
            id: "is_slider",
            name: "is_slider",
            label: "Show as slider",
            value: false
        },
        {
            type: "range",
            label: "Section spacing top",
            id: "section_spacing_top",
            name: "section_spacing_top",
            value: 5,
            min: 0,
            max: 10,
            step: 1,
            unit: "px"
        },
        {
            type: "range",
            label: "Section spacing bottom",
            id: "section_spacing_bottom",
            name: "section_spacing_bottom",
            value: 5,
            min: 0,
            max: 10,
            step: 1,
            unit: "px"
        },
        {
            type: "color_picker",
            name: "section_color",
            label: "Section color",
            value: ""
        }
    ],
    category_list: [
        {
            type: "text",
            id: "heading",
            name: "heading",
            label: "Heading",
            value: "",
            placeholder: ""
        },
        {
            type: "select",
            label: "Main heading",
            name: "headingType",
            value: "",
            items: [
                { name: "h1", value: "h1" },
                { name: "h2", value: "h2" },
                { name: "h3", value: "h3" },
                { name: "h4", value: "h4" },
                { name: "h5", value: "h5" },
                { name: "h6", value: "h6" },
            ]
        },
        {
            type: "select",
            label: "Heading alignment",
            name: "heading_alignment",
            value: "left",
            items: [
                { name: "Left", value: "left" },
                { name: "Center", value: "center" },
                { name: "Right", value: "right" },
            ]
        },
        {
            type: "select",
            label: "Choose layout",
            name: "layout",
            value: "layout1",
            items: [
                { name: "Layout 1", value: "layout1" },
                { name: "Layout 2", value: "layout2" },
                { name: "Layout 3", value: "layout3" },
            ]
        },
        {
            type: "divider"
        },
        {
            type: "number",
            id: "show_products",
            name: "show_products",
            label: "Show categories",
            value: "",
            placeholder: ""
        },
        {
            type: "range",
            label: "Items per row",
            id: "item_per_row",
            name: "item_per_row",
            value: 3,
            min: 2,
            max: 6,
            step: 1,
            unit: ""
        },
        {
            type: "checkbox",
            id: "is_slider",
            name: "is_slider",
            label: "Show as slider",
            value: false
        },
        {
            type: "range",
            label: "Section spacing top",
            id: "section_spacing_top",
            name: "section_spacing_top",
            value: 5,
            min: 0,
            max: 10,
            step: 1,
            unit: "px"
        },
        {
            type: "range",
            label: "Section spacing bottom",
            id: "section_spacing_bottom",
            name: "section_spacing_bottom",
            value: 5,
            min: 0,
            max: 10,
            step: 1,
            unit: "px"
        },
        {
            type: "color_picker",
            name: "section_color",
            label: "Section color",
            value: ""
        }
    ],
    text_column_with_image: [
        {
            type: "text",
            name: "heading",
            label: "Heading",
            value: "",
            placeholder: ""
        },
        {
            type: "select",
            label: "Main heading",
            name: "headingType",
            value: "",
            items: [
                { name: "h1", value: "h1" },
                { name: "h2", value: "h2" },
                { name: "h3", value: "h3" },
                { name: "h4", value: "h4" },
                { name: "h5", value: "h5" },
                { name: "h6", value: "h6" },
            ]
        },
        {
            type: "select",
            label: "Heading alignment",
            name: "heading_alignment",
            value: "left",
            items: [
                { name: "Left", value: "left" },
                { name: "Center", value: "center" },
                { name: "Right", value: "right" },
            ]
        },
        {
            type: "range",
            label: "Items per row",
            id: "item_per_row",
            name: "item_per_row",
            value: 3,
            min: 2,
            max: 4,
            step: 1,
            unit: ""
        },
        {
            type: "range",
            label: "Items per row on mobile",
            id: "item_per_row_mobile",
            name: "item_per_row_mobile",
            value: 2,
            min: 1,
            max: 2,
            step: 1,
            unit: ""
        },
        {
            type: "list_item",
            label: "Column",
        },
        {
            type: "select",
            label: "Text alignment",
            name: "text_alignment",
            value: "left",
            items: [
                { name: "Left", value: "left" },
                { name: "Center", value: "center" },
                { name: "Right", value: "right" },
            ]
        },
        {
            type: "range",
            label: "Section spacing top",
            id: "section_spacing_top",
            name: "section_spacing_top",
            value: 5,
            min: 0,
            max: 10,
            step: 1,
            unit: "px"
        },
        {
            type: "range",
            label: "Section spacing bottom",
            id: "section_spacing_bottom",
            name: "section_spacing_bottom",
            value: 5,
            min: 0,
            max: 10,
            step: 1,
            unit: "px"
        },
        {
            type: "color_picker",
            name: "section_color",
            label: "Section color",
            value: ""
        }
    ],
    gallery: [
        {
            type: "text",
            name: "heading",
            label: "Heading",
            value: "",
            placeholder: ""
        },
        {
            type: "select",
            label: "Main heading",
            name: "headingType",
            value: "",
            items: [
                { name: "h1", value: "h1" },
                { name: "h2", value: "h2" },
                { name: "h3", value: "h3" },
                { name: "h4", value: "h4" },
                { name: "h5", value: "h5" },
                { name: "h6", value: "h6" },
            ]
        },
        {
            type: "select",
            label: "Heading alignment",
            name: "heading_alignment",
            value: "left",
            items: [
                { name: "Left", value: "left" },
                { name: "Center", value: "center" },
                { name: "Right", value: "right" },
            ]
        },
        {
            type: "range",
            label: "Items per row",
            id: "item_per_row",
            name: "item_per_row",
            value: 3,
            min: 2,
            max: 5,
            step: 1,
            unit: ""
        },
        {
            type: "select",
            label: "Gallery type",
            name: "type",
            value: "masonry",
            items: [
                { name: "Masonry", value: "masonry" },
                { name: "Fixed", value: "fixed" }
            ]
        },
        // {
        //     type: "checkbox",
        //     id: "lightbox",
        //     name: "lightbox",
        //     label: "Enable lightbox",
        //     value: false
        // },
        {
            type: "divider"
        },
        {
            type: "list_item",
            label: "Image",
        },
        {
            type: "range",
            label: "Section spacing top",
            id: "section_spacing_top",
            name: "section_spacing_top",
            value: 5,
            min: 0,
            max: 10,
            step: 1,
            unit: "px"
        },
        {
            type: "range",
            label: "Section spacing bottom",
            id: "section_spacing_bottom",
            name: "section_spacing_bottom",
            value: 5,
            min: 0,
            max: 10,
            step: 1,
            unit: "px"
        },
        {
            type: "color_picker",
            name: "section_color",
            label: "Section color",
            value: ""
        }
    ],
    content_block: [
        {
            type: "text",
            name: "heading",
            label: "Section Heading",
            value: "Lorem ipsum dolor",
            placeholder: ""
        },
        {
            type: "select",
            label: "Main heading",
            name: "headingType",
            value: "",
            items: [
                { name: "h1", value: "h1" },
                { name: "h2", value: "h2" },
                { name: "h3", value: "h3" },
                { name: "h4", value: "h4" },
                { name: "h5", value: "h5" },
                { name: "h6", value: "h6" },
            ]
        },
        {
            type: "select",
            label: "Heading alignment",
            name: "heading_alignment",
            value: "left",
            items: [
                { name: "Left", value: "left" },
                { name: "Center", value: "center" },
                { name: "Right", value: "right" },
            ]
        },
        {
            type: "select",
            label: "Order",
            id: "order",
            name: "order",
            value: "default",
            items: [
                { name: "Default", value: "default" },
                { name: "Reverse", value: "reverse" },
            ]
        },
        {
            type: "image_file",
            id: "image",
            label: "Image",
            note: "",
            height: "",
            width: "100%",
            name: "image",
            alt: "",
            value: "https://checkout-dev.growcart.app/side-image.jpg"
        },
        {
            type: "select",
            label: "Content alignment",
            name: "alignment",
            value: "center",
            items: [
                { name: "Top", value: "top" },
                { name: "Center", value: "center" },
                { name: "Bottom", value: "bottom" },
            ]
        },
        {
            type: "textarea",
            name: "content_heading",
            label: "Heading",
            value: "Lorem ipsum dolor sit",
            placeholder: ""
        },
        {
            type: "select",
            label: "Content heading",
            name: "content_headingType",
            value: "",
            items: [
                { name: "h1", value: "h1" },
                { name: "h2", value: "h2" },
                { name: "h3", value: "h3" },
                { name: "h4", value: "h4" },
                { name: "h5", value: "h5" },
                { name: "h6", value: "h6" },
            ]
        },
        {
            type: "editor",
            name: "paragraph",
            id: "paragraph",
            label: "Text",
            value: "<p></p>",
        },
        {
            type: "text",
            name: "button_label",
            label: "Button label",
            value: "Shop now",
            placeholder: ""
        },
        {
            type: "text",
            name: "button_link",
            label: "Button link",
            value: "",
            placeholder: ""
        },
        {
            type: "range",
            label: "Section spacing top",
            id: "section_spacing_top",
            name: "section_spacing_top",
            value: 5,
            min: 0,
            max: 10,
            step: 1,
            unit: "px"
        },
        {
            type: "range",
            label: "Section spacing bottom",
            id: "section_spacing_bottom",
            name: "section_spacing_bottom",
            value: 5,
            min: 0,
            max: 10,
            step: 1,
            unit: "px"
        },
        {
            type: "color_picker",
            name: "section_color",
            label: "Section color",
            value: ""
        }
    ],
    brands_list: [
        {
            type: "text",
            name: "heading",
            label: "Heading",
            value: "Heading goes here",
            placeholder: ""
        },
        {
            type: "select",
            label: "Main heading",
            name: "headingType",
            value: "",
            items: [
                { name: "h1", value: "h1" },
                { name: "h2", value: "h2" },
                { name: "h3", value: "h3" },
                { name: "h4", value: "h4" },
                { name: "h5", value: "h5" },
                { name: "h6", value: "h6" },
            ]
        },
        {
            type: "select",
            label: "Heading alignment",
            name: "heading_alignment",
            value: "left",
            items: [
                { name: "Left", value: "left" },
                { name: "Center", value: "center" },
                { name: "Right", value: "right" },
            ]
        },
        {
            type: "select",
            label: "Layout",
            name: "layout",
            value: "fixed",
            items: [
                { name: "Fixed", value: "fixed" },
                { name: "Full width", value: "full" },
            ]
        },
        {
            type: "range",
            label: "Items per row",
            id: "item_per_row",
            name: "item_per_row",
            value: 4,
            min: 2,
            max: 8,
            step: 1,
            unit: ""
        },
        {
            type: "checkbox",
            id: "is_slider",
            name: "is_slider",
            label: "Show as slider",
            value: true
        },
        {
            type: "checkbox",
            id: "hover",
            name: "hover",
            label: "Hover effect",
            value: false
        },
        {
            type: "divider"
        },
        {
            type: "list_item",
            label: "Brand",
        },
        {
            type: "range",
            label: "Section spacing top",
            id: "section_spacing_top",
            name: "section_spacing_top",
            value: 5,
            min: 0,
            max: 10,
            step: 1,
            unit: "px"
        },
        {
            type: "range",
            label: "Section spacing bottom",
            id: "section_spacing_bottom",
            name: "section_spacing_bottom",
            value: 5,
            min: 0,
            max: 10,
            step: 1,
            unit: "px"
        },      
        {
            type: "range",
            id: "radius",
            label: "Image radius",
            value: 0,
            min: 0,
            max: 500,
            step: 5,
            unit: "%"
        },
        {
            type: "color_picker",
            name: "section_color",
            label: "Section color",
            value: ""
        }
    ],
    map: [
        {
            type: "text",
            name: "src",
            label: "Map src",
            value: "",
            placeholder: "Add map embeded code"
        },
        {
            type: "text",
            name: "heading",
            label: "Map heading",
            value: "",
            placeholder: ""
        },
        {
            type: "select",
            label: "Main heading",
            name: "headingType",
            value: "",
            items: [
                { name: "h1", value: "h1" },
                { name: "h2", value: "h2" },
                { name: "h3", value: "h3" },
                { name: "h4", value: "h4" },
                { name: "h5", value: "h5" },
                { name: "h6", value: "h6" },
            ]
        },
        // {
        //     type: "text",
        //     name: "map_address",
        //     label: "Store address",
        //     value: "",
        //     placeholder: ""
        // },
        {
            type: "editor",
            name: "address_text",
            id: "address_text",
            label: "Address text",
            value: "",
        },
        {
            type: "select",
            label: "Vertical position",
            name: "vertical",
            value: "center",
            items: [
                { name: "Top", value: "top" },
                { name: "Center", value: "center" },
                { name: "Bottom", value: "bottom" }
            ]
        },
        {
            type: "select",
            label: "Horizontal position",
            name: "horizontal",
            value: "left",
            items: [
                { name: "Left", value: "left" },
                { name: "Center", value: "center" },
                { name: "Right", value: "right" }
            ]
        },
    ],
    heading_block: [
        {
            type: "text",
            name: "heading",
            id: "heading",
            label: "Heading",
            value: "",
            placeholder: ""
        },
        {
            type: "select",
            label: "Main heading",
            name: "headingType",
            value: "",
            items: [
                { name: "h1", value: "h1" },
                { name: "h2", value: "h2" },
                { name: "h3", value: "h3" },
                { name: "h4", value: "h4" },
                { name: "h5", value: "h5" },
                { name: "h6", value: "h6" },
            ]
        },
        {
            type: "select",
            label: "Heading alignment",
            name: "heading_alignment",
            value: "left",
            items: [
                { name: "Left", value: "left" },
                { name: "Center", value: "center" },
                { name: "Right", value: "right" },
            ]
        },
        {
            type: "editor",
            name: "subheading",
            id: "subheading",
            label: "Subheading",
            value: "<p></p>",
        },
        {
            type: "range",
            label: "Top spacing",
            id: "top_spacing",
            name: "top_spacing",
            value: 10,
            min: 10,
            max: 100,
            step: 10,
            unit: "px"
        },
        {
            type: "range",
            label: "Bottom spacing",
            id: "bottom_spacing",
            name: "bottom_spacing",
            value: 10,
            min: 10,
            max: 100,
            step: 10,
            unit: "px"
        }
    ],
    testimonials: [
        {
            type: "text",
            name: "heading",
            label: "Heading",
            value: "",
            placeholder: ""
        },
        {
            type: "select",
            label: "Main heading",
            name: "headingType",
            value: "",
            items: [
                { name: "h1", value: "h1" },
                { name: "h2", value: "h2" },
                { name: "h3", value: "h3" },
                { name: "h4", value: "h4" },
                { name: "h5", value: "h5" },
                { name: "h6", value: "h6" },
            ]
        },
        {
            type: "select",
            label: "Heading alignment",
            name: "heading_alignment",
            value: "left",
            items: [
                { name: "Left", value: "left" },
                { name: "Center", value: "center" },
                { name: "Right", value: "right" },
            ]
        },
        {
            type: "range",
            label: "Items per row",
            id: "item_per_row",
            name: "item_per_row",
            value: 1,
            min: 1,
            max: 3,
            step: 1,
            unit: ""
        },
        {
            type: "divider"
        },
        {
            type: "list_item",
            label: "Testimonial",
        },
        {
            type: "range",
            label: "Section spacing top",
            id: "section_spacing_top",
            name: "section_spacing_top",
            value: 5,
            min: 0,
            max: 10,
            step: 1,
            unit: "px"
        },
        {
            type: "range",
            label: "Section spacing bottom",
            id: "section_spacing_bottom",
            name: "section_spacing_bottom",
            value: 5,
            min: 0,
            max: 10,
            step: 1,
            unit: "px"
        },
        {
            type: "select",
            label: "Layout",
            name: "layout",
            value: "list",
            items: [
                { name: "Layout 1", value: "layout1" },
                { name: "Layout 2", value: "layout2" },
            ]
        },
        {
            type: "color_picker",
            name: "section_color",
            label: "Section color",
            value: ""
        }
    ],
    blog_post: [
        {
            type: "text",
            name: "heading",
            id: "heading",
            label: "Heading",
            value: "Blog posts",
            placeholder: ""
        },
        {
            type: "select",
            label: "Main heading",
            name: "headingType",
            value: "",
            items: [
                { name: "h1", value: "h1" },
                { name: "h2", value: "h2" },
                { name: "h3", value: "h3" },
                { name: "h4", value: "h4" },
                { name: "h5", value: "h5" },
                { name: "h6", value: "h6" },
            ]
        },
        {
            type: "select",
            label: "Heading alignment",
            name: "heading_alignment",
            value: "left",
            items: [
                { name: "Left", value: "left" },
                { name: "Center", value: "center" },
                { name: "Right", value: "right" },
            ]
        },
        // {
        //     type: "heading",
        //     text: "Blog"
        // },
        // {
        //     type: "nav_list",
        //     label: "Static blog",
        //     current: {
        //         name: "News",
        //         link: ""
        //     }
        // },
        // {
        //     type: "select",
        //     label: "",
        //     name: "blog_category",
        //     value: "news_blog",
        //     items: [
        //         { name: "News", value: "news_blog" },
        //         { name: "Fashion", value: "fashion_blog" }
        //     ]
        // },
        {
            type: "divider"
        },
        {
            type: "range",
            label: "Show posts",
            id: "show_posts",
            name: "show_posts",
            value: 3,
            min: 2,
            max: 6,
            step: 1,
            unit: ""
        },
        {
            type: "select",
            label: "Post heading",
            name: "post_headingType",
            value: "h4",
            items: [
                { name: "h1", value: "h1" },
                { name: "h2", value: "h2" },
                { name: "h3", value: "h3" },
                { name: "h4", value: "h4" },
                { name: "h5", value: "h5" },
                { name: "h6", value: "h6" },
            ]
        },
        {
            type: "checkbox",
            id: "show_date",
            name: "show_date",
            label: "Show date",
            value: true
        },
        {
            type: "checkbox",
            id: "show_author",
            name: "show_author",
            label: "Show author",
            value: true
        },
        {
            type: "range",
            label: "Section spacing top",
            id: "section_spacing_top",
            name: "section_spacing_top",
            value: 5,
            min: 0,
            max: 10,
            step: 1,
            unit: "px"
        },
        {
            type: "range",
            label: "Section spacing bottom",
            id: "section_spacing_bottom",
            name: "section_spacing_bottom",
            value: 5,
            min: 0,
            max: 10,
            step: 1,
            unit: "px"
        },
        {
            type: "color_picker",
            name: "section_color",
            label: "Section color",
            value: ""
        }
    ],
    newsletter: [
        {
            type: "text",
            name: "heading",
            id: "heading",
            label: "Heading",
            value: "",
            placeholder: ""
        },
        {
            type: "select",
            label: "Main heading",
            name: "headingType",
            value: "",
            items: [
                { name: "h1", value: "h1" },
                { name: "h2", value: "h2" },
                { name: "h3", value: "h3" },
                { name: "h4", value: "h4" },
                { name: "h5", value: "h5" },
                { name: "h6", value: "h6" },
            ]
        },
        {
            type: "select",
            label: "Heading alignment",
            name: "heading_alignment",
            value: "left",
            items: [
                { name: "Left", value: "left" },
                { name: "Center", value: "center" },
                { name: "Right", value: "right" },
            ]
        },
        {
            type: "editor",
            name: "subheading",
            id: "subheading",
            label: "Subheading",
            value: "<p></p>",
        },
        {
            type: "text",
            name: "button_label",
            label: "Button label",
            value: "",
            placeholder: ""
        },
    ],
    video: [
        {
            type: "text",
            name: "heading",
            id: "heading",
            label: "Heading",
            value: "",
            placeholder: ""
        },
        {
            type: "select",
            label: "Main heading",
            name: "headingType",
            value: "",
            items: [
                { name: "h1", value: "h1" },
                { name: "h2", value: "h2" },
                { name: "h3", value: "h3" },
                { name: "h4", value: "h4" },
                { name: "h5", value: "h5" },
                { name: "h6", value: "h6" },
            ]
        },
        {
            type: "select",
            label: "Heading alignment",
            name: "heading_alignment",
            value: "left",
            items: [
                { name: "Left", value: "left" },
                { name: "Center", value: "center" },
                { name: "Right", value: "right" },
            ]
        },
        {
            type: "select",
            label: "Video type",
            name: "video_type",
            value: "src",
            items: [
                { name: "Source", value: "src" },
                { name: "Embedded", value: "embedded" },
            ]
        },
        {
            type: "text",
            name: "video_link",
            label: "Video link",
            value: "https://player.vimeo.com/external/270064133.sd.mp4?s=1c8e9a564534a58168212ade095a0366491fdd5d&profile_id=164&oauth2_token_id=57447761",
            placeholder: ""
        },
        {
            type: "divider"
        },
        // {
        //     type: "select",
        //     label: "Style",
        //     name: "video_height",
        //     value: "large",
        //     items: [
        //         { name: "Small", value: "small" },
        //         { name: "Medium", value: "medium" },
        //         { name: "Large", value: "large" },
        //     ]
        // },
        {
            type: "select",
            label: "Style",
            name: "style",
            value: "background_video",
            items: [
                { name: "Background video", value: "background_video" },
                { name: "Fixed width", value: "fixed" }
            ]
        },
        {
            type: "checkbox",
            id: "show_overlay",
            name: "show_overlay",
            label: "Show overlay",
            value: true
        },
        {
            type: "checkbox",
            id: "show_controls",
            name: "show_controls",
            label: "Show controls",
            value: false
        },
        {
            type: "range",
            label: "Section spacing top",
            id: "section_spacing_top",
            name: "section_spacing_top",
            value: 5,
            min: 0,
            max: 10,
            step: 1,
            unit: "px"
        },
        {
            type: "range",
            label: "Section spacing bottom",
            id: "section_spacing_bottom",
            name: "section_spacing_bottom",
            value: 5,
            min: 0,
            max: 10,
            step: 1,
            unit: "px"
        },
        {
            type: "color_picker",
            name: "section_color",
            label: "Section color",
            value: ""
        }
    ],
    list_style: [
        {
            type: "list_item",
            label: "List item",
        },
        {
            type: "select",
            label: "Layout",
            name: "layout",
            value: "list",
            items: [
                { name: "List", value: "list" },
                { name: "Order List", value: "order_list" },
            ]
        },
    ],
    custom_html: [
        {
            type: "html_editor",
            name: "code",
            id: "code",
            label: "Code",
            value: "",
            placeholder: "Enter HTML code here"
        },
        {
            type: "range",
            label: "Section spacing top",
            id: "section_spacing_top",
            name: "section_spacing_top",
            value: 5,
            min: 0,
            max: 10,
            step: 1,
            unit: "px"
        },
        {
            type: "range",
            label: "Section spacing bottom",
            id: "section_spacing_bottom",
            name: "section_spacing_bottom",
            value: 5,
            min: 0,
            max: 10,
            step: 1,
            unit: "px"
        },
        {
            type: "color_picker",
            name: "section_color",
            label: "Section color",
            value: ""
        }
    ],
    product_detail: [        
        {
            type: "checkbox",
            id: "show_product_type",
            name: "show_product_type",
            label: "Show product type",
            value: true
        },
        {
            type: "checkbox",
            id: "show_vender",
            name: "show_vender",
            label: "Show Brand",
            value: true
        },
        {
            type: "checkbox",
            id: "show_sku",
            name: "show_sku",
            label: "Show SKU",
            value: true
        },
        {
            type: "color_picker",
            name: "text_color",
            label: "Text color",
            value: ""
        },
        {
            type: "select",
            label: "Description position",
            name: "description_position",
            value: "fixed",
            items: [
                { name: "Fixed", value: "fixed" },
                { name: "Full width", value: "full" },
            ]
        },
        {
            type: "select",
            label: "Options type",
            name: "option_type",
            value: "select",
            items: [
                { name: "Select", value: "select" },
                { name: "Buttons", value: "button" },
            ]
        },
    ],
    category_list_page: [
        {
            type: "checkbox",
            id: "product_type",
            name: "product_type",
            label: "Show product type",
            value: true
        },
        {
            type: "checkbox",
            id: "vendor",
            name: "vendor",
            label: "Show Brand",
            value: true
        },
        {
            type: "checkbox",
            id: "product_tag",
            name: "product_tag",
            label: "Show product tags",
            value: true
        },
        {
            type: "divider"
        },
        {
            type: "select",
            label: "Banner position",
            name: "banner_position",
            value: "fixed",
            items: [
                { name: "Fixed", value: "fixed" },
                { name: "Full width", value: "full" },
            ]
        },
        {
            type: "checkbox",
            id: "show_banner",
            name: "show_banner",
            label: "Show banner",
            value: true
        },
        {
            type: "checkbox",
            id: "show_title",
            name: "show_title",
            label: "Show title",
            value: true
        },
        {
            type: "checkbox",
            id: "show_description",
            name: "show_description",
            label: "Show description",
            value: true
        },
        {
            type: "divider"
        },
        {
            type: "select",
            label: "Product layout",
            name: "layout",
            value: "layout1",
            items: [
                { name: "Layout 1", value: "layout1" },
                { name: "Layout 2", value: "layout2" },
                { name: "Layout 3", value: "layout3" },
                { name: "Layout 4", value: "layout4" }
            ]
        },
        {
            type: "number",
            id: "show_products",
            name: "show_products",
            label: "Show products",
            value: "",
            placeholder: ""
        },
        {
            type: "range",
            label: "Items per row",
            id: "item_per_row",
            name: "item_per_row",
            value: 3,
            min: 2,
            max: 4,
            step: 1,
            unit: ""
        }
    ],
    category_page: [
        {
            type: "select",
            label: "Choose layout",
            name: "layout",
            value: "layout1",
            items: [
                { name: "Layout 1", value: "layout1" },
                { name: "Layout 2", value: "layout2" },
                { name: "Layout 3", value: "layout3" },
            ]
        },
        {
            type: "divider"
        },
        {
            type: "range",
            label: "Items per row",
            id: "item_per_row",
            name: "item_per_row",
            value: 3,
            min: 2,
            max: 6,
            step: 1,
            unit: ""
        },
        {
            type: "range",
            label: "Section spacing top",
            id: "section_spacing_top",
            name: "section_spacing_top",
            value: 5,
            min: 0,
            max: 10,
            step: 1,
            unit: "px"
        },
        {
            type: "range",
            label: "Section spacing bottom",
            id: "section_spacing_bottom",
            name: "section_spacing_bottom",
            value: 5,
            min: 0,
            max: 10,
            step: 1,
            unit: "px"
        },
        {
            type: "color_picker",
            name: "section_color",
            label: "Section color",
            value: ""
        }
    ],
}