import React, { useCallback, useEffect, useState } from "react";
import { Divider, Link } from "@mui/material";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ImagePicker from "../Layout/Sidebar/Components/ImagePicker";
import TextFields from "../Layout/Sidebar/Components/TextField";
import SelectBox from "../Layout/Sidebar/Components/Select";
import CheckBox from "../Layout/Sidebar/Components/CheckBox";
import RadioButton from "../Layout/Sidebar/Components/RadioButton";
import Colors from "../Layout/Sidebar/Components/ColorPicker";
import RangeSlider from "../Layout/Sidebar/Components/RangeSlider";
import TextEditor from "../Layout/Sidebar/Components/TextEditor";
import HTMLEditor from "../Layout/Sidebar/Components/HTMLEditor";
import Heading from "../Layout/Sidebar/Components/Heading";
import ListItems from "../Layout/Sidebar/Components/Common/ListItem/ListItems";
import FontFamily from "../Layout/Sidebar/Components/FontFamily";
import { Box } from "@mui/system";
import NavList from "../Layout/Sidebar/Components/NavList";
import { useDispatch, useSelector } from "react-redux";
import _ from "underscore";
import settingSchema from "./setting-schema";
import main_data from "./main";
import item_data from "./Items";
import htmlToDraft from "html-to-draftjs";

import update from "immutability-helper";

import { dataValue } from "../Layout/CommonReducer";
import { EditorState, convertToRaw, ContentState, convertFromHTML } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { useLocation, useNavigate } from "react-router-dom";
import HeadingFlex from "../Layout/Sidebar/Components/HeadingFlex";
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { useUnload } from "../../../Container/config/Apis";
// import HeadingBlock from "../Components/ThemeEditor/Layout/Container/Components/HeadingBlock";

import * as Api from "../../../Container/config/Apis"
import config from '../../../Container/config';
import { useSnackbar } from "notistack";

import Images from "../Schema/images";
import TextArea from "../Layout/Sidebar/Components/TextArea";
import TextFieldNumber from "../Layout/Sidebar/Components/TextFieldNumber";


export const drawerWidth = 220;

export const renderImport = (arr) => {
    return (
        <React.Fragment>
            {arr.length > 0 && arr.map((opt, index) => {
                return (
                    <Box key={index.toString()}>
                        <RenderFn data={opt} />
                    </Box>
                )
            })}
        </React.Fragment>
    )
}


export default function RenderFn({ data }) {
    const params = new URLSearchParams(window.location.search)
    const dataVal = useSelector((state) => state.dataValue)
    console.log("dataVal export: ", dataVal)
    const { enqueueSnackbar } = useSnackbar();
    const snack = (msg, variant) => {
        enqueueSnackbar(msg, { variant });
    }
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [schemaData, setSchemaData] = useState(dataVal)
    const [mainData, setMainData] = useState(main_data)
    const [itemData, setItemData] = useState(item_data)
    const [main, setMain] = useState([])
    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty()
    );
    const location = useLocation()
    const [activeDetail, setactiveDetail] = useState([])
    const [array, setArray] = useState([])
    const [disableBtn, setdisableBtn] = useState(false)
    const [showLoader, setshowLoader] = useState(false)
    const [hasNoMoreData, sethasNoMoreData] = useState(false)

    const [page, setPage] = useState(1)
    const [contentState, setcontentState] = useState()
    // const [schemaType, setschemaType] = useState((params.get('type') == "header" || params.get('type') == "footer") ? schemaData?.common : schemaData[dataVal?.active_page]?.components)

    console.log("mainData: ", mainData)
    console.log("mainData main: ", main)

    // //Default dispatch function
    const dispatchFn = useCallback((id, val) => {
        let currentId = {}
        let currentData = {}
        let newSchemaData = { ...dataVal }
        let newObj = {}
        if (!_.isEmpty(dataVal) && dataVal?.active_page) {
            if (dataVal?.active_page == "product_detail" || dataVal?.active_page == "category_list_page" || dataVal?.active_page == "category_page") {
                newObj = { [id]: val }
                newSchemaData = {
                    ...newSchemaData,
                    [dataVal?.active_page]: {
                        ...newSchemaData[[dataVal?.active_page]],
                        [id]: val
                    }
                }
            } else {
                if (params.get('type') == "header" || params.get('type') == "footer" || params.get('context') == "theme") {
                    currentId = newSchemaData["common"] && newSchemaData["common"][params.get('type')]
                    if (params.get('item')) {
                        let newSchemaDataItems = currentId?.items
                        let arr2 = []
                        newSchemaDataItems.map((opt) => {
                            if (opt?.id == params.get('item')) {
                                arr2.push({ ...opt, [id]: val })
                            } else {
                                arr2.push({ ...opt })
                            }
                        })
                        newObj = { ...currentId, 'items': arr2 }
                    } else {
                        newObj = { ...currentId, [id]: val }
                    }
                    newSchemaData = {
                        ...newSchemaData,
                        "common": {
                            ...newSchemaData["common"],
                            [params.get('type')]: newObj
                        }
                    }
                } else {
                    // currentId = newSchemaData[newSchemaData?.active_page]?.active.filter((x)=>x.id == params.get('id'))
                    currentId = _.where(newSchemaData[newSchemaData?.active_page]?.active, { id: params.get('id') })
                    console.log("currentId----", currentId)
                    if (currentId && currentId.length > 0) {
                        let arr = []
                        if (params.get('item')) {
                            let newSchemaDataItems = currentId[0]?.data?.items
                            let arr2 = []
                            newSchemaDataItems.map((opt) => {
                                if (opt?.id == params.get('item')) {
                                    arr2.push({ ...opt, [id]: val })
                                } else {
                                    arr2.push({ ...opt })
                                }
                            })
                            newObj = { ...currentId[0]?.data, 'items': arr2 }

                        } else {
                            newObj = { ...currentId[0]?.data, [id]: val }
                        }
                        console.log("newObj----------------------------", newObj, "id:--", id, "val----", val)
                        newSchemaData[newSchemaData?.active_page].active.map((opt) => {
                            if (opt.id == params.get('id')) {
                                arr.push({ ...opt, data: newObj })
                            } else {
                                arr.push({ ...opt })
                            }
                        })
                        console.log("arr----------------------------", arr)
                        newSchemaData = {
                            ...newSchemaData,
                            [newSchemaData?.active_page]: {
                                "active": arr
                            }
                        }
                    }
                }
            }


            console.log("currentId: ", currentId, "----------newObj: ", newObj)

            // document.getElementById('customizer').contentWindow.location.reload(true)

            console.log("newSchemaData: ", newSchemaData)
            // setSchemaData(newSchemaData)
            dispatch(dataValue(newSchemaData))
            setTimeout(() => {
                dispatch(dataValue({ "update": newObj }))
            }, 500);
        }
    }, [main, dataVal])

    console.log("schemaData: ", schemaData)
    //Set/Delete param
    const setparam = (key, val) => {
        params.set(key, val)
        const to = { pathname: location.pathname, search: params.toString() };
        navigate(to, { replace: true });
    }
    const deleteparam = (key) => {
        params.delete(key)
        const to = { pathname: location.pathname, search: params.toString() };
        navigate(to, { replace: true });
    }

    const updateTextDescription = async (state, name) => {
        await setEditorState(state);
        console.log("change..........")
        // const htmlData = draftToHtml(
        //     convertToRaw(editorState && editorState.getCurrentContent())
        // )
        // setMain([...main])
    }

    useUnload(e => {
        if (dataVal?.update) {
            e.preventDefault();
        }
        // e.returnValue = '';
    });

    const onEditorBlur = useCallback(async (e, contentState, name) => {
        const htmlData = draftToHtml(
            convertToRaw(contentState && contentState.getCurrentContent())
        )
        console.log("htmlData: ", htmlData)
        dispatchFn(name, htmlData)
    }, [main])

    const onContentStateChange = useCallback(async (contentState, name) => {
        // main.map((opt) => {
        //     let i = ""
        //     if (opt.name && opt.name === name) {
        //         console.log("opt editor: ", opt)
        //         i = _.findLastIndex(main, { id: opt.id })
        //         if (i !== -1) {
        //             main[i].value = draftToHtml(contentState)
        //         }
        //     }
        // })


        let arr = []
        console.log("contentState", contentState)
        main.map((opt) => {
            if (opt.name == name) {
                const currentPlainText = opt?.value;
                const newPlainText = draftToHtml(contentState);
                if (currentPlainText.trim() !== newPlainText.trim()) {
                    console.log("contentState yesssss")
                    // dispatchFn(name, draftToHtml(contentState))
                    setcontentState(draftToHtml(contentState))
                } else {
                    console.log("contentState nooooooo")
                }
                arr.push({ ...opt, value: draftToHtml(contentState) })
            } else {
                arr.push({ ...opt })
            }
        })
        // setMain(arr)


        // setMain([...main])
    }, [main])

    const handleHtmlChange = useCallback(async (name, val) => {
        const delayDebounceFn = setTimeout(() => {
            console.log("handleHtmlChange name", name)
            console.log("handleHtmlChange val", val)
            dispatchFn(name, val)
            let arr = []
            main.map((opt) => {
                if (opt.name == name) {
                    arr.push({ ...opt, value: val })
                } else {
                    arr.push({ ...opt })
                }
            })
            setMain(arr)
        }, 1000);

        return () => clearTimeout(delayDebounceFn);
    }, [main])


    //Range slider onchange
    const rangeValue = useCallback((e, value, thumb, unit) => {
        dispatchFn(e.target.name, value)
        // main.filter((obj) => obj.name === e.target.name).map((opt) => {
        let arr = []
        main.map((opt) => {
            if (opt.name == e.target.name) {
                arr.push({ ...opt, value: value })
            } else {
                arr.push({ ...opt })
            }
        })
        setMain(arr)
        return `${value} ${unit}`;
    }, [main])

    //Color picker on change
    const handleColorChange = useCallback((name, val) => {
        console.log("val color: ", val)
        dispatchFn(name, `rgba(${val?.rgb?.r},${val?.rgb?.g},${val?.rgb?.b},${val?.rgb?.a})`)
        let arr = []
        main.map((opt) => {
            if (opt.name == name) {
                arr.push({ ...opt, value: `rgba(${val?.rgb?.r},${val?.rgb?.g},${val?.rgb?.b},${val?.rgb?.a})` })
            } else {
                arr.push({ ...opt })
            }
        })
        setMain(arr)
    }, [main])

    //Radio button on change
    const handleRadioChange = useCallback((e, id) => {
        dispatchFn(e.target.name, e.target.value)
        // main.filter((obj) => obj.name === e.target.name).map((opt) => {
        //     opt.options.map((val) => {
        //         let val2 = {...val}
        //         if (val.id !== id) {
        //             val2["checked"] = false
        //         } else {
        //             val2["checked"] = e.target.checked
        //         }
        //         return val2
        //     })
        // })
        // setMain(main)
        let arr = []
        main.map((opt) => {
            if (opt.name == e.target.name) {
                let optArr = []
                opt.options.map((val) => {
                    if (val.id == id) {
                        optArr.push({ ...val, checked: true })
                    } else {
                        optArr.push({ ...val, checked: false })
                    }
                })
                arr.push({ ...opt, value: e.target.value, options: optArr })
            } else {
                arr.push({ ...opt })
            }
        })
        console.log("arr radio: ", arr)
        setMain(arr)
    }, [main])

    //Checkbox button on change
    const handleCheckboxChange = useCallback((e) => {
        dispatchFn(e.target.name, e.target.checked)
        // main.filter((obj) => obj.name === e.target.name).map((opt) => {
        let arr = []
        main.map((opt) => {
            if (opt.name == e.target.name) {
                arr.push({ ...opt, value: e.target.checked })
            } else {
                arr.push({ ...opt })
            }
        })
        setMain(arr)
    }, [main])

    //image uploader
    const doneUpload = useCallback((id, img) => {
        dispatchFn(id, img)
        let arr = []
        main.map((opt) => {
            if (opt.id === id) {
                arr.push({ ...opt, value: img })
            } else {
                arr.push({ ...opt })
            }
        })
        setMain(arr)
    }, [main])

    //Text/input change
    const handleInputChange = useCallback((e) => {
        const delayDebounceFn = setTimeout(() => {
            dispatchFn(e.target.name, e.target.value)
            let arr = []
            main.map((opt) => {
                if (opt.name == e.target.name) {
                    arr.push({ ...opt, value: e.target.value })
                } else {
                    arr.push({ ...opt })
                }
            })
            setMain(arr)
        }, 500);

        return () => clearTimeout(delayDebounceFn);
    }, [main])

    //Number/input change
    const handleInputNumberChange = useCallback((e) => {
        const delayDebounceFn = setTimeout(() => {
            let value = e.target.value
            var regex_num = value.replace(/^0+/, "");
            dispatchFn(e.target.name, regex_num)
            let arr = []
            main.map((opt) => {
                if (opt.name == e.target.name) {
                    arr.push({ ...opt, value: regex_num })
                } else {
                    arr.push({ ...opt })
                }
            })
            setMain(arr)
        }, 500);

        return () => clearTimeout(delayDebounceFn);
    }, [main])

    //Select change
    const handleSelectChange = useCallback((e, name) => {
        dispatchFn(name, e.target.value)
        let arr = []
        main.map((opt) => {
            if (opt.name == name) {
                arr.push({ ...opt, value: e.target.value })
            } else {
                arr.push({ ...opt })
            }
        })
        setMain(arr)
    }, [main])

    const handleAltChange = useCallback((e, name) => {
        dispatchFn(name, e.target.value)
        let arr = []
        main.map((opt) => {
            if (opt.alt_name === name) {
                arr.push({ ...opt, alt: e.target.value })
            } else {
                arr.push({ ...opt })
            }
        })
        setMain(arr)
    }, [main])

    const addNewItem = useCallback(() => {
        if (!_.isEmpty(dataVal) && dataVal?.active_page) {
            let schemaType = ""
            let schemaVal = {}
            let newSchemaData = { ...dataVal }
            let arr = []
            if (params.get('type') == "header" || params.get('type') == "footer" || params.get('context') == "theme") {
                schemaType = "common"
                schemaVal = newSchemaData["common"][params.get('type')]

            } else {
                schemaType = "components"
                // schemaVal = newSchemaData[newSchemaData?.active_page]?.components
                let currentId = _.where(newSchemaData[newSchemaData?.active_page]?.active, { id: params.get('id') })
                console.log("currentId 2----", currentId)
                if (currentId && currentId.length > 0) {
                    schemaVal = currentId[0]?.data
                }
            }


            let schemaDataItems = [...schemaVal?.items]
            let newObj = {}
            schemaDataItems.map((opt) => {
                newObj = { ...opt }
            })
            console.log("newObj1111111", newObj)
            _.each(newObj, (val, key) => {
                if (key === "id") {
                    let maxID = _.max(schemaDataItems, function (stooge) { return stooge.id });
                    if (maxID) {
                        newObj[key] = maxID?.id + 1
                    }
                } else if (key === "position") {
                    let maxPosition = _.max(schemaDataItems, function (stooge) { return stooge.position });
                    if (maxPosition) {
                        newObj[key] = maxPosition + 1
                    }
                } else if (key === "image_src") {
                    newObj[key] = val || ""
                } else {
                    newObj[key] = ""
                }
            })
            schemaDataItems.push(newObj)

            let currentId = schemaVal
            newObj = { ...currentId, 'items': schemaDataItems }

            console.log("newObj1231313132", newObj)

            if (schemaType == "common") {
                newSchemaData = {
                    ...newSchemaData,
                    "common": {
                        ...newSchemaData["common"],
                        [params.get('type')]: newObj
                    }
                }
            } else {
                newSchemaData[newSchemaData?.active_page].active.map((opt) => {
                    if (opt.id == params.get('id')) {
                        arr.push({ ...opt, data: newObj })
                    } else {
                        arr.push({ ...opt })
                    }
                })
                newSchemaData = {
                    ...newSchemaData,
                    [newSchemaData?.active_page]: {
                        ...newSchemaData[newSchemaData?.active_page],
                        "active": arr
                    }
                }

            }
            console.log("newSchemaData---------", newSchemaData)
            updateArray(newSchemaData, "update")
            // setSchemaData(newSchemaData)
            // dispatch(dataValue(newSchemaData))
        }
    }, [main, dataVal])

    const editItem = useCallback((data, type) => {
        let pageType = "item"
        if (type == "open") {
            if (params.get(pageType) && params.get(pageType) !== data.id) {
                params.set(pageType, data.id)
                updateMain(true)
            } else if (params.get(pageType) && params.get(pageType) == data.id) {
                params.delete(pageType)
                updateMain(false)
            } else {
                params.append(pageType, data.id)
                updateMain(true)
            }
        } else {
            params.delete(pageType)
            updateMain(true)
        }
        const to = { pathname: location.pathname, search: params.toString() };
        navigate(to, { replace: true });

    }, [activeDetail, main, dataVal])

    const updateMain = useCallback((type) => {
        if (!_.isEmpty(dataVal) && dataVal?.active_page) {
            let schemaVal = {}
            let newSchemaData = { ...dataVal }
            if (dataVal?.active_page == "product_detail" || dataVal?.active_page == "category_list_page" || dataVal?.active_page == "category_page") {
                schemaVal = newSchemaData[dataVal?.active_page]
                console.log("schemaVal[opt.name]----------: ")
                let arr = []
                main.length > 0 && main.map((opt) => {
                    console.log("schemaVal[opt.name]: ", schemaVal, "obj[value]: ", opt)
                    let obj = { ...opt }
                    if (opt.type !== "heading") {
                        obj["value"] = ""
                        obj["value"] = schemaVal[opt.name]
                    }
                    if (opt.type == "image_file") {
                        obj["alt"] = ""
                        obj["alt"] = schemaVal[opt.alt_name]
                        console.log("obj[alt]: ", obj["alt"])
                    }
                    console.log("obj--------: ", obj)
                    arr.push(obj)
                    return obj
                })
                // setMain(arr)
            } else {
                if (params.get('type')) {
                    if (params.get('type') == "header" || params.get('type') == "footer" || params.get('context') == "theme") {
                        schemaVal = newSchemaData["common"] && newSchemaData["common"][params.get('type')]
                    } else {
                        // schemaVal = newSchemaData[newSchemaData?.active_page]?.components
                        let currentId = _.where(newSchemaData[newSchemaData?.active_page]?.active, { id: params.get('id') })
                        console.log("currentId 2----", currentId)
                        if (currentId && currentId.length > 0) {
                            schemaVal = currentId[0]?.data
                        }
                    }
                    console.log("schemaVal?.items: ", schemaVal?.items)
                    if (type) {
                        let schemaDataItems = [...schemaVal?.items]
                        console.log("schemaDataItems: ", schemaDataItems)
                        let currentIndex = ""
                        let filterData = schemaDataItems.filter((obj) => obj.id && obj.id == params.get('item'))
                        let newArr = []
                        let main = []

                        let arr = schemaVal?.items
                        let arr2 = []
                        itemData[params.get('type')].map((opt) => {
                            let menuList = []
                            if (opt?.name) {
                                if (opt.name == "nav_menu" && dataVal.menuList && dataVal.menuList.length > 0) {
                                    dataVal.menuList.map((item) => {
                                        menuList.push(item)
                                    })
                                    arr2.push({ ...opt, value: arr[opt.name], items: menuList })
                                } else {
                                    arr2.push({ ...opt, value: arr[opt.name] })
                                }
                            } else {
                                arr2.push({ ...opt })
                            }
                        })


                        console.log("filterData arr2: ", arr2)
                        console.log("filterData: ", filterData)
                        if (filterData.length > 0) {
                            filterData.map((obj) => {
                                currentIndex = _.findLastIndex(schemaDataItems, { 'id': obj.id })
                                arr2.map((val) => {
                                    console.log("val: ", val)
                                    if (val.type === "editor") {
                                        const contentBlock = htmlToDraft(` ${obj[val.name]} `);
                                        if (contentBlock) {
                                            const contentState = ContentState.createFromBlockArray(
                                                contentBlock.contentBlocks
                                            );
                                            const editorState = EditorState.createWithContent(contentState);
                                            setEditorState(editorState)
                                        }
                                    }

                                    newArr.push({ ...val, 'value': obj[val.name] })
                                })
                                // opt.value = schemaDataItems[currentIndex][opt.name]
                            })
                        } else {
                            // navigate(`?context=page&type=${params.get('type')}`)
                            // window.location.reload(false)
                        }
                        console.log("newArr: ", newArr)

                        setactiveDetail(newArr)
                        // setMain([...newArr])

                    } else {
                        main.length > 0 && main.map((opt) => {
                            console.log("schemaVal[opt.name]: ", schemaVal, "obj[value]: ", opt)
                            let obj = { ...opt }
                            if (opt.type !== "heading") {
                                obj["value"] = ""
                                obj["value"] = schemaVal[opt.name]
                            }
                            if (opt.type == "image_file") {
                                obj["alt"] = ""
                                obj["alt"] = schemaVal[opt.alt_name]
                                console.log("obj[alt]: ", obj["alt"])
                            }
                            if (opt.type === "editor") {
                                obj["value"] = schemaVal[opt.name]
                                // let editorData = EditorState.createWithContent(
                                //     ContentState.createFromBlockArray(
                                //         convertFromHTML(opt.value)
                                //     )
                                // )
                                // setEditorState(editorData)
                                if (obj["value"]) {
                                    const contentBlock = htmlToDraft(` ${obj["value"]} `);
                                    if (contentBlock) {
                                        const contentState = ContentState.createFromBlockArray(
                                            contentBlock.contentBlocks
                                        );
                                        const editorState = EditorState.createWithContent(contentState);
                                        setEditorState(editorState)
                                    }
                                }
                            }
                            console.log("obj--------: ", obj)
                            return obj
                        })
                    }
                }
            }
            // console.log(opt, "=====", opt.name, "----", opt.value, "))))))))", schemaData[schemaType][params.get('type')][opt.name])
            console.log("schemaVal[params.get('type')][opt.name]: ", main)
        }
    }, [main, activeDetail, dataVal])

    const updateArray = useCallback((data1, type) => {
        if (!_.isEmpty(data1) && data1?.active_page) {
            let schemaType = ""
            let schemaVal = {}
            if (params.get('type') == "header" || params.get('type') == "footer" || params.get('context') == "theme") {
                schemaType = "common"
                schemaVal = data1["common"] && data1["common"][params.get('type')]
            } else {
                schemaType = "components"
                // schemaVal = data1[data1?.active_page]?.components
                let currentId = _.where(data1[data1?.active_page]?.active, { id: params.get('id') })
                console.log("currentId 2----", currentId)
                if (currentId && currentId.length > 0) {
                    schemaVal = currentId[0]?.data
                }
            }
            if (schemaVal && schemaVal.items && schemaVal.items.length > 0) {
                console.log("schemaVal.items-----------------------", schemaVal.items)
                setArray(schemaVal.items)
                if (schemaVal.limit) {
                    if (schemaVal.items.length < schemaVal.limit) {
                        setdisableBtn(false)
                    } else {
                        setdisableBtn(true)
                    }
                } else {
                    setdisableBtn(false)
                }
            }
            if (type == "update") {
                console.log("data1: ", data1)
                // setSchemaData(data1)
                dispatch(dataValue(data1))
                setTimeout(() => {
                    dispatch(dataValue({ "update": data1 }))
                }, 500);
            } else {
                console.log("data1 2: ", data1)
            }
        }
    }, [activeDetail, dataVal])

    useEffect(() => {
        console.log("dataValL ", dataVal)
        setSchemaData(dataVal)
    }, [dataVal])

    const moveOption = useCallback((dragIndex, hoverIndex) => {
        console.log(dragIndex, hoverIndex)
        if (!_.isEmpty(dataVal) && dataVal?.active_page) {
            let schemaType = ""
            let schemaVal = {}
            let newSchemaData = { ...dataVal }
            if (params.get('type') == "header" || params.get('type') == "footer" || params.get('context') == "theme") {
                schemaType = "common"
                schemaVal = dataVal["common"] && dataVal["common"][params.get('type')]
            } else {
                schemaType = "components"
                // schemaVal = dataVal[dataVal?.active_page]?.components
                let currentId = _.where(dataVal[dataVal?.active_page]?.active, { id: params.get('id') })
                console.log("currentId 2----", currentId)
                if (currentId && currentId.length > 0) {
                    schemaVal = currentId[0]?.data
                }
            }
            if (schemaVal && schemaVal.items && schemaVal.items.length > 0) {
                let optArr = [...schemaVal.items]
                setArray((prevCards) =>
                    optArr = update(prevCards, {
                        $splice: [
                            [dragIndex, 1],
                            [hoverIndex, 0, prevCards[dragIndex]]
                        ]
                    })
                )
                console.log("optArr: ", optArr)
                let optArr2 = []
                optArr.map((opt, i) => {
                    optArr2.push({ ...opt, "position": i + 1 })
                })
                console.log("optArr2: ", optArr2)
                setArray(optArr2)
                let schemaDataItems = [...optArr2]
                if (schemaDataItems.length > 1) {
                    let currentId = schemaVal
                    let newObj = { ...currentId, 'items': schemaDataItems }
                    console.log("schemaVal: ", schemaVal)
                    if (schemaType == "common") {
                        newSchemaData = {
                            ...newSchemaData,
                            "common": {
                                ...newSchemaData["common"],
                                [params.get('type')]: newObj
                            }
                        }
                    } else {
                        let arr = []
                        newSchemaData[newSchemaData?.active_page].active.map((opt) => {
                            if (opt.id == params.get('id')) {
                                arr.push({ ...opt, data: newObj })
                            } else {
                                arr.push({ ...opt })
                            }
                        })
                        newSchemaData = {
                            ...newSchemaData,
                            [newSchemaData?.active_page]: {
                                ...newSchemaData[newSchemaData?.active_page],
                                "active": arr
                            }
                        }
                    }
                    updateArray(newSchemaData, "update")
                    // setSchemaData(newSchemaData)
                    // dispatch(dataValue(newSchemaData))
                }
            }
        }

        // setList((prevCards) =>
        //     update(prevCards, {
        //         $splice: [
        //             [dragIndex, 1],
        //             [hoverIndex, 0, prevCards[dragIndex]]
        //         ]
        //     })
        // )
    }, [dataVal])

    const [isDelete, setisDelete] = useState("")
    const confirmDelete = useCallback((data) => {
        let schemaType = ""
        let schemaVal = {}
        let newSchemaData = { ...dataVal }
        if (params.get('type') == "header" || params.get('type') == "footer" || params.get('context') == "theme") {
            schemaType = "common"
            schemaVal = dataVal["common"][params.get('type')]
        } else {
            schemaType = "components"
            // schemaVal = schemaData[schemaData?.active_page]?.components
            let currentId = _.where(dataVal[dataVal?.active_page]?.active, { id: params.get('id') })
            console.log("currentId 2----", currentId)
            if (currentId && currentId.length > 0) {
                schemaVal = currentId[0]?.data
            }
        }
        let schemaDataItems = [...schemaVal?.items]
        let currentIndex = _.findLastIndex(schemaDataItems, { 'id': data.id })
        if (currentIndex !== -1 && schemaDataItems.length > 1) {
            schemaDataItems.splice(currentIndex, 1)
            let currentId = schemaVal
            let newObj = { ...currentId, 'items': schemaDataItems }
            console.log("schemaVal: ", schemaVal)
            if (schemaType == "common") {
                newSchemaData = {
                    ...newSchemaData,
                    "common": {
                        ...newSchemaData["common"],
                        [params.get('type')]: newObj
                    }
                }
            } else {
                let arr = []
                newSchemaData[newSchemaData?.active_page].active.map((opt) => {
                    if (opt.id == params.get('id')) {
                        arr.push({ ...opt, data: newObj })
                    } else {
                        arr.push({ ...opt })
                    }
                })
                newSchemaData = {
                    ...newSchemaData,
                    [newSchemaData?.active_page]: {
                        ...newSchemaData[newSchemaData?.active_page],
                        "active": arr
                    }
                }
            }
            updateArray(newSchemaData, "update")
            setisDelete("")
            // setSchemaData(newSchemaData)
            dispatch(dataValue({ "update": newSchemaData }))
        }
    }, [dataVal])

    const deleteItem = (data) => {
        setisDelete(data?.id)
        let schemaType = ""
        let schemaVal = {}
        let newSchemaData = { ...dataVal }
        if (params.get('type') == "header" || params.get('type') == "footer" || params.get('context') == "theme") {
            schemaType = "common"
            schemaVal = dataVal["common"][params.get('type')]
        } else {
            schemaType = "components"
            // schemaVal = schemaData[schemaData?.active_page]?.components
            let currentId = _.where(dataVal[dataVal?.active_page]?.active, { id: params.get('id') })
            console.log("currentId 2----", currentId)
            if (currentId && currentId.length > 0) {
                schemaVal = currentId[0]?.data
            }
        }
        let schemaDataItems = [...schemaVal?.items]
        console.log("schemaDataItems delete: ", schemaDataItems)
    }

    const cancelDelete = () => {
        setisDelete("")
    }


    // console.log("main: ", main)
    const mainUpdateFn = (val) => {
        if (dataVal?.active_page && (dataVal?.active_page == "product_detail" || dataVal?.active_page == "category_list_page" || dataVal?.active_page == "category_page")) {
            let arr2 = []
            val.map((opt) => {
                arr2.push({ ...opt, value: dataVal?.[dataVal?.active_page][opt?.name] })
            })
            setMain(arr2)
            dispatch(dataValue({ "main": arr2 }))
        } else {
            if (params.get('type') == "header" || params.get('type') == "footer" || params.get('context') == "theme") {
                let arr = dataVal?.common && dataVal?.common[params.get('type')]
                console.log("arr111111111111:", arr)

                let arr2 = []
                if (arr) {
                    val.map((opt) => {
                        let menuList = []
                        if (opt?.name) {
                            if (opt.name == "nav_menu" && dataVal.menuList && dataVal.menuList.length > 0) {
                                dataVal.menuList.map((item) => {
                                    menuList.push(item)
                                })
                                arr2.push({ ...opt, value: arr[opt.name], items: menuList })
                            } else if (opt?.type == "radio" && opt.options && opt.options.length > 0) {
                                let optArr = []
                                opt.options.map((x) => {
                                    if (x.value == arr[opt.name]) {
                                        optArr.push({ ...x, checked: true })
                                    } else {
                                        optArr.push({ ...x, checked: false })
                                    }
                                })
                                arr2.push({ ...opt, value: arr[opt.name], options: optArr })
                            } else {
                                if (opt?.type == "image_file") {
                                    arr2.push({ ...opt, value: arr[opt.name], alt: arr[opt.alt_name] })
                                } else {
                                    arr2.push({ ...opt, value: arr[opt.name] })
                                }
                            }
                        } else {
                            arr2.push({ ...opt })
                        }
                    })
                    setMain(arr2)
                    dispatch(dataValue({ "main": arr2 }))
                } else {
                    params.delete('type')
                    const to = { pathname: location.pathname, search: params.toString() };
                    navigate(to, { replace: true });
                    setMain([])
                    dispatch(dataValue({ "main": [] }))
                }
            }
            else if (params.get('type') === "hero_slider" || params.get('type') === "text_over_image" || params.get('type') === "product_list" || params.get('type') === "text_column_with_image" || params.get('type') === "gallery" || params.get('type') === "content_block" || params.get('type') === "brands_list" || params.get('type') === "map" || params.get('type') === "heading_block" || params.get('type') === "testimonials" || params.get('type') === "blog_post" || params.get('type') === "newsletter" || params.get('type') === "video" || params.get('type') === "list_style" || params.get('type') === "custom_html" || params.get('type') === "category_list") {
                // let arr = dataVal[dataVal?.active_page]?.components[params.get('type')]

                let currentId = _.where(dataVal[dataVal?.active_page]?.active, { id: params.get('id') })
                console.log("currentId----", currentId)
                if (currentId && currentId.length > 0 && val) {
                    console.log("currentId val----", val)
                    let arr2 = []
                    val.map((opt) => {
                        if (opt?.name) {
                            if (opt.name == "product_category" && dataVal.categories && dataVal.categories.length > 0) {
                                let category = [{ name: "None", value: "" }]
                                dataVal.categories.map((x) => {
                                    category.push({ ...x })
                                })
                                arr2.push({ ...opt, value: currentId[0]?.data[opt.name], items: category })
                            } else if (opt?.type == "radio" && opt.options && opt.options.length > 0) {
                                let optArr = []
                                opt.options.map((x) => {
                                    if (x.value == currentId[0]?.data[opt.name]) {
                                        optArr.push({ ...x, checked: true })
                                    } else {
                                        optArr.push({ ...x, checked: false })
                                    }
                                })
                                arr2.push({ ...opt, value: currentId[0]?.data[opt.name], options: optArr })
                            } else {
                                if (opt?.type == "image_file") {
                                    arr2.push({ ...opt, value: currentId[0]?.data[opt.name], alt: currentId[0]?.data[opt.alt_name] })
                                } else {
                                    arr2.push({ ...opt, value: currentId[0]?.data[opt.name] })
                                }
                            }
                        } else {
                            arr2.push({ ...opt })
                        }
                    })
                    setMain(arr2)
                    dispatch(dataValue({ "main": arr2 }))
                } else {
                    params.delete('context')
                    params.delete('id')
                    params.delete('type')
                    const to = { pathname: location.pathname, search: params.toString() };
                    navigate(to, { replace: true });
                    setMain([])
                    dispatch(dataValue({ "main": [] }))
                }
            }
            else {
                params.delete('context')
                params.delete('id')
                params.delete('type')
                const to = { pathname: location.pathname, search: params.toString() };
                navigate(to, { replace: true });
                setMain([])
                dispatch(dataValue({ "main": [] }))
                // let currentObj = {}
                // settingSchema.filter((opt) => opt.type === params.get('type')).map((val) => {
                //     currentObj = val.settings
                // })
                // setMain(currentObj)
                // dispatch(dataValue({ "main": currentObj }))
            }
        }
    }

    const getCategory = (param) => {
        Api.GetApi(`${config.CATEGORY_LIST}${param}`).then(res => {
            setshowLoader(false)
            if (res.error === true) {
                snack(res.response.data.error, 'error');
            } else {
                let arr = []
                let categoryData = []
                if (dataVal.categories.length > 0) {
                    res.data.data.map((x) => {
                        arr.push({ id: x.category_id, value: x.handle, name: x.title })
                    })
                    categoryData = [...dataVal.categories, ...arr]
                } else {
                    categoryData = res.data.data
                }

                if (res.data.data.length < 20) {
                    sethasNoMoreData(true)
                } else {
                    sethasNoMoreData(false)
                }
                dispatch(dataValue({ "categories": categoryData }))
            }
        })
    }
    useEffect(() => {
        mainFn()
    }, [dataVal?.categories])


    const callFn = useCallback(() => {
        let pageData = page + 1
        getCategory(`?page=${page + 1}`)
        setPage(pageData)
    }, [page, dataVal?.categories])

    const fetchMoreData = (newData) => {
        console.log("yessssssssssssssssssssssssssss", newData)
        setshowLoader(true)
        setTimeout(() => {
            callFn()
        }, 100);
    }

    const mainFn = () => {
        if (dataVal) {
            if (dataVal?.active_page == "product_detail" || dataVal?.active_page == "category_list_page" || dataVal?.active_page == "category_page") {
                mainUpdateFn(main_data[dataVal?.active_page])
            } else {
                mainUpdateFn(main_data[params.get('type')])
            }
        }
    }
    useEffect(() => {
        mainFn()
    }, [dataVal?.active_page, params.get('type')])

    useEffect(() => {
        if (dataVal && dataVal.main && dataVal.main.length > 0) {
            mainUpdateFn(dataVal.main)
        }
    }, [dataVal?.update])

    useEffect(() => {
        if (params.get('item')) {
            updateMain(true)
        } else {
            updateMain(false)
        }
        // if (params.get('item')) {
        // updateArray(schemaData, "")
        // }

        // console.log("schemaData: ", schemaData)
        // if (params.get('context') === "page" && !_.isEmpty(schemaData)) {
        //     if (params.get('type') !== "header" || params.get('type') !== "footer") {
        //         if (schemaData.active.filter((opt) => opt === params.get('type')).length == 0) {
        //             navigate('/admin/customizer')
        //         }
        //     }
        // }

        // dispatch(dataValue(schemaData))

    }, [main])
    useEffect(() => {
        updateArray(dataVal, "")
    }, [])
    console.log("main-----: ", main)
    console.log("data.type: ", data)

    return (
        <>
            {data.type === "heading" &&
                <Heading
                    data={data}
                />
            }
            {data.type === "heading_flex" &&
                <HeadingFlex
                    data={data}
                />
            }
            {data.type === "font_family" &&
                <FontFamily
                    data={data}
                    handleFontChange={handleSelectChange}
                />
            }
            {data.type === "range" &&
                <RangeSlider
                    data={data}
                    rangeValue={rangeValue}
                />
            }
            {data.type === "color_picker" &&
                <Colors
                    data={data}
                    handleColorChange={handleColorChange}
                />
            }
            {data.type === "image_file" &&
                <ImagePicker
                    data={data}
                    doneUpload={doneUpload}
                    handleAltChange={handleAltChange}
                />
            }
            {data.type === "checkbox" &&
                <CheckBox
                    data={data}
                    main={main}
                    handleCheckboxChange={handleCheckboxChange}
                />
            }
            {data.type === "radio" &&
                <RadioButton
                    data={data}
                    handleRadioChange={handleRadioChange}
                />
            }
            {data.type === "text" ?
                <TextFields
                    data={data}
                    main={main}
                    handleInputChange={handleInputChange}
                /> : null
            }
            {data.type === "number" ?
                <TextFieldNumber
                    data={data}
                    main={main}
                    handleInputChange={handleInputNumberChange}
                /> : null
            }
            {data.type === "textarea" ?
                <TextArea
                    data={data}
                    main={main}
                    handleInputChange={handleInputChange}
                /> : null
            }
            {data.type === "select" &&
                <>{data.name == "product_category" ?
                    <SelectBox
                        data={data}
                        handleSelectChange={handleSelectChange}
                        fetchMoreData={fetchMoreData}
                        hasNoMoreData={hasNoMoreData}
                        showLoader={showLoader}
                    />
                    :
                    <SelectBox
                        data={data}
                        handleSelectChange={handleSelectChange}
                    />
                }
                </>
            }
            {/* {data.type === "nav_list" &&
                <NavList
                    data={data}
                />
            } */}
            {data.type === "divider" &&
                <Divider sx={{ my: 2 }} />
            }
            {data.type === "list_item" ?

                <DndProvider backend={HTML5Backend}>
                    <ListItems
                        data={data}
                        addNewItem={addNewItem}
                        editItem={editItem}
                        deleteItem={deleteItem}
                        isDelete={isDelete}
                        array={array}
                        activeDetail={activeDetail}
                        disableBtn={disableBtn}
                        handleInputChange={handleInputChange}
                        moveOption={moveOption}
                        confirmDelete={confirmDelete}
                        cancelDelete={cancelDelete}
                    />
                </DndProvider>
                : null
            }
            {data.type === "editor" &&
                <TextEditor
                    data={data}
                    editorState={editorState}
                    updateTextDescription={updateTextDescription}
                    onContentStateChange={onContentStateChange}
                    onEditorBlur={onEditorBlur}
                />
            }
            {data.type === "html_editor" &&
                <HTMLEditor
                    data={data}
                    handleInputChange={handleInputChange}
                />
            }
            {data.type === "header" ||
                data.type === "footer" ||
                data.type === "typography" ||
                data.type === "colors" ||
                data.type === "favicon" ||
                data.type === "share_button" ||
                data.type === "social_button" ||
                data.type === "list_style" ||
                data.type === "hero_slider" ||
                data.type === "text_over_image" ||
                data.type === "product_list" ||
                data.type === "category_list" ||
                data.type === "text_column_with_image" ||
                data.type === "gallery" ||
                data.type === "content_block" ||
                data.type === "brands_list" ||
                data.type === "map" ||
                data.type === "heading_text" ||
                data.type === "heading_block" ||
                data.type === "testimonials" ||
                data.type === "newsletter" ||
                data.type === "video" ||
                data.type === "custom_html" ||
                data.type === "blog_post" ||
                data.type === "product_detail" ||
                data.type === "category_list_page" ||
                data.type === "category_page" ?
                renderImport(main)
                : null
            }
        </>
    )
}