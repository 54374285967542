import React from "react";
import { Box, TextField, Typography } from "@mui/material";
import HTMLMiniEditor from "../../../../common/CustomEditor/HTMLMiniEditor";

export default function HTMLEditor({ data, handleInputChange }) {
    console.log("data HTMLEditor: ", data)
    return (
        <Box sx={{ pb: 3 }}>
            <Typography variant="caption" component="div" sx={{ p: "0 0 0 10px", mb: 1, display: "block" }}>
                {data.label}
            </Typography>
            {/* <HTMLMiniEditor
                value={data?.value}
                handleHtmlChange={(val)=>handleHtmlChange(data?.name, val)}
                onHtmlEditorBlur={(val)=>onHtmlEditorBlur(data?.name, val)}
            /> */}
            <TextField
                defaultValue={data?.value}
                variant="outlined"
                fullWidth
                type={data?.type}
                name={data?.name}
                placeholder={data?.placeholder}
                sx={{ mb: 2 }}
                onChange={handleInputChange}
                multiline
                rows={6}
                maxRows={10}
            />
        </Box>
    )
}