import React, { useEffect, useState } from 'react'
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { alpha } from '@mui/material/styles';
import {
    Grid, Divider, Typography, Paper, ButtonGroup,
    Button, InputBase, Menu, MenuItem, Box, Chip,
    Card, IconButton, Container, Checkbox, Popover, LinearProgress, Pagination, FormControlLabel, Radio, Skeleton,
} from '@mui/material'
import { styled } from '@mui/material/styles';

import SearchIcon from '@mui/icons-material/Search';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ClearIcon from '@mui/icons-material/Clear';
import order from "../../../../Assets/images/no-result/order.svg";

import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import CircleIcon from '@mui/icons-material/Circle';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CurrencyFormat from 'react-currency-format';
import SortIcon from '@mui/icons-material/Sort';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';

import { DataGrid } from '@mui/x-data-grid';
import { useMediaQuery, useTheme } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import * as Api from "../../../../Container/config/Apis"
import config from '../../../../Container/config';
import _ from "underscore";
import moment from "moment";
import { useSnackbar } from 'notistack';

import { useRef } from 'react';
import NoResult from '../../../common/NoResult';

// secrch
const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.0),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.0),
    },
    marginRight: "0",
    marginLeft: "0",
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        border: "1px solid #d9d9d9",
        borderRadius: "5px",
        // background: "#f7f8fa",
        [theme.breakpoints.up('md')]: {
            width: '100%',
        },
    },
}));

// secrch
const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));

// dropdown  

const handleToggleAllCompleted = (e) => {
    console.log("e", e);
};

const handleToggleCompleted = (e) => {
    console.log("e", e);
};


const theme = createTheme({
    palette: {
        neutral: {
            main: '#64748B',
            contrastText: '#fff',
        },
    },
});


const StyledGridOverlay = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
}));
function CustomNoRowsOverlay() {
    return (
        <Box sx={{ mt: 5, mb: 2, textAlign: "center" }}>
            <NoResult
                icon={order}
                title={"View  all the abandoned carts here"}
                subtitle={"You can keep track of the abandoned carts in this section. Remind your users to complete the checkout process by sending them a link to their cart."}
                btn={false}
            />
            {/* <img src={failed_vector} alt="" width="100" />
                <Typography variant="h5" component="div" sx={{ mt: 1 }}>No result found!</Typography> */}
        </Box>
    );
}

export default function AbandonedCheckout() {
    // dropdown btn
    const searchParams = new URLSearchParams(window.location.search)
    let navigate = useNavigate()
    const location = useLocation()
    const [anchorEl, setAnchorEl] = useState({
        action: null,
        customer: null,
        sortby: null,
        date: null,
        status: null,
        email_status: null,
    });
    const actionRef = React.useRef();

    const [loadingBar, setloadingBar] = React.useState(true);
    const [loadingData, setloadingData] = React.useState(false);
    const [activeTab, setactiveTab] = useState("all")
    const [selectionModel, setSelectionModel] = useState([]);
    const [limit, setLimit] = useState(process.env.REACT_APP_LIMIT || 20)
    const [params, setParams] = useState({
        search: "",
        status: "",
        type: "",
        sort_key: "",
        sort_value: "desc",
        page: 1
    })
    const { enqueueSnackbar } = useSnackbar();
    const snack = (msg, variant) => {
        enqueueSnackbar(msg, { variant });
    }
    
    const tabs = [
        { label: "All", name: "all" },
        { label: "Recovered", name: "recovered" },
        { label: "Not recovered", name: "not-recovered" },
    ]

    const [statusArray, setStatusArray] = useState([
        { name: "All", key: "email_status", value: "all", active: searchParams.get('email_status') == "all" ? true : false },
        { name: "Scheduled", key: "email_status", value: "scheduled", active: searchParams.get('email_status') == "scheduled" ? true : false },
        { name: "Sent", key: "email_status", value: "sent", active: searchParams.get('email_status') == "sent" ? true : false },
        { name: "Not sent", key: "email_status", value: "not-sent", active: searchParams.get('email_status') == "not-sent" ? true : false },
    ])
    const [sortby, setSortby] = useState([
        { name: "Oldest first", key: "createdAt", value: "asc", active: false },
        { name: "Newest first", key: "createdAt", value: "desc", active: false },
    ])
    const [sortDate, setSortDate] = useState([
        { name: "Today", key: "today", value: "asc", active: searchParams.get('type') == "today" ? true : false },
        { name: "Last 7 days", key: "last_7_days", value: "asc", active: searchParams.get('type') == "last_7_days" ? true : false },
        { name: "Last 30 days", key: "last_30_days", value: "asc", active: searchParams.get('type') == "last_30_days" ? true : false },
        { name: "Last 90 days", key: "last_90_days", value: "asc", active: searchParams.get('type') == "last_90_days" ? true : false },
        { name: "Last 12 months", key: "last_12_month", value: "asc", active: searchParams.get('type') == "last_12_month" ? true : false },
    ])

    const [activeId, setactiveId] = useState("")
    const ref = useRef(null)

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const density = isSmallScreen ? 'standard' : 'standard';
    const openStatus = Boolean(anchorEl.email_status);
    const openSort = Boolean(anchorEl.sortby);
    const openDate = Boolean(anchorEl.date);
    const [checkoutOrders, setcheckoutOrders] = useState([])

    const [count, setCount] = useState(1)
    const [totalOrders, setTotalOrders] = useState(0)
    const [filters, setfilters] = useState(false)
    const handleClick = (event, data) => {
        setAnchorEl({ ...anchorEl, [data]: event.currentTarget });
    };
    const handleClose = () => {
        setAnchorEl({
            action: null,
            customer: null,
            sortby: null,
            date: null,
            status: null,
            email_status: null,
        });
        // setAnchorEl({...anchorEl, data : null});
    };
    const clickTab = (name) => {
        setloadingData(true)
        setactiveTab(name);
        if (name !== "all") {
            setParams({ ...params, 'page': 1, 'status': name })
            setparam("page", 1)
            setparam("status", name)
        } else {
            setParams({ ...params, 'page': 1, 'status': "" })
            deleteparam("status")
        }
    };

    //Set/Delete param
    const setparam = (key, val) => {
        searchParams.set(key, val)
        const to = { pathname: location.pathname, search: searchParams.toString() };
        navigate(to, { replace: true });
    }
    const deleteparam = (key) => {
        searchParams.delete(key)
        const to = { pathname: location.pathname, search: searchParams.toString() };
        navigate(to, { replace: true });
    }
    // popover
    const openPopover = (event, id) => {
        setAnchorEl({ ...anchorEl, 'customer': event.currentTarget });
        setactiveId(id)
        // setAnchorEl(event.currentTarget);
    };

    const popoverClose = (event, id) => {
        setAnchorEl({ ...anchorEl, ['customer']: null });
        setactiveId(null)
        // setAnchorEl(null);
    };



    //Sort by on change
    const handleSortChange = (val, type) => {
        setloadingBar(true)
        let newArr = []
        if (type == "sort") {
            sortby.map((opt) => {
                if (opt.name === val.name) {
                    newArr.push({ ...opt, 'active': true })
                    setParams({ ...params, "sort_key": val.key, "sort_value": val.value, 'page': 1 })
                    setparam("page", 1)
                    setparam("sort_key", val.key)
                    setparam("sort_value", val.value)
                } else {
                    newArr.push({ ...opt, 'active': false })
                }
            })
            setSortby(newArr)
        } else if (type == "status") {
            statusArray.map((opt) => {
                if (opt.name === val.name) {
                    newArr.push({ ...opt, 'active': true })
                    setParams({ ...params, [val.key]: val.value, 'page': 1 })
                    setparam("page", 1)
                    setparam([val.key], val.value)
                } else {
                    newArr.push({ ...opt, 'active': false })
                }
            })
            setStatusArray(newArr)
        } else {
            sortDate.map((opt) => {
                if (opt.name === val.name) {
                    newArr.push({ ...opt, 'active': true })
                    setParams({ ...params, "type": val.key, "sort_value": val.value, 'page': 1 })
                    setparam("page", 1)
                    setparam("type", val.key)
                    setparam("sort_value", val.value)
                } else {
                    newArr.push({ ...opt, 'active': false })
                }
            })
            setSortDate(newArr)
        }
        handleClose()
    }
console.log("statusarray", statusArray)
    const columns = [
        // {
        //     field: 'Customer', headerName: 'Customer', sortable: false, flex: 1, minWidth: 250,
        //     renderCell: (params) =>
        //         <>
        //             {params?.value?.first_name ?
        //                 <Button aria-describedby={params.id} variant="text" color="secondary" onClick={(e) => openPopover(e, params.id)} endIcon={<ArrowDropDownIcon />}>
        //                     <span className='text-truncate'>{`${params?.value?.first_name} ${params?.value?.last_name ? params?.value?.last_name : ""}`}</span>
        //                 </Button>
        //                 :
        //                 <Button  variant="text" color="secondary" className={'noHover px-0'} component={Link} to={`/admin/orders/checkouts/${params?.row?.cart_id || ""}`}>
        //                     <span className='text-truncate'>Guest user</span>
        //                 </Button>
        //             }
        //             <Popover
        //                 id={params.id}
        //                 open={activeId == params.id}
        //                 anchorEl={anchorEl.customer}
        //                 onClose={(e) => popoverClose(e, params.id)}
        //                 anchorOrigin={{
        //                     vertical: 'bottom',
        //                     horizontal: 'left',
        //                 }} >
        //                 <Typography component="div" sx={{ p: 2 }}>
        //                     <Typography variant="caption" component="div"> {params?.value?.orders_count} orders</Typography>
        //                     <Typography variant="caption" sx={{ mb: 1 }} component="div">
        //                         <a href={`mailto:${params?.value?.email}`} color="secondary">{params?.value?.email}</a>
        //                     </Typography>
        //                     <NavLink to={`/admin/users/${params?.row?.customer_id}`} className="link "><small>View customer</small></NavLink>
        //                 </Typography>
        //             </Popover>
        //         </>
        // },
        {
            field: 'abundant_checkout_id', headerName: 'Checkout ID',
            renderCell: (params) => <Button component={Link} to={`/admin/orders/checkouts/${params?.value || ""}`} color="secondary" className='noHover d-block' sx={{ p: 0, pl: 1 }}>
                <Typography variant="subtitle2" component="div" sx={{ m: 0, lineHeight: "1.2" }} >
                    {params.value}
                </Typography>
            </Button>, width: 200, height: 40, sortable: false,
        },
        {
            field: 'createdAt', headerName: 'Date', minWidth: 200,
            renderCell: (params) => <Button variant='text' color='secondary' className='noHover px-0' component={Link} to={`/admin/orders/checkouts/${params?.row?.abundant_checkout_id || ""}`}>{moment(params.value).format("lll")}</Button>, sortable: false, flex: 1,
        },
        {
            field: 'status', headerName: 'Status', minWidth: 200,
            renderCell: (params) => <Button variant='text' color='secondary' className='noHover px-0' component={Link} to={`/admin/orders/checkouts/${params?.row?.abundant_checkout_id || ""}`}>{params.value && params.value.charAt(0).toUpperCase() + params.value.slice(1).toLowerCase().split("-").join(" ")}</Button>, sortable: false, flex: 1,
        },
        {
            field: 'email_status', headerName: 'Email status', minWidth: 150,
            renderCell: (params) => <Box>
                <Button variant='text' color='secondary' className='noHover px-0' component={Link} to={`/admin/orders/checkouts/${params?.row?.abundant_checkout_id || ""}`}>
                    <Chip sx={{ fontSize: "11px !important", cursor: "pointer" }}
                        label={params.value && params.value.toLowerCase() == "sent" ? `Email sent` : params.value.charAt(0).toUpperCase() + params.value.slice(1).toLowerCase().split("-").join(" ")}
                        variant={params.value && params.value.toLowerCase() == "not sent" || params.value.toLowerCase() == "scheduled" ? "outlined" : "contained"}
                        color={params.value && params.value.toLowerCase() == "sent" ? 'success' : params.value.toLowerCase() == "scheduled" ? 'primary' : params.value.toLowerCase() == "not sent" ? 'warning' : 'error'}
                    />
                </Button>
            </Box>, sortable: false, flex: 1, justifyContent: "flex-end"
        },
        {
            field: 'total', headerName: 'Total', sortable: false, flex: 1, minWidth: 150,
            renderCell: (params) => <Box>
                <CurrencyFormat value={parseFloat(params?.value).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={config.CURRENCY} />
            </Box>
        },
    ]


    //Get order list
    const getOrdersList = (param) => {
        Api.GetApi(`${config.CHECKOUT_ORDERS_LIST}${param}`).then(res => {
            setTimeout(() => {
                setloadingBar(false)
                setloadingData(false)
            }, 500);
            console.log("res list: ", res)
            if (res.error === true) {
                snack(res.response.data.error, 'error');
            } else {
                if (res.data.data) {
                    let resData = res.data.data
                    let arr = []
                    !_.isEmpty(resData) && resData?.rows.length && resData?.rows.map((opt) => {
                        arr.push({ ...opt, id: opt?.abundant_checkout_id || opt?.cart_id, total: (opt?.abundant_cart && !_.isEmpty(opt?.abundant_cart)) ? opt?.abundant_cart?.total : (opt?.abundant_order && !_.isEmpty(opt?.abundant_order)) ? opt?.abundant_order?.total_price : "0.00" })
                    })
                    setcheckoutOrders(arr)
                    setTotalOrders(resData?.count)
                    let newCount = Math.ceil(parseInt(resData?.count) / limit)
                    if (newCount !== 0) {
                        setCount(newCount)
                    } else {
                        setCount(1)
                    }
                }
            }
        })
    }

    useEffect(() => {
        let newArr = []
        const to = { pathname: location.pathname, search: searchParams.toString() };
        navigate(to, { replace: true });
        getOrdersList(location.search)
        setactiveTab(searchParams.get('status') || "all")
    }, [limit, params])

    console.log("checkoutOrders: ", checkoutOrders)
    const toggleShowFilter = () => {
        setfilters(!filters)
    }

    //Clear sort by filter
    const clearSortBy = () => {
        setloadingBar(true)
        let newArr = []
        let newArr2 = []
        setParams({ ...params, 'page': 1, "sort_key": "", "sort_value": "", type: "" })
        deleteparam("sort_key")
        deleteparam("type")
        deleteparam("sort_value")
        sortby.map((opt) => {
            newArr.push({ ...opt, 'active': false })
        })
        sortDate.map((opt) => {
            newArr2.push({ ...opt, 'active': false })
        })
        setSortby(newArr)
        setSortDate(newArr2)
    }


    const handleChangePage = (_event, newPage) => {
        setloadingBar(true)
        console.log("newPage: ", newPage)
        setParams({ ...params, "page": newPage })
        setparam("page", newPage)
    };

    // dropdown btn
    return (
        <React.Fragment>
            {/* <Box className="smallContainer"> */}

            {loadingBar ?
                <LinearProgress className="top_loader" color="info" sx={{ mt: "-25px", mb: "21px" }} />
                :
                null
            }
            <Container maxWidth="lg" className={`orderPage ${checkoutOrders.length == 0 ? 'no_order_overlay' : ''}`}>
                <Box sx={{ mb: 2 }}>
                    <Grid container spacing={1} columns={12} sx={{ alignItems: "center" }}>
                        <Grid item xs={10}>
                            <Typography variant="h5" component="div"> Abandoned checkouts </Typography>
                        </Grid>
                        <Grid item xs={2} sx={{ display: "flex", justifyContent: 'flex-end', flexShrink: { sm: 0 } }}>
                            <IconButton sx={{ display: { xs: "block", sm: "none", }, p: 0 }} aria-label="delete" onClick={() => toggleShowFilter()}>
                                {filters ? <FilterAltOffIcon /> : <FilterAltIcon />}
                            </IconButton>
                        </Grid>
                    </Grid>
                </Box>
                <Divider sx={{ mb: 2, display: { xs: "none", md: "block" } }} />
                <Card sx={{ mb: 2 }}>
                    <Box sx={{ p: { xs: filters ? 2 : 0, sm: 2, lg: 2 }, borderBottom: "#eee 1px solid" }}>
                        <Grid container spacing={{ xs: filters ? 1 : 0, sm: 1 }} columns={12} alignItems="center">
                            <Grid item xs={12} sm={5} md={7} lg={7} >
                                <Box sx={{ display: "flex", gap: "10px", flexWrap: { sm: "wrap", md: "no-wrap" }, alignItems: "center" }}>
                                    <Box className="responsiveFixed">
                                        <ButtonGroup variant="outlined" color="primary" aria-label="medium success button group">
                                            {tabs.map((tab) => {
                                                return (
                                                    <Button key={tab.name} sx={{ whiteSpace: "nowrap" }} variant={`${activeTab === tab.name ? 'contained' : 'outlined'}`} onClick={() => clickTab(tab.name)}>{tab.label}</Button>
                                                )
                                            })}
                                        </ButtonGroup>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={7} md={5} lg={5}>
                                <Box className='mobilescrollMenu' sx={{ textAlign: { sm: 'right' }, display: { xs: filters ? "block" : "none", sm: 'flex' }, flexWrap: { sm: "wrap", md: "no-wrap" }, justifyContent: { sm: "flex-end" } }}>
                                    {searchParams.get("sort_key") || searchParams.get("sort_value") ?
                                        <Button variant='text' color="error" size="small" sx={{ mr: 2 }} endIcon={<ClearIcon />} onClick={clearSortBy}>
                                            <Box sx={{ display: { xs: "none", md: "block" } }}>Clear</Box>
                                        </Button>
                                        : null
                                    }
                                    <Box sx={{ mr: 1 }}>
                                        <Button
                                            id="discountCodeType"
                                            aria-controls={openStatus ? 'discountCodeTypeArea' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={openStatus ? 'true' : undefined}
                                            variant="text"
                                            disableElevation
                                            size="small"
                                            color="secondary"
                                            onClick={(e) => handleClick(e, "email_status")}
                                            endIcon={<KeyboardArrowDownIcon />}>

                                            {statusArray.filter((x) => x.active)[0]?.name || "Status"}
                                        </Button>

                                        <StyledMenu
                                            id="discountCodeTypeArea"
                                            MenuListProps={{ 'aria-labelledby': 'discountCodeType', }}
                                            anchorEl={anchorEl.email_status}
                                            open={openStatus}
                                            onClose={handleClose}>

                                            {statusArray.map((data, index) => {
                                                return (
                                                    <MenuItem key={index} onClick={() => handleSortChange(data, "status")} disableRipple sx={{ fontSize: "12px" }}>{data.name}</MenuItem>
                                                )
                                            })}
                                        </StyledMenu>
                                    </Box>
                                    <Box sx={{ mr: 1 }}>
                                        <Button
                                            id="discountCodeType"
                                            aria-controls={openDate ? 'discountCodeTypeArea' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={openDate ? 'true' : undefined}
                                            variant="text"
                                            disableElevation
                                            size="small"
                                            color="secondary"
                                            onClick={(e) => handleClick(e, "date")}
                                            endIcon={<KeyboardArrowDownIcon />}>

                                            {sortDate.filter((x) => x.active)[0]?.name || "Date"}
                                        </Button>

                                        <StyledMenu
                                            id="discountCodeTypeArea"
                                            MenuListProps={{ 'aria-labelledby': 'discountCodeType', }}
                                            anchorEl={anchorEl.date}
                                            open={openDate}
                                            onClose={handleClose}>

                                            {sortDate.map((data, index) => {
                                                return (
                                                    <MenuItem key={index} onClick={() => handleSortChange(data, "date")} disableRipple sx={{ fontSize: "12px" }}>{data.name}</MenuItem>
                                                )
                                            })}
                                        </StyledMenu>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box className="discountTable" sx={{ width: '100%' }}>
                        {loadingBar ?
                            <Skeleton variant="text" height={40} width={"100%"} />
                            :
                            <Box>
                                <DataGrid
                                    rows={checkoutOrders}
                                    columns={columns}
                                    pageSize={limit}
                                    rowsPerPageOptions={[]}
                                    onPageSizeChange={(e) => onPageSizeChange(e)}
                                    checkboxSelection={false}
                                    disableColumnMenu
                                    disableSelectionOnClick={true}
                                    autoHeight={true}
                                    density={density}
                                    ref={ref}
                                    hideFooterPagination={true}
                                    hideFooter={true}
                                    // onSelectionModelChange={(newSelectionModel) => {
                                    //     setSelectionModel(newSelectionModel);
                                    // }}
                                    // selectionModel={selectionModel}
                                    components={{
                                        NoRowsOverlay: CustomNoRowsOverlay,
                                        // LoadingOverlay: LinearProgress,
                                    }}
                                    sx={{
                                        '.MuiDataGrid-columnSeparator': {
                                            display: 'none',
                                        },
                                        '&.MuiDataGrid-root': {
                                            border: 'none',
                                        },
                                    }}
                                    loading={loadingData}
                                    selectionModel={selectionModel}
                                />
                                {checkoutOrders.length > 0 ?
                                    <Box sx={{ display: "flex", justifyContent: "space-between", gap: "10px", flexWrap: "wrap", p: 2, alignItems: "center" }}>
                                        <Typography variant='body2' component={"div"} sx={{ mb: 0 }}>Showing {checkoutOrders.length} of {totalOrders} orders</Typography>
                                        <Pagination count={count} page={params.page} showFirstButton showLastButton onChange={handleChangePage} />
                                    </Box>
                                    : null
                                }
                            </Box>
                        }
                        {/* <Pagination count={count} page={params.page} showFirstButton showLastButton sx={{ p: 2, float: "right" }} onChange={handleChangePage} /> */}
                    </Box>
                </Card>
            </Container>
        </React.Fragment >
    )
}

