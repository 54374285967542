import React, { useEffect, useState, useRef, useCallback } from 'react';
import {
    Box, Button, Grid, Menu, MenuItem, Card, CardContent, CardActionArea, CardMedia,
    FormControl, InputAdornment, Divider, FormControlLabel,
    Checkbox, Radio, FormLabel, RadioGroup, FormHelperText, Typography,
    FormGroup, Select, TextField, InputBase, Container, InputLabel,
    Avatar, ListItemAvatar, ListItemText, ListItemButton, ListItem, List, IconButton, OutlinedInput,
    Chip, Badge, styled, ListItemIcon, LinearProgress, Popover
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { Link, useNavigate, useParams } from "react-router-dom";

// icons 
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import CircleIcon from '@mui/icons-material/Circle';
import CancelIcon from '@mui/icons-material/Cancel';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import PrintIcon from '@mui/icons-material/Print';
import states from "../../../common/CommonJason/states";

import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import MoreVertIcon from '@mui/icons-material/MoreVert';
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import DoneIcon from '@mui/icons-material/Done';

import * as Api from "../../../../Container/config/Apis"
import config from '../../../../Container/config';
import _ from "underscore";
import moment from "moment";
import { useSnackbar } from 'notistack';
import CurrencyFormat from 'react-currency-format';

import DialogAlert from '../../../common/Alerts/Dialog';
import { useSelector } from 'react-redux';
import noImage from '../../../../Assets/images/no_image.jpg';
import PopupModal from '../../../common/PopupModal/PopupModal';


// common components

// file drag drop  
const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 22,
    height: 22,
    border: `1px solid ${theme.palette.background.paper}`,
    objectFit: "contain"
}));


const ExchaneOrderDetail = () => {
    const dataVal = useSelector((state) => state.dataValue)
    console.log("dataVal: ", dataVal)
    const param = useParams()
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar();
    const snack = (msg, variant) => {
        enqueueSnackbar(msg, { variant });
    }

    const [returnData, setReturnData] = useState({})
    const [deleteType, setDeleteType] = useState({})
    const [showDialog, setOpen] = React.useState(false);
    const [content, setContent] = React.useState({
        title: "Are you sure?",
        message: "",
        type: ""
    });

    const [anchorEl, setAnchorEl] = React.useState({
        order: null,
        button1: null,
        button2: null,
        moreActionBtn: null,
        location: null,
    });
    const open3 = Boolean(anchorEl.moreActionBtn);

    const [loadingBar, setloadingBar] = useState(true)
    const [loadingBtn, setloadingBtn] = useState(false)
    const [orderData, setOrderData] = useState({})

    const [paymentData, setpaymentData] = useState({})
    const [qty, setQty] = useState(0)

    const [shipRocket, setshipRocket] = useState([])
    const [rejectReason, setRejectReason] = useState("")
    const [errors, setErrors] = useState(false)
    const [copied, setcopied] = useState({
        email: false,
        phone: false,
    })
    const [openModal, setOpenModal] = useState(false);
    const [modalContent, setModalContent] = useState({
        type: "",
        modalTitle: ""
    })

    const closeDialog = () => {
        setDeleteType({})
        setOpen(false);
        ModalClose()
    }

    const ModalClose = () => {
        setOpenModal(false)
    }
    const handleClick = (event, data) => {
        setAnchorEl({ ...anchorEl, [data]: event.currentTarget });
    };
    const handleClose = () => {
        setAnchorEl({
            order: null,
            button1: null,
            button2: null,
            moreActionBtn: null,
            location: null,
        });
    };


    function getShiprocket(url) {
        Api.GetApi(url).then(res2 => {
            console.log("SHIPROCKET_LOCATIONS res: ", res2)
            if (res2.error === true) {
                snack(res2.response.data.error, 'error');
            } else {
                if (res2.data.data) {
                    setshipRocket(res2.data.data)
                }
            }
        })
    }
    function statuFn(url) {
        Api.GetApi(`${url}/${returnData?.lineItemId}`).then(res2 => {
            console.log("GENERATE_PICKUP res: ", res2)
            if (res2.error === true) {
                snack(res2.response.data.error, 'error');
            } else {
                snack(res2.data.data, 'success');
                closeDialog()
                if (param.id) {
                    getOrderData(param.id)
                }
            }
        })
    }

    function acceptFn(type) {
        let data = {}
        if (type == "accept") {
            data = {
                ...data,
                status: "ACCEPTED"
            }
        } else {
            data = {
                ...data,
                status: "REJECTED",
                reason: rejectReason
            }
        }

        console.log("data", data)
        if (type !== "accept" && rejectReason == "") {
            setErrors(true)
        } else {
            setErrors(false)
            Api.PostApi(`${config.RETURN_STATUS}/${param.item}`, data).then(res2 => {
                console.log("GENERATE_PICKUP res: ", res2)
                if (res2.error === true) {
                    snack(res2.response.data.error, 'error');
                } else {
                    snack(res2.data.data, 'success');
                    closeDialog()
                    if (param.id) {
                        getOrderData(param.id)
                    }
                }
            })
        }
    }

    const generatePickup = () => {
        setContent({ ...content, message: "You want to generate pickup for this request.", type: "pickup" })
        setOpen(true)
    }
    const markRefunded = () => {
        setContent({ ...content, message: "You have refunded the amount to user.", type: "refund" })
        setOpen(true)
    }
    const acceptOrder = () => {
        setContent({ ...content, message: `You want to accept the request.`, type: "accept" })
        setOpen(true)
    }
    const rejectOrder = () => {
        setOpenModal(true)
        setModalContent({
            type: "reject",
            modalTitle: "Reject reason"
        })
    }

    const handleRejectReason = (e) => {
        setRejectReason(e.target.value)
    }

    const confirmDialog = () => {
        if (content.type == "create") {
            createOrderFn()
        } else if (content.type == "refund") {
            statuFn(config.RETURN_COMPLETE)
        } else if (content.type == "accept") {
            acceptFn("accept")
        } else {
            statuFn(config.GENERATE_PICKUP)
        }
    }

    const getOrderData = (id) => {
        Api.GetApi(`${config.ORDERS}/${id}`).then(res => {
            setTimeout(() => {
                setloadingBar(false)
            }, 500);
            console.log("res data: ", res)
            if (res.error === true) {
                snack(res.response.data.error, 'error');
            } else {
                if (res.data.data) {
                    let resData = res.data.data
                    if (resData.Track && resData.Track.length > 0) {
                        let orderStatus = resData.Track.filter((x) => x?.is_active)
                        if (orderStatus) {
                            resData["order_status"] = orderStatus[0]?.status
                            if (orderStatus[0]?.status == "Delivered") {
                                resData["deliveryDate"] = orderStatus[0]?.updated_at
                            }
                        } else {
                            resData["order_status"] = config?.CONFIRMED
                        }
                        if (resData.order_status == config?.CONFIRMED) {
                            setTracking({ ...orderStatus[0] })
                        }
                    } else {
                        resData["order_status"] = config?.CONFIRMED
                    }

                    resData["line_items_by_location"] = _.values(resData?.line_items_by_location)
                    let arrMain = []
                    let arr = []
                    let returnExchangeId = resData?.return_exchange_details.filter((x) => x?.returnExchangeId == param.item)
                    if (returnExchangeId && returnExchangeId.length) {
                        setReturnData(returnExchangeId[0])
                        resData.line_items_by_location && resData.line_items_by_location.map((opt) => {
                            opt.map((x) => {
                                if (x?.LineItem_id == returnExchangeId[0]?.lineItemId) {
                                    arr.push({ ...x, isReturn: true, returnData: returnExchangeId[0] })
                                } else {
                                    arr.push({ ...x })
                                }
                            })
                            arrMain.push(arr)
                        })
                        console.log("arr returnExchangeId", arrMain)
                        resData.line_items_by_location = arrMain
                    }

                    if (resData?.ﬁnancial_status == "paid" || resData?.ﬁnancial_status == "succeeded" || resData?.ﬁnancial_status == "authorized") {
                        resData['ﬁnancial_status'] = "paid"
                    } else if (resData?.ﬁnancial_status == "Voided") {
                        resData['ﬁnancial_status'] = "Failed"
                    } else {
                        resData['ﬁnancial_status'] = "Unpaid"
                    }

                    // if (resData?.processing_method == "shiprocket") {
                    // }
                    setOrderData(resData)
                    let qtyArray = []
                    resData.line_items.length > 0 && resData.line_items.map((opt) => {
                        qtyArray.push(opt.quantity)
                    })
                    let quantity = _.reduce(qtyArray, function (memo, num) { return memo + num; }, 0);
                    setQty(quantity)
                }
            }
        })
    }

    const copiedFn = (type) => {
        setcopied({ ...copied, [type]: true })
        setTimeout(() => {
            setcopied({
                email: false,
                phone: false,
            })
        }, 2000);
    }

    const savebtnFunct = () => {
        acceptFn("reject")
    }
    const createOrder = () => {
        setContent({ ...content, message: `You want to create order for this request.`, type: "create" })
        setOpen(true)
    }


    const createOrderFn = () => {
        Api.GetApi(`${config.CREATE_RETURN_ORDER}/${param.item}`).then(res => {
            setTimeout(() => {
                setloadingBar(false)
            }, 500);
            console.log("res CREATE_RETURN_ORDER: ", res)
            if (res.error === true) {
                snack(res.response.data.error, 'error');
            } else {
                snack("Order created successfully", 'success');
                closeDialog()
                if (param.id) {
                    getOrderData(param.id)
                }
            }
        })
    }

    useEffect(() => {
        let url = ""
        if (dataVal && dataVal.store && dataVal.store?.activeShippingSource && dataVal.store?.activeShippingSource) {
            if (dataVal.store?.activeShippingSource?.shipping_source == "Goswift") {
                url = config.GOSWIFT_PICKUPLOC
            }
            else {
                url = config.SHIPROCKET_LOCATIONS
            }
            getShiprocket(url)
        }
    }, [dataVal])

    useEffect(() => {
        if (param.id) {
            getOrderData(param.id)
        }
    }, [])


    console.log("orderData: ", orderData)
    console.log("returnData: ", returnData)
    console.log("shipRocket: ", shipRocket)


    return (
        <>
            {loadingBar ?
                <LinearProgress className="top_loader" color="info" sx={{ mt: "-25px", mb: "21px" }} />
                :
                <Container maxWidth="lg">
                    <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2, alignItems: "center" }}>

                        <Box>
                            <Button component={Link} variant="text" to="/admin/orders/returns" color="secondary" startIcon={<ArrowBackIosIcon />}>
                                Exchange & return order<br />
                                {param.item}
                            </Button>
                            <Chip sx={{ height: "26px", textTransform: "capitalize", fontSize: "12px !important", color: "#fff", ml: 3 }}
                                label={returnData?.status}
                                variant={"contained"}
                                color={returnData?.status == "Pending" ? 'warning' : returnData?.status == "Rejected" ? 'error' : 'success'}
                            />
                        </Box>
                        {returnData?.status !== "Closed" ?
                            <>
                                {returnData?.status !== "Rejected" && returnData?.status !== "Completed" ?
                                    <>
                                        {returnData?.status == "Pending" ?
                                            <Box>
                                                <Button variant="contained" sx={{ whiteSpace: "nowrap", mr: 2 }} onClick={acceptOrder}>Accept</Button>
                                                <Button variant="outlined" sx={{ whiteSpace: "nowrap" }} onClick={rejectOrder} color='error'>Reject</Button>
                                            </Box>
                                            :
                                            <Box>
                                                {shipRocket.length > 0 && returnData?.status !== "In progress" ?
                                                    <Button variant="contained" sx={{ whiteSpace: "nowrap", mr: 2 }} onClick={generatePickup}>Generate pickup</Button>
                                                    : null
                                                }
                                                {returnData?.mode == "return" ?
                                                    <Button variant="contained" sx={{ whiteSpace: "nowrap" }} onClick={markRefunded}>Mark as refunded</Button>
                                                    :
                                                    null
                                                }
                                                {returnData?.mode !== "return" && (returnData?.status == "In progress" || returnData?.status == "Accepted") ?
                                                    <Button onClick={createOrder} variant="outlined" color='secondary' sx={{ whiteSpace: "nowrap", ml: 2 }}>Create order</Button>
                                                    // <Button component={Link} to={`/admin/orders/create/${param.item}`} variant="outlined" color='secondary' sx={{ whiteSpace: "nowrap", ml: 2 }}>Create order</Button>
                                                    : null
                                                }
                                            </Box>
                                        }
                                    </>
                                    :
                                    returnData?.status == "Completed" && returnData?.mode == "exchange" && returnData?.exchange_order_id ?
                                        <Button component={Link} to={`/admin/orders/${returnData?.exchange_order_id}`} variant="outlined" color='secondary' sx={{ whiteSpace: "nowrap", ml: 2 }}>View order</Button>
                                        : null
                                }
                            </>
                            : null
                        }
                    </Box>
                    <Box>
                        <Grid container spacing={2} columns={12}>
                            <Grid item md={8}>
                                <Card sx={{ mb: 2 }}>
                                    <CardContent>
                                        <Grid container spacing={2} columns={12}>
                                            <Grid item md={6}>
                                                {/* <Typography variant="h6" component="div" sx={{ pl: 1 }}>{orderData?.order_number}</Typography> */}
                                                <Button component={Link} sx={{ wordBreak: "break-all", p: 0 }} to={`/admin/orders/${orderData?.order_id}`} color="secondary" className='noHover'>
                                                    <Typography variant="h6" component="div" sx={{ pl: 1 }}>
                                                        {orderData?.order_number}
                                                    </Typography>
                                                </Button>
                                                <Typography variant="subtitle2" component="div" sx={{ pl: 1 }}>{moment(orderData?.created_at).format("llll")}</Typography>

                                            </Grid>
                                            <Grid item md={6} sx={{ textAlign: "Right" }}>
                                                <Box sx={{ mb: 1 }}>
                                                    <Chip sx={{ height: "26px", textTransform: "capitalize", fontSize: "13px !important", color: "#fff" }}
                                                        label={"Delivered"}
                                                        variant={"contained"}
                                                        color='success'
                                                    />
                                                </Box>
                                                <Typography variant="subtitle2" component="div" >{moment(orderData?.deliveryDate).format("llll")}</Typography>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                                {orderData && orderData?.line_items_by_location && orderData?.line_items_by_location.map((opt, i) => {
                                    return (
                                        <Card sx={{ mb: 2 }} key={i}>
                                            <CardContent>
                                                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                                    {shipRocket && shipRocket.length > 0 && shipRocket.filter((x) => (x?.id || x?.alias) == opt[0]?.location_id).map((loc, ind) => {
                                                        return (
                                                            <Box key={ind}>
                                                                <Typography variant="p" component="strong">Location:</Typography>
                                                                <Typography variant="p" component="span">{" "} {loc?.pickup_location || loc?.alias}</Typography>
                                                            </Box>
                                                        )
                                                    })}

                                                </Box>
                                                {opt && opt.length > 0 && opt.map((data, index) => {
                                                    return (
                                                        <Box key={index} className="orderProductBox" sx={{ display: "flex", backgroundColor: data?.isReturn && data?.returnData ? "rgba(255,0,0,0.04)" : "transparent" }}>
                                                            <Box className="orderProductBox__img">
                                                                <Badge
                                                                    overlap="circular"
                                                                    // anchorOrigin={{ vertical: "top", horizontal: "right" }}
                                                                    badgeContent={
                                                                        <SmallAvatar sx={{ fontSize: 10, width: "auto", height: "auto", py: 0.5, px: 0.6, objectFit: "contain" }} > {data?.quantity} </SmallAvatar>
                                                                    }  >
                                                                    <Avatar alt="Image" src={data?.product_variant?.Image?.src || (data?.product?.images && data?.product.images.length > 0 && _.sortBy(data?.product.images, "position")[0]?.src) || noImage} variant="square" sx={{ objectFit: "contain" }} />
                                                                </Badge>
                                                            </Box>
                                                            <Box className="orderProductBoxCntnt" sx={{ display: "flex" }}>
                                                                <Box className="orderProductBoxCntnt__link">
                                                                    <Link to="" color="inherit">{data?.title}</Link>
                                                                    {data?.name !== "" && data?.name !== "Default Title" ?
                                                                        <Typography variant="caption" component="div">{data?.name} </Typography>
                                                                        : null
                                                                    }
                                                                    {data.sku &&
                                                                        <Typography variant="caption" component="div">SKU: {data?.sku} </Typography>
                                                                    }
                                                                </Box>
                                                                <Box className="orderProductBoxCntnt__link">
                                                                    <CurrencyFormat value={parseFloat(data?.price).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={config.CURRENCY} /> x {data?.quantity}

                                                                    {data?.isReturn && data?.returnData ?
                                                                        <Box>
                                                                            <Box>
                                                                                <Typography variant="subtitle2" component="strong" sx={{ color: "red" }}>
                                                                                    Request for {data?.returnData?.mode}
                                                                                </Typography>
                                                                            </Box>
                                                                            <Typography variant="subtitle2" component="strong" sx={{ color: "red" }}>
                                                                                Qty: {data?.returnData?.quantity}
                                                                            </Typography>
                                                                        </Box>
                                                                        : null
                                                                    }
                                                                </Box>
                                                                {/* <Box className="orderProductBoxCntnt__qntty" sx={{ minWidth: "120px", textAlign: "right" }}><Typography variant="subtitle1" component="div">₹{(data.price).toFixed(2)} × {data.quantity} </Typography> </Box> */}
                                                                <Box className="orderProductBoxCntnt__price">
                                                                    <Typography variant="caption" component="div" sx={{ textAlign: "end" }}>
                                                                        {data?.refund_quantity && data?.refund_quantity > 0 ?
                                                                            <>
                                                                                Subtotal:
                                                                                <CurrencyFormat value={parseFloat(data?.price * (data?.quantity)).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={config.CURRENCY} />
                                                                                {data?.discount_amount && data?.discount_amount > 0 ? (
                                                                                    <>
                                                                                        <br />
                                                                                        Discount: <span style={{ color: "red" }}>
                                                                                            -<CurrencyFormat value={parseFloat(data?.discount_amount).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={config.CURRENCY} />
                                                                                        </span>
                                                                                    </>
                                                                                ) : null}
                                                                                <br />
                                                                            </>
                                                                            :
                                                                            <>
                                                                                Subtotal:
                                                                                <CurrencyFormat value={parseFloat(data?.price * data?.quantity).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={config.CURRENCY} />
                                                                                {data?.discount_amount && data?.discount_amount > 0 ? (
                                                                                    <>
                                                                                        <br />
                                                                                        Discount: <span style={{ color: "red" }}>
                                                                                            -<CurrencyFormat value={parseFloat(data?.discount_amount).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={config.CURRENCY} />
                                                                                        </span>
                                                                                    </>
                                                                                ) : null
                                                                                }
                                                                                <br />
                                                                            </>
                                                                        }
                                                                    </Typography>
                                                                    {data?.tax_amount ?
                                                                        <Typography variant="caption" component="div" sx={{ textAlign: "end" }}>
                                                                            Tax: <CurrencyFormat value={parseFloat(data?.tax_amount).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={config.CURRENCY} />
                                                                        </Typography>
                                                                        : null
                                                                    }
                                                                    <Typography variant="subtitle2" component="div" sx={{ textAlign: "end" }}>
                                                                        Total: <CurrencyFormat value={parseFloat((data?.price * data?.quantity) + data?.tax_amount - data?.discount_amount).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={config.CURRENCY} />
                                                                    </Typography>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    )
                                                })}
                                                <Divider />
                                                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start", my: 1 }}>
                                                    <Typography variant="h6" component="div"  >Details </Typography>
                                                    <Box sx={{ textAlign: "Right" }}>

                                                        <Chip sx={{ height: "26px", mb: 1, textTransform: "capitalize", fontSize: "13px !important", color: "#fff" }}
                                                            label={returnData?.mode}
                                                            variant={"contained"}
                                                            color={returnData?.mode == "return" ? 'error' : 'success'}
                                                        />
                                                        <Typography variant="subtitle2" component="div" >{moment(returnData?.createdAt).format("llll")}</Typography>
                                                    </Box>
                                                </Box>

                                                <Box className="ordertabular" sx={{ display: "flex", mb: 1 }}>
                                                    <Box className="ordertabular__title" >
                                                        <Typography variant="subtitle2" component="div"  >Reason </Typography>
                                                    </Box>
                                                    <Box className="ordertabular__cntnt" sx={{ display: "flex", justifyContent: "space-between", flexGrow: 1, }}>
                                                        <Box></Box>
                                                        <Box>
                                                            <Typography variant="body2" component="div" sx={{ ml: 1 }}>{returnData?.reason}</Typography>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                                {returnData?.mode == "return" && orderData?.payment_gateway_names.toLowerCase() == "cod" ?
                                                    <>
                                                        <Box className="ordertabular" sx={{ display: "flex", mb: 1 }}>
                                                            <Box className="ordertabular__title" >
                                                                <Typography variant="subtitle2" component="div"  >Mode of refund </Typography>
                                                            </Box>
                                                            <Box className="ordertabular__cntnt" sx={{ display: "flex", justifyContent: "space-between", flexGrow: 1, }}>
                                                                <Box></Box>
                                                                <Box>
                                                                    <Typography variant="subtitle2" component="div" sx={{ ml: 1, textTransform: "capitalize" }}> {returnData?.refund_mode.split("_").join(" ")}</Typography>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                        {returnData?.refund_mode == "UPI" ?
                                                            <Box className="ordertabular" sx={{ display: "flex", mb: 1 }}>
                                                                <Box className="ordertabular__title" >
                                                                    <Typography variant="subtitle2" component="div"  >UPI Id</Typography>
                                                                </Box>
                                                                <Box className="ordertabular__cntnt" sx={{ display: "flex", justifyContent: "space-between", flexGrow: 1, }}>
                                                                    <Box></Box>
                                                                    <Box>
                                                                        <Typography variant="body2" component="div" sx={{ ml: 1 }}> {returnData?.bankDetails?.upi_id}</Typography>
                                                                    </Box>
                                                                </Box>
                                                            </Box>
                                                            :
                                                            <>
                                                                <Box className="ordertabular" sx={{ display: "flex", mb: 1 }}>
                                                                    <Box className="ordertabular__title" >
                                                                        <Typography variant="subtitle2" component="div"  >Benificiary Name</Typography>
                                                                    </Box>
                                                                    <Box className="ordertabular__cntnt" sx={{ display: "flex", justifyContent: "space-between", flexGrow: 1, }}>
                                                                        <Box></Box>
                                                                        <Box>
                                                                            <Typography variant="body2" component="div" sx={{ ml: 1 }}>{returnData?.bankDetails?.bank_ac_holder_name}</Typography>
                                                                        </Box>
                                                                    </Box>
                                                                </Box>
                                                                <Box className="ordertabular" sx={{ display: "flex", mb: 1 }}>
                                                                    <Box className="ordertabular__title" >
                                                                        <Typography variant="subtitle2" component="div"  >Account number</Typography>
                                                                    </Box>
                                                                    <Box className="ordertabular__cntnt" sx={{ display: "flex", justifyContent: "space-between", flexGrow: 1, }}>
                                                                        <Box></Box>
                                                                        <Box>
                                                                            <Typography variant="body2" component="div" sx={{ ml: 1 }}> {returnData?.bankDetails?.bank_ac_number}</Typography>
                                                                        </Box>
                                                                    </Box>
                                                                </Box>
                                                                <Box className="ordertabular" sx={{ display: "flex", mb: 1 }}>
                                                                    <Box className="ordertabular__title" >
                                                                        <Typography variant="subtitle2" component="div"  >IFSC code</Typography>
                                                                    </Box>
                                                                    <Box className="ordertabular__cntnt" sx={{ display: "flex", justifyContent: "space-between", flexGrow: 1, }}>
                                                                        <Box></Box>
                                                                        <Box>
                                                                            <Typography variant="body2" component="div" sx={{ ml: 1 }}> {returnData?.bankDetails?.bank_ifsc_code}</Typography>
                                                                        </Box>
                                                                    </Box>
                                                                </Box>
                                                            </>
                                                        }
                                                    </>
                                                    : null
                                                }
                                                <Box className="ordertabular" sx={{ display: "flex", mb: 1 }}>
                                                    <Box className="ordertabular__title" >
                                                        <Typography variant="subtitle2" component="div"  >Comments </Typography>
                                                    </Box>
                                                    <Box className="ordertabular__cntnt" sx={{ display: "flex", justifyContent: "space-between", flexGrow: 1, }}>
                                                        <Box></Box>
                                                        <Box>
                                                            <Typography variant="body2" component="div" sx={{ ml: 1, fontStyle: "italic" }}> {returnData?.comments || "-"}</Typography>
                                                        </Box>
                                                    </Box>
                                                </Box>


                                            </CardContent>
                                        </Card>
                                    )
                                })}

                                <Card sx={{ mb: 2 }}>
                                    <CardContent>
                                        <Box className="ordertabular" sx={{ display: "flex", mb: 1 }}>
                                            <Box className="ordertabular__title" >
                                                <Typography variant="subtitle2" component="div"  >Subtotal </Typography>
                                            </Box>
                                            <Box className="ordertabular__cntnt" sx={{ display: "flex", justifyContent: "space-between", flexGrow: 1, }}>
                                                <Box></Box>
                                                <Box> <Typography variant="body2" component="div" sx={{ ml: 1 }}>
                                                    <CurrencyFormat value={parseFloat(orderData?.subtotal_price || 0).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={config.CURRENCY} />
                                                </Typography> </Box>
                                            </Box>
                                        </Box>
                                        <Box className="ordertabular" sx={{ display: "flex", mb: 1 }}>
                                            <Box className="ordertabular__title" >
                                                <Typography variant="subtitle2" component="div" >Shipping </Typography>
                                            </Box>
                                            <Box className="ordertabular__cntnt" sx={{ display: "flex", justifyContent: "space-between", flexGrow: 1, }}>
                                                <Box> <Typography variant="body2" component="div" sx={{ ml: 1 }}>
                                                    {/* Standard Shipping (0.34 kg) */}
                                                </Typography> </Box>
                                                <Box> <Typography variant="body2" component="div" sx={{ ml: 1 }}>
                                                    <CurrencyFormat value={parseFloat(orderData?.total_shipping_price_set?.presentment_money?.amount || 0).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={config.CURRENCY} /> </Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box className="ordertabular" sx={{ display: "flex", mb: 1 }}>
                                            <Box className="ordertabular__title" >
                                                <Typography variant="subtitle2" component="div" >Discount </Typography>
                                            </Box>
                                            <Box className="ordertabular__cntnt" sx={{ display: "flex", justifyContent: "space-between", flexGrow: 1, }}>
                                                <Box></Box>
                                                <Box>
                                                    <Typography variant="body2" component="div" sx={{ ml: 1, color: "red" }}>
                                                        {/* -<CurrencyFormat value={parseFloat(orderData?.current_total_discounts || 0).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={config.CURRENCY} /> */}
                                                        {"-"}<CurrencyFormat value={parseFloat(orderData?.total_discounts || 0.00).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={config.CURRENCY} />
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box className="ordertabular" sx={{ display: "flex", mb: 1 }}>
                                            <Box className="ordertabular__title" >
                                                <Typography variant="subtitle2" component="div" >Tax </Typography>
                                            </Box>
                                            <Box className="ordertabular__cntnt" sx={{ display: "flex", justifyContent: "space-between", flexGrow: 1, }}>
                                                <Box></Box>
                                                <Box>
                                                    <Typography variant="body2" component="div" sx={{ ml: 1 }}>
                                                        <CurrencyFormat value={parseFloat(orderData?.total_tax || 0.00).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={config.CURRENCY} />
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                        {orderData?.refunds.length > 0 && orderData?.refund_amount ?
                                            <Box className="ordertabular" sx={{ display: "flex", mb: 1 }}>
                                                <Box className="ordertabular__title" >
                                                    <Typography variant="subtitle2" component="div" >Total refund </Typography>
                                                </Box>
                                                <Box className="ordertabular__cntnt" sx={{ display: "flex", justifyContent: "space-between", flexGrow: 1, }}>
                                                    <Box>
                                                    </Box>
                                                    <Box> <Typography variant="body2" component="div" sx={{ ml: 1, color: "red" }}>
                                                        <CurrencyFormat value={parseFloat(orderData?.refund_amount || 0).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={config.CURRENCY} /> </Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                            : null
                                        }
                                        <Box className="ordertabular" sx={{ display: "flex", mb: 1 }}>
                                            <Box className="ordertabular__title" >
                                                <Typography variant="p" component="strong" >Total paid</Typography>
                                            </Box>
                                            <Box className="ordertabular__cntnt" sx={{ display: "flex", justifyContent: "space-between", flexGrow: 1, }}>
                                                <Box> <Typography variant="subtitle1" component="div" sx={{ ml: 1 }}> </Typography> </Box>
                                                <Box> <Typography variant="p" component="strong" sx={{ ml: 1 }}>
                                                    <CurrencyFormat value={parseFloat(orderData?.total_price || 0).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={config.CURRENCY} />
                                                </Typography> </Box>
                                            </Box>
                                        </Box>



                                        {orderData?.refunds && orderData.refunds.length > 0 &&
                                            <Box sx={{ pt: 1 }}>
                                                <Divider sx={{ mb: 1 }} />

                                                <Box sx={{ pt: 2, mb: 2 }}>
                                                    <Typography variant="p" component="strong" gutterBottom >Refund history</Typography>
                                                </Box>
                                                <Box sx={{ maxHeight: "400px", overflowY: "auto" }}>
                                                    {orderData.refunds.map((refund, i) => {
                                                        return (
                                                            <Grid container spacing={5} columns={12}>
                                                                <Grid item md={12}>
                                                                    <Box className="ordertabular_outer refund_timeline" key={i}>
                                                                        {refund.RefundTransaction && refund.RefundTransaction.length > 0 && refund.RefundTransaction.map((opt, ind) => {
                                                                            return (
                                                                                <>
                                                                                    <Box className="ordertabular" sx={{ display: "flex" }} key={ind}>
                                                                                        <Box className="ordertabular__title" >
                                                                                            <Typography variant="subtitle2" component="div" >Status</Typography>
                                                                                        </Box>
                                                                                        <Box className="ordertabular__cntnt" sx={{ display: "flex", flexGrow: 1, }}>
                                                                                            <Typography variant="body2" component="div" sx={{ textTransform: "capitalize" }}>{opt?.status}</Typography>
                                                                                        </Box>
                                                                                    </Box>
                                                                                    <Box className="ordertabular" sx={{ display: "flex", mt: '5px' }} key={ind}>
                                                                                        <Box className="ordertabular__title" >
                                                                                            <Typography variant="subtitle2" component="div" >Amount</Typography>
                                                                                        </Box>
                                                                                        <Box className="ordertabular__cntnt" sx={{ display: "flex", flexGrow: 1, }}>
                                                                                            <Typography variant="body2" component="div" sx={{ textTransform: "capitalize" }}>
                                                                                                <CurrencyFormat value={parseFloat(opt?.amount || 0).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={config.CURRENCY} />
                                                                                            </Typography>
                                                                                        </Box>
                                                                                    </Box>
                                                                                    {opt?.refund == "cod" ?
                                                                                        <Box className="ordertabular" sx={{ display: "flex", mt: '5px' }} key={ind}>
                                                                                            <Box className="ordertabular__title" >
                                                                                                <Typography variant="subtitle2" component="div" >Receipt no.</Typography>
                                                                                            </Box>
                                                                                            <Box className="ordertabular__cntnt" sx={{ display: "flex", flexGrow: 1, }}>
                                                                                                <Typography variant="body2" component="div" sx={{ textTransform: "capitalize" }}>
                                                                                                    {opt?.recipt || ""}
                                                                                                </Typography>
                                                                                            </Box>
                                                                                        </Box>
                                                                                        :
                                                                                        <Box className="ordertabular" sx={{ display: "flex", mt: '5px' }} key={ind}>
                                                                                            <Box className="ordertabular__title" >
                                                                                                <Typography variant="subtitle2" component="div" >Refund ID</Typography>
                                                                                            </Box>
                                                                                            <Box className="ordertabular__cntnt" sx={{ display: "flex", flexGrow: 1, }}>
                                                                                                <Typography variant="body2" component="div" sx={{ textTransform: "capitalize" }}>
                                                                                                    {opt?.refund?.id || opt?.refund?.request_id || opt?.refund?.refund_id || ""}
                                                                                                </Typography>
                                                                                            </Box>
                                                                                        </Box>
                                                                                    }
                                                                                    <Box className="ordertabular" sx={{ display: "flex", mt: '5px' }} key={ind}>
                                                                                        <Box className="ordertabular__title" >
                                                                                            <Typography variant="subtitle2" component="div" >Date</Typography>
                                                                                        </Box>
                                                                                        <Box className="ordertabular__cntnt" sx={{ display: "flex", flexGrow: 1, }}>
                                                                                            <Typography variant="body2" component="div" sx={{ textTransform: "capitalize" }}>
                                                                                                {moment(opt?.refund_at).format("llll") || ""}
                                                                                            </Typography>
                                                                                        </Box>
                                                                                    </Box>
                                                                                </>
                                                                            )
                                                                        })}
                                                                        <Box className="ordertabular" sx={{ display: "flex", mt: '5px' }}>
                                                                            <Box className="ordertabular__title" >
                                                                                <Typography variant="subtitle2" component="div" >Refund reason:</Typography>
                                                                            </Box>
                                                                            <Box className="ordertabular__cntnt" sx={{ display: "flex", flexGrow: 1, }}>
                                                                                <Typography variant="body2" component="div" >{refund?.note || "-"}</Typography>
                                                                            </Box>
                                                                        </Box>
                                                                        {/* <Divider sx={{ my: 2 }} /> */}
                                                                        {refund?.refund_line_items && refund.refund_line_items.length > 0 &&
                                                                            <Box className="ordertabular" sx={{ display: "flex", mt: '5px' }}>
                                                                                <Box className="ordertabular__title" >
                                                                                    <Typography variant="subtitle2" component="div" >Product:</Typography>
                                                                                </Box>
                                                                                <Box className="ordertabular__cntnt" sx={{ flexGrow: 1, maxWidth: "200px" }}>
                                                                                    {refund?.refund_line_items.map((opt, ind) => {
                                                                                        let product = orderData.line_items.filter((x) => x?.LineItem_id == opt?.order_line_id)
                                                                                        return (
                                                                                            <Box key={ind} sx={{ width: "100%", mb: 1 }}>
                                                                                                <Typography variant="subtitle2" component="div">{product && product[0]?.title}</Typography>
                                                                                                <Typography variant="caption" component="div">{product && product[0]?.name}</Typography>
                                                                                                <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                                                                                    {/* <Typography variant="body2" component="div">{product && product[0]?.quantity && `${opt?.quantity} out of ${product[0]?.quantity}`}</Typography>
                                                                                                                <Typography variant="body2" component="div">
                                                                                                                    <CurrencyFormat value={parseFloat(product[0]?.price || 0).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={config.CURRENCY} />
                                                                                                                </Typography> */}
                                                                                                    {product && product.length > 0 && product[0]?.quantity && product[0]?.price &&
                                                                                                        <>
                                                                                                            <Typography variant="body2" component="div">
                                                                                                                QTY: {opt?.quantity}
                                                                                                            </Typography>
                                                                                                            {/* <Typography variant="body2" component="div">
                                                                                                                            {opt?.quantity} x <CurrencyFormat value={parseFloat(product[0]?.price || 0).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={config.CURRENCY} />
                                                                                                                        </Typography>
                                                                                                                        <Typography variant="subtitle2" component="div">
                                                                                                                            <CurrencyFormat value={parseFloat(product[0]?.price * opt?.quantity || 0).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={config.CURRENCY} />
                                                                                                                        </Typography> */}
                                                                                                        </>
                                                                                                    }
                                                                                                </Box>
                                                                                            </Box>
                                                                                        )
                                                                                    })}
                                                                                </Box>
                                                                            </Box>
                                                                        }
                                                                    </Box>
                                                                    {/* </Grid>
                                                            <Grid item md={6}> */}


                                                                </Grid>
                                                            </Grid>
                                                        )
                                                    })}
                                                </Box>
                                            </Box>
                                        }



                                        {/* <Divider sx={{ mb: 1 }} />
                                                    <Box className="ordertabular" sx={{ display: "flex", }}>
                                                        <Box className="ordertabular__title" >
                                                            <Typography variant="subtitle2" component="div" sx={{ ml: 1 }}>Paid by customer </Typography>
                                                        </Box>
                                                        <Box className="ordertabular__cntnt" sx={{ display: "flex", justifyContent: "space-between", flexGrow: 1, }}>
                                                            <Box> <Typography variant="body2" component="div" sx={{ ml: 1 }}> </Typography> </Box>
                                                            <Box> <Typography variant="body2" component="div" sx={{ ml: 1 }}> ₹10.50</Typography> </Box>
                                                        </Box>
                                                    </Box> */}

                                        {orderData?.ﬁnancial_status == "paid" &&
                                            <Box sx={{ pt: 1 }}>
                                                <Divider sx={{ mb: 1 }} />

                                                <Box sx={{ pt: 2 }}>
                                                    <Typography variant="p" component="strong" gutterBottom >Payment summary</Typography>
                                                </Box>
                                                <Grid container spacing={2} columns={12} sx={{ mb: 2 }}>
                                                    <Grid item md={7}>
                                                        {orderData?.OrderPayment?.payment_id &&
                                                            <Box className="ordertabular" sx={{ display: "flex", mt: 1 }}>
                                                                <Box className="ordertabular__title" >
                                                                    <Typography variant="subtitle2" component="div" >Payment ID</Typography>
                                                                </Box>
                                                                <Box className="ordertabular__cntnt" sx={{ display: "flex", flexGrow: 1, }}>
                                                                    <Typography variant="subtitle2" component="div" >{orderData?.OrderPayment?.payment_id} </Typography>
                                                                </Box>
                                                            </Box>
                                                        }
                                                        {orderData?.OrderPayment?.payment_data?.id || orderData?.OrderPayment?.payment_data?.mihpayid ?
                                                            <Box className="ordertabular" sx={{ display: "flex", mt: 1 }}>
                                                                <Box className="ordertabular__title" >
                                                                    <Typography variant="subtitle2" component="div" >Payment ID</Typography>
                                                                </Box>
                                                                <Box className="ordertabular__cntnt" sx={{ display: "flex", flexGrow: 1, }}>
                                                                    <Typography variant="subtitle2" component="div" >{orderData?.OrderPayment?.payment_data?.id || orderData?.OrderPayment?.payment_data?.mihpayid} </Typography>
                                                                </Box>
                                                            </Box>
                                                            : null
                                                        }
                                                        {/* <Box className="ordertabular" sx={{ display: "flex", mt: 1 }}>
                                                                        <Box className="ordertabular__title" >
                                                                            <Typography variant="subtitle2" component="div" >Order ID</Typography>
                                                                        </Box>
                                                                        <Box className="ordertabular__cntnt" sx={{ display: "flex", flexGrow: 1, }}>
                                                                            <Typography variant="body2" component="div" >{orderData?.order_id} </Typography>
                                                                        </Box>
                                                                    </Box> */}
                                                        <Box className="ordertabular" sx={{ display: "flex", mt: 1 }}>
                                                            <Box className="ordertabular__title" >
                                                                <Typography variant="subtitle2" component="div" >Amount</Typography>
                                                            </Box>
                                                            <Box className="ordertabular__cntnt" sx={{ display: "flex", flexGrow: 1, }}>
                                                                <Typography variant="body2" component="div" >
                                                                    <CurrencyFormat value={parseFloat(orderData?.total_price || 0).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={config.CURRENCY} />
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                        {orderData?.payment_gateway_names &&
                                                            <Box className="ordertabular" sx={{ display: "flex", mt: 1 }}>
                                                                <Box className="ordertabular__title" >
                                                                    <Typography variant="subtitle2" component="div" >Gateway</Typography>
                                                                </Box>
                                                                <Box className="ordertabular__cntnt" sx={{ display: "flex", flexGrow: 1, }}>
                                                                    <Typography variant="body2" component="div" sx={{ textTransform: "uppercase" }}> {orderData?.payment_gateway_names == ("cod" || "COD") ? "Cash on delivery" : !_.isEmpty(paymentData) && !paymentData?.last4 ? `${orderData?.payment_gateway_names.toUpperCase() || "-"} (${orderData?.OrderPayment?.payment_data?.method || orderData?.OrderPayment?.payment_data?.mode || ""})` : orderData?.payment_gateway_names.toUpperCase() || "-"}</Typography>
                                                                </Box>
                                                            </Box>
                                                        }
                                                        <Box className="ordertabular" sx={{ display: "flex", mt: 1 }}>
                                                            <Box className="ordertabular__title" >
                                                                <Typography variant="subtitle2" component="div" >Status  </Typography>
                                                            </Box>
                                                            <Box className="ordertabular__cntnt" sx={{ display: "flex", flexGrow: 1, }}>
                                                                <Typography variant="body2" component="div" >{orderData?.ﬁnancial_status == "paid" ? "Success" : "Failed"} </Typography>
                                                            </Box>
                                                        </Box>
                                                        <Box className="ordertabular" sx={{ display: "flex", mt: 1 }}>
                                                            <Box className="ordertabular__title" >
                                                                <Typography variant="subtitle2" component="div" >From  </Typography>
                                                            </Box>
                                                            <Box className="ordertabular__cntnt" sx={{ display: "flex", flexGrow: 1, }}>
                                                                <Typography variant="body2" component="div" >{orderData?.source_name == "cart" ? "Cart" : " Draft order"} </Typography>
                                                            </Box>
                                                        </Box>
                                                        <Box className="ordertabular" sx={{ display: "flex", mt: 1 }}>
                                                            <Box className="ordertabular__title" >
                                                                <Typography variant="subtitle2" component="div" > Created </Typography>
                                                            </Box>
                                                            <Box className="ordertabular__cntnt" sx={{ display: "flex", flexGrow: 1, }}>
                                                                <Typography variant="body2" component="div" > {moment(orderData?.created_at).format("llll")}</Typography>
                                                            </Box>
                                                        </Box>
                                                    </Grid>

                                                    <Grid item md={5}>
                                                        {(!_.isEmpty(paymentData) && paymentData?.last4) || (orderData?.OrderPayment?.last4) ?
                                                            <Box sx={{ mt: 1 }}>
                                                                <Box>
                                                                    <img src={paymentData?.icon} alt="" style={{ height: "20px" }} />
                                                                </Box>
                                                                <Box className="ordertabular" sx={{ display: "flex", mt: 1 }}>
                                                                    <Box className="ordertabular__title" >
                                                                        <Typography variant="subtitle2" component="div" >xxxx xxxx xxxx {paymentData?.last4 || orderData?.OrderPayment?.last4}</Typography>
                                                                    </Box>
                                                                    {/* {paymentData?.exp_month && paymentData?.exp_year &&
                                                                                    <Box className="ordertabular__cntnt" sx={{ display: "flex", flexGrow: 1, }}>
                                                                                        <Typography variant="subtitle2" component="div" sx={{ color: "#7e7e7e" }}> Exp date: {paymentData?.exp_month}/{paymentData?.exp_year}</Typography>
                                                                                    </Box>
                                                                                } */}
                                                                </Box>
                                                            </Box>
                                                            : null
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        }


                                        {orderData?.return_exchange_orders_tracking && orderData?.return_exchange_orders_tracking.length > 0 &&
                                            <Box sx={{ pt: 1 }}>
                                                <Divider sx={{ mb: 1 }} />

                                                <Box sx={{ pt: 2 }}>
                                                    <Typography variant="p" component="strong" gutterBottom >Return/exchange tracking</Typography>
                                                </Box>
                                                <Grid container spacing={2} columns={12} sx={{ mb: 2 }}>
                                                    {orderData?.return_exchange_orders_tracking.map((opt) => {
                                                        return (
                                                            <Grid item xs={12} key={opt?.id}>
                                                                <Box className="ordertabular" sx={{ display: "flex", mt: 1 }}>
                                                                    <Box className="ordertabular__title" >
                                                                        <Typography variant="subtitle2" component="div" > Status: </Typography>
                                                                    </Box>
                                                                    <Box className="ordertabular__cntnt" sx={{ display: "flex", flexGrow: 1, }}>
                                                                        <Typography variant="subtitle2" component="div" >{opt?.status}</Typography>
                                                                    </Box>
                                                                </Box>
                                                                <Box className="ordertabular" sx={{ display: "flex", mt: 1 }}>
                                                                    <Box className="ordertabular__title" >
                                                                        <Typography variant="subtitle2" component="div" > Updated at: </Typography>
                                                                    </Box>
                                                                    <Box className="ordertabular__cntnt" sx={{ display: "flex", flexGrow: 1, }}>
                                                                        <Typography variant="body2" component="div" > {moment(opt?.updated_at).format("llll")}</Typography>
                                                                    </Box>
                                                                </Box>
                                                                {opt?.courier_name ?
                                                                    <Box className="ordertabular" sx={{ display: "flex", mt: 1 }}>
                                                                        <Box className="ordertabular__title" >
                                                                            <Typography variant="subtitle2" component="div" > Courier name: </Typography>
                                                                        </Box>
                                                                        <Box className="ordertabular__cntnt" sx={{ display: "flex", flexGrow: 1, }}>
                                                                            <Typography variant="body2" component="div" > {opt?.courier_name}</Typography>
                                                                        </Box>
                                                                    </Box>
                                                                    : null
                                                                }
                                                                {opt?.track_number ?
                                                                    <Box className="ordertabular" sx={{ display: "flex", mt: 1 }}>
                                                                        <Box className="ordertabular__title" >
                                                                            <Typography variant="subtitle2" component="div" > Tracking number: </Typography>
                                                                        </Box>
                                                                        <Box className="ordertabular__cntnt" sx={{ display: "flex", flexGrow: 1, }}>
                                                                            <Typography variant="body2" component="div" > {opt?.track_number}</Typography>
                                                                        </Box>
                                                                    </Box>
                                                                    : null
                                                                }
                                                                {opt?.tracking_url ?
                                                                    <Box className="ordertabular" sx={{ display: "flex", mt: 1 }}>
                                                                        <Box className="ordertabular__title" >
                                                                            <Typography variant="subtitle2" component="div" > Tracking URL: </Typography>
                                                                        </Box>
                                                                        <Box className="ordertabular__cntnt" sx={{ display: "flex", flexGrow: 1, }}>
                                                                            <Button variant='text' sx={{ p: 0, minWidth: "auto" }} className='noHover' component={Link} target='_blank' to={opt?.tracking_url}> {opt?.tracking_url}</Button>
                                                                        </Box>
                                                                    </Box>
                                                                    : null
                                                                }
                                                            </Grid>

                                                        )
                                                    })}

                                                </Grid>
                                            </Box>
                                        }

                                    </CardContent>
                                </Card>


                            </Grid>
                            <Grid item md={4}>
                                <Card sx={{ mb: 2 }}>
                                    <CardContent>
                                        <Grid container spacing={2} columns={12} justifyContent="space-between" alignItems="center" sx={{ mb: 1 }}>
                                            <Grid item> <Typography variant="h6" component="h6" >User</Typography></Grid>
                                        </Grid>
                                        {/* <Typography variant="subtitle1" component="div" gutterBottom >{orderData?.customer?.name}</Typography> */}
                                        <Box><Typography variant="caption2">{orderData?.customer?.name ? orderData?.customer?.name : `${orderData?.customer?.first_name} ${orderData?.customer?.last_name ? orderData?.customer?.last_name : ""}`}</Typography></Box>
                                        <Box><Typography variant="caption2">{orderData?.customer?.orders_count || 0} orders</Typography></Box>
                                    </CardContent>
                                    <Divider />
                                    <CardContent>
                                        <Grid container spacing={2} columns={12} justifyContent="space-between" alignItems="center" sx={{ mb: 1 }}>
                                            <Grid item > <Typography variant="p" component="strong">User information</Typography></Grid>
                                            <Grid item >

                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2} columns={12} justifyContent="space-between" alignItems="center"  >
                                            <Grid item > <Link to="" color="inherit">{orderData?.email} </Link></Grid>
                                            <Grid item >
                                                <IconButton aria-label="copy" sx={{ width: "35px", height: "35px" }}>
                                                    {/* <ContentCopyOutlinedIcon sx={{ width: "15px" }} /> */}
                                                    <CopyToClipboard text={orderData?.email} onCopy={() => copiedFn('email')}>
                                                        {copied.email ?
                                                            <DoneIcon sx={{ width: "15px" }} />
                                                            :
                                                            <ContentCopyOutlinedIcon sx={{ width: "15px" }} />
                                                        }
                                                    </CopyToClipboard>
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                    <Divider />
                                    <CardContent>
                                        <Grid container spacing={2} columns={12} justifyContent="space-between" alignItems="center" sx={{ mb: 1 }}>
                                            <Grid item > <Typography variant="p" component="strong">Shipping address </Typography></Grid>
                                            <Grid item >

                                            </Grid>
                                        </Grid>
                                        <Typography variant="subtitle1" component="div" > {orderData?.shipping_address?.first_name} {orderData?.shipping_address?.last_name}</Typography>
                                        {orderData?.shipping_address?.phone ?
                                            <Typography variant="subtitle1" component="div" > {orderData?.shipping_address?.phone}</Typography> : null
                                        }
                                        <Typography variant="subtitle2" component="div" sx={{ mt: 1 }}>Address:</Typography>
                                        <Typography variant="subtitle1" component="div" > {orderData?.shipping_address?.address1}</Typography>
                                        <Typography variant="subtitle1" component="div" > {orderData?.shipping_address?.address2}</Typography>
                                        <Typography variant="subtitle1" component="div" > {orderData?.shipping_address?.city} {orderData?.shipping_address?.province ? `, ${orderData?.shipping_address?.province}` : ""}</Typography>
                                        <Typography variant="subtitle1" component="div" > {orderData?.shipping_address?.country}</Typography>
                                    </CardContent>
                                    <Divider />
                                    <CardContent>
                                        <Grid container spacing={2} columns={12} justifyContent="space-between" alignItems="center" sx={{ mb: 1 }}>
                                            <Grid item > <Typography variant="p" component="strong">Billing address </Typography></Grid>
                                        </Grid>
                                        <Typography variant="subtitle1" component="div" > {orderData?.billing_address?.first_name} {orderData?.billing_address?.last_name}</Typography>
                                        {orderData?.billing_address?.phone ?
                                            <Typography variant="subtitle1" component="div" > {orderData?.billing_address?.phone}</Typography> : null
                                        }
                                        <Typography variant="subtitle2" component="div" sx={{ mt: 1 }}>Address:</Typography>
                                        <Typography variant="subtitle1" component="div" > {orderData?.billing_address?.address1}</Typography>
                                        <Typography variant="subtitle1" component="div" > {orderData?.billing_address?.address2}</Typography>
                                        <Typography variant="subtitle1" component="div" > {orderData?.billing_address?.city} {orderData?.billing_address?.province}</Typography>
                                        <Typography variant="subtitle1" component="div" > {orderData?.billing_address?.country}</Typography>
                                    </CardContent>
                                    <Divider />

                                </Card>
                            </Grid>
                        </Grid>
                    </Box>
                </Container >
            }


            <PopupModal
                open={openModal}
                ModalClose={ModalClose}
                title={modalContent.modalTitle}
                cancelbtn={true}
                cancelbtnTxt="Cancel"
                savebtn={true}
                hideFooter={false}
                savebtnTxt="Save"
                loadingBtn={loadingBtn}
                savebtnFunct={savebtnFunct}
            >
                {modalContent.type === "reject" ?
                    <FormControl fullWidth sx={{ mb: 2 }} >
                        <TextField
                            label="Reason*"
                            type="text"
                            variant="outlined"
                            size="small"
                            name="reason"
                            value={rejectReason}
                            error={errors && rejectReason == "" ? true : false}
                            helperText={errors && rejectReason == "" ? "Can't be blank" : ""}
                            onChange={handleRejectReason}
                        />
                    </FormControl> : null
                }
            </PopupModal>

            <DialogAlert
                showDialog={showDialog}
                closeDialog={closeDialog}
                content={content}
                confirmDialog={confirmDialog}
            />
        </>
    );
}

export default ExchaneOrderDetail