import React from "react";
import { FormHelperText, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import { alphabets } from "../../../../../Container/config/Apis";
import _ from "underscore"

export default function TextFieldNumber({ data, handleInputChange, main }) {
    const params = new URLSearchParams(window.location.search)
    console.log("TextFields main", main)
    return (
        <React.Fragment>
            {params.get('item') ?
                <TextField
                    label={data.label}
                    defaultValue={data.value}
                    variant="outlined"
                    fullWidth
                    type={data.type}
                    name={data.name}
                    pattern="[0-9]*"
                    onWheel={() => document.activeElement.blur()}
                    onKeyDown={(e) => (e.keyCode === 38 || e.keyCode === 40 || e.keyCode === 189 || e.code === "NumpadSubtract" || e.code === "NumpadAdd" || !(/[^0-9 .]/gi).test(e) || ["e", "E", "+", "-"].includes(e.key) || _.includes(alphabets, e.key)) && e.preventDefault()}
                    placeholder={data.placeholder}
                    sx={{ mb: 2 }}
                    onChange={handleInputChange}
                />
                :
                <>
                    <TextField
                        label={data.label}
                        defaultValue={data.value}
                        variant="outlined"
                        fullWidth
                        type={data.type}
                        name={data.name}
                        pattern="[0-9]*"
                        onWheel={() => document.activeElement.blur()}
                        onKeyDown={(e) => (e.keyCode === 38 || e.keyCode === 40 || e.keyCode === 189 || e.code === "NumpadSubtract" || e.code === "NumpadAdd" || !(/[^0-9 .]/gi).test(e) || ["e", "E", "+", "-"].includes(e.key) || _.includes(alphabets, e.key)) && e.preventDefault()}
                        placeholder={data.placeholder}
                        sx={{ mb: 2 }}
                        onChange={handleInputChange}
                    />
                    {data.name == "video_link" && main.filter((x) => x?.name == "video_type").length > 0 ?
                        <>
                            {main.filter((x) => x?.name == "video_type")[0]?.value
                                == "embedded" ?
                                <FormHelperText sx={{ mt: "-10px" }}>Enter iframe embedded code here. <a href="https://support.google.com/youtube/answer/171780?hl=en" target="_blank" style={{ color: "blue" }}>Learn more</a></FormHelperText>
                                : main.filter((x) => x?.name == "video_type")[0]?.value
                                    == "src" ? <FormHelperText sx={{ mt: "-10px" }}>Enter video format link (.mp4, .mov etc)</FormHelperText>
                                    : null
                            }
                        </>
                        : null
                    }
                </>
            }
        </React.Fragment>
    )
}