export default [
    {
        name: "Typography",
        type: "typography",
        settings: [
            {
                type: "typography"
            }
        ]
    },
    {
        name: "Colors",
        type: "colors",
        settings: [
            {
                type: "colors"
            }
        ]
    },
    {
        name: "Favicon",
        type: "favicon",
        settings: [
            {
                type: "favicon"
            }
        ]
    },
    {
        name: "Social share",
        type: "share_button",
        settings: [
            {
                type: "share_button"
            }
        ]
    },
    {
        name: "Social links",
        type: "social_button",
        settings: [
            {
                type: "social_button"
            }
        ]
    },
    {
        name: "Header",
        type: "header",
        settings: [
            {
                type: "header"
            }
        ]
    },
    {
        name: "Footer",
        type: "footer",
        settings: [
            {
                type: "footer"
            }
        ]
    },
    {
        name: "Slider",
        type: "hero_slider",
        settings: [
            {
                type: "hero_slider"
            }
        ]
    },
    {
        name: "Text over image",
        type: "text_over_image",
        settings: [
            {
                type: "text_over_image"
            }
        ]
    },
    {
        name: "Product list",
        type: "product_list",
        settings: [
            {
                type: "product_list"
            }
        ]
    },
    {
        name: "category list",
        type: "category_list",
        settings: [
            {
                type: "category_list"
            }
        ]
    },
    {
        name: "Text column with image",
        type: "text_column_with_image",
        settings: [
            {
                type: "text_column_with_image"
            }
        ]
    },
    {
        name: "Gallery",
        type: "gallery",
        settings: [
            {
                type: "gallery"
            }
        ]
    },
    {
        name: "Content block",
        type: "content_block",
        settings: [
            {
                type: "content_block"
            }
        ]
    },
    {
        name: "Brand list",
        type: "brands_list",
        settings: [
            {
                type: "brands_list"
            }
        ]
    },
    {
        name: "Map",
        type: "map",
        settings: [
            {
                type: "map"
            }
        ]
    },
    {
        name: "Heading block",
        type: "heading_block",
        settings: [
            {
                type: "heading_block"
            }
        ]
    },
    {
        name: "Testimonials",
        type: "testimonials",
        settings: [
            {
                type: "testimonials"
            }
        ]
    },
    {
        name: "Blog Post",
        type: "blog_post",
        settings: [
            {
                type: "blog_post"
            }
        ]
    },
    {
        name: "Newsletter",
        type: "newsletter",
        settings: [
            {
                type: "newsletter"
            }
        ]
    },
    {
        name: "Video",
        type: "video",
        settings: [
            {
                type: "video"
            }
        ]
    },
    {
        name: "List style",
        type: "list_style",
        settings: [
            {
                type: "list_style"
            }
        ]
    },
    {
        name: "Custom HTML",
        type: "custom_html",
        settings: [
            {
                type: "custom_html"
            }
        ]
    },
    {
        name: "Product detail",
        type: "product_detail",
        settings: [
            {
                type: "product_detail"
            }
        ]
    },
    {
        name: "Category List page",
        type: "category_list_page",
        settings: [
            {
                type: "category_list_page"
            }
        ]
    },
    {
        name: "Category page",
        type: "category_page",
        settings: [
            {
                type: "category_page"
            }
        ]
    }
]