import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, Typography, useMediaQuery } from '@mui/material';
import { LoadingButton } from "@mui/lab";

export default function DialogAlert({ showDialog, closeDialog, content, confirmDialog, smallContent, canceltext, btnLoading }) {
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    return (
        <Dialog
            fullWidth={true}
            open={showDialog}
            onClose={closeDialog}
            aria-labelledby="responsive-dialog-title"
            sx={{ textAlign: 'center', p: { md: 4, sm: 0 } }}
            maxWidth={'sm'}
        >
            <DialogContent>
                <Typography variant="h5" component="div" sx={{ mb: "15px !important", fontSize: { xs: "18px", md: "22px" } }}>
                    {content.title}
                </Typography>
                <DialogContentText>
                    <Typography variant={smallContent ? "body2" : "h6"} component={"span"} sx={{ fontSize: { xs: "14px", md: "18px", color: !smallContent ? "inherit" : prefersDarkMode ? "#fff" : "#333" } }}>
                        {content.message}
                    </Typography>
                </DialogContentText>
                <DialogActions sx={{ mt: 3, justifyContent: 'center' }}>
                    <Button color="error" variant="outlined" autoFocus onClick={closeDialog} disabled={btnLoading}>
                        {canceltext || "Cancel"}
                    </Button>
                    {/* <Button color="primary" sx={{ minWidth: "120px" }} variant="contained" onClick={confirmDialog} autoFocus>
                        Yes
                    </Button> */}
                    <LoadingButton
                        sx={{ whiteSpace: 'nowrap' }}
                        disabled={btnLoading}
                        onClick={confirmDialog}
                        loading={btnLoading}
                        loadingIndicator="Please wait..."
                        variant={"contained"}
                    >
                        Yes
                    </LoadingButton>
                </DialogActions>
            </DialogContent>
        </Dialog>
    )
}