import React, { useEffect, useState } from 'react';
import {
    Box, Button, Grid,
    Card, CardContent, Typography, Container, InputLabel, LinearProgress,
} from '@mui/material';
import { Link, useParams } from "react-router-dom";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import * as Api from '../../../../Container/config/Apis';
import config from '../../../../Container/config';
import { useSnackbar } from 'notistack';
import moment from 'moment/moment';
import { useSelector } from 'react-redux';


function createData(date, activity, unavailable, committed, available, onhand) {
    return { date, activity, unavailable, committed, available, onhand };
}


// ITEM_NVENTORY_HISTORY

export default function InventoryHistory() {
    const dataVal = useSelector((state) => state.dataValue)
    const params = useParams()
    const [loadingBar, setloadingBar] = useState(true);
    const [loading, setloading] = useState(false)
    const [area, setArea] = useState("");
    // const [open, setOpen] = useState(false);
    const [historyBox, setHistoryBox] = useState([])

    const [locationList, setlocationList] = useState([])

    const { enqueueSnackbar } = useSnackbar();
    const snack = (msg, variant) => {
        enqueueSnackbar(msg, { variant });
    }

    // const handleChange = (event) => {
    //     setArea(event.target.value);
    // };

    // const handleClose = () => {
    //     setOpen(false);
    // };

    // const handleOpen = () => {
    //     setOpen(true);
    // };


    const getInventoryHistory = () => {
        Api.GetApi(`${config.ITEM_NVENTORY_HISTORY}/${params?.id}`).then(res => {
            console.log("ITEM_NVENTORY_HISTORY", res)
            setloadingBar(false)
            if (res.error === true) {
                snack(res.response.data.error, 'error');
            } else {
                console.log("urlParams ", res.data.data)
                let resData = { ...res.data.data }
                let arr = []
                resData.inventory_adjustment_history.map((opt) => {
                    for (var x in opt) {
                        if (typeof opt[x] == "object" && opt[x]) {
                            opt[x]['diff'] = opt[x].currentData - opt[x].previousData
                        }
                    }
                    arr.push({ ...opt })
                })
                console.log("first", arr)
                resData.inventory_adjustment_history = arr
                setHistoryBox(resData)

            }
        })
    }
    const getlocationnames = (url) => {
        Api.GetApi(url).then(res => {
            setloadingBar(false)
            if (res.error === true) {
                snack(res.response.data.error, 'error');
            } else {
                console.log("urlParams ", res.data.data)
                setlocationList(res.data.data)
            }
        })
    }
    const getLocation = () => {
        const updatedArray = locationList.filter((x) => (x.id || x?.alias) === historyBox?.location_id).map((item) => {
            return item.pickup_location || item?.alias;
        })
        console.log("updatedArray: ", updatedArray)
        if (locationList.length > 0 && updatedArray) {
            setArea(updatedArray)
        }
    }

    useEffect(() => {
        getInventoryHistory()
        
        let url = ""
        if (dataVal && dataVal.store && dataVal.store?.activeShippingSource && dataVal.store?.activeShippingSource) {
            if (dataVal.store?.activeShippingSource?.shipping_source == "Goswift") {
                url = config.GOSWIFT_PICKUPLOC
            }
            else {
                url = config.SHIPROCKET_LOCATIONS
            }
            getlocationnames(url)
        }
    }, [])
    useEffect(() => {
        getLocation()
    }, [locationList])
    console.log("area: ", area)
    console.log("locationList: ", locationList)
    console.log("historyBox: ", historyBox)

    return (
        <React.Fragment>
            {loadingBar ?
                <LinearProgress color="primary" sx={{ mt: "-25px" }} />
                :
                <Container maxWidth="lg">
                    <Grid container spacing={2} sx={{ mb: 2, alignItems: "flex-end", }}>
                        <Grid item xs={6}>
                            <Button component={Link} variant="text" color="secondary" to={historyBox?.variants?.title !== "Default Title" ? `/admin${config.PRODUCTS}/${historyBox?.product?.product_id}/variant/${historyBox?.variants?.variant_id}` : `/admin${config.PRODUCTS}/${historyBox?.product?.product_id}`} startIcon={<ArrowBackIosIcon />}>
                                <Box sx={{ display: "flex", flexDirection: "column" }}>
                                    <Typography variant="subtitle2" component="div" sx={{ lineHeight: "normal" }}>{historyBox?.product?.title} </Typography>
                                    {historyBox?.variants?.title !== "Default Title" ?
                                        <Typography variant="body2" component="div">{historyBox?.variants?.title}</Typography>
                                        : null
                                    }
                                </Box>
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Box className='cstmlocationDropdown' sx={{ display: "flex", alignItems: "flex-end", justifyContent: "flex-end" }}>
                                {area ?
                                    <>
                                        <FmdGoodIcon color='fff' />
                                        <Typography variant="body1" component="div">{area}</Typography>
                                    </>
                                    : null
                                }
                                {/* <FormControl sx={{ m: 1, minWidth: 120 }}>
                                    <InputLabel id="demo-controlled-open-select-label" size='small'> </InputLabel>
                                    <Select
                                        labelId="demo-controlled-open-select-label"
                                        id="demo-controlled-open-select"
                                        open={open}
                                        onClose={handleClose}
                                        onOpen={handleOpen}
                                        value={area}
                                        onChange={handleChange}
                                        defaultValue={area}
                                        size='small'
                                    >
                                        <MenuItem value="chandigarh">Chandigarh</MenuItem>
                                        <MenuItem value="ludhiana">Ludhiana</MenuItem>
                                        <MenuItem value="amritsar">Amritsar</MenuItem>
                                    </Select>
                                </FormControl> */}
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={12}>
                            <Card sx={{ mb: 2 }}>
                                <CardContent>
                                    <Typography variant="h6" component="h6" gutterBottom>Inventory logs</Typography>


                                    {historyBox?.inventory_adjustment_history && historyBox?.inventory_adjustment_history.length > 0 ? <>
                                        <TableContainer component={Paper}>
                                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Modified by</TableCell>
                                                        <TableCell >Reason</TableCell>
                                                        <TableCell sx={{ textAlign: "end" }}>Not in stock</TableCell>
                                                        <TableCell sx={{ textAlign: "end" }}>Allocated</TableCell>
                                                        <TableCell sx={{ textAlign: "end" }}>Accessible</TableCell>
                                                        <TableCell sx={{ textAlign: "end" }}>In stock</TableCell>

                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {historyBox?.inventory_adjustment_history.map((data, index) => {
                                                        return (
                                                            <TableRow key={index}
                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                            >
                                                                <TableCell component="th" scope="row">
                                                                    <Typography variant="p" component="strong">  {data?.modified_by} </Typography>
                                                                    <Box>{moment(data?.updatedAt).format('llll')}</Box>
                                                                </TableCell>
                                                                <TableCell >{data?.reason ? data?.reason : "-"}</TableCell>
                                                                <TableCell sx={{ textAlign: "end" }}>
                                                                    <Typography variant="button" component="div">{data?.unavailable?.currentData}</Typography>
                                                                    <Typography variant="caption" component="div">({data?.unavailable?.diff > 0 ? "+" : ""}{data?.unavailable?.diff})</Typography>
                                                                </TableCell>
                                                                <TableCell sx={{ textAlign: "end" }}>
                                                                    <Typography variant="button" component="div">{data?.commited?.currentData}</Typography>
                                                                    <Typography variant="caption" component="div">({data?.commited?.diff > 0 ? "+" : ""}{data?.commited?.diff})</Typography>
                                                                </TableCell>
                                                                <TableCell sx={{ textAlign: "end" }}>
                                                                    <Typography variant="button" component="div">{data?.available?.currentData}</Typography>
                                                                    <Typography variant="caption" component="div">({data?.available?.diff > 0 ? "+" : ""}{data?.available?.diff})</Typography>
                                                                </TableCell>
                                                                <TableCell sx={{ textAlign: "end" }}>
                                                                    <Typography variant="button" component="div">{data?.on_hand?.currentData}</Typography>
                                                                    <Typography variant="caption" component="div">({data?.on_hand?.diff > 0 ? "+" : ""}{data?.on_hand?.diff})</Typography>
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    })}

                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </>
                                        : <>
                                            <Box sx={{ textAlign: "center", py: 3 }}>
                                                <Typography variant="h5" component="div" sx={{ mt: 1 }}>No result found!</Typography>
                                            </Box>
                                        </>}



                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>

                </Container>
            }
        </React.Fragment>
    )
}
