import React, { useEffect, useRef, useState } from 'react'
import { Accordion, AccordionSummary, Alert, Avatar, Badge, Box, Button, Card, CardActions, CardContent, CardHeader, CardMedia, Chip, Container, Divider, FormControlLabel, FormGroup, Grid, IconButton, LinearProgress, ListItemIcon, ListItemText, Menu, MenuItem, Pagination, Popover, Skeleton, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, useMediaQuery } from '@mui/material'
import moment from 'moment';
import FacebookIcon from '@mui/icons-material/Facebook';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import fb from "../../../../Assets/images/social/fb_social.svg";
import instagram from "../../../../Assets/images/social/instagram_social.svg";
import x_social from "../../../../Assets/images/social/x_social.svg";
import yt from "../../../../Assets/images/social/yt_social.svg";
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { InstagramLogin } from '@amraneze/react-instagram-login';
import FacebookLogin from 'react-facebook-login'
import PopupModal from '../../../common/PopupModal/PopupModal';
import { useSnackbar } from 'notistack';
import * as Api from "../../../../Container/config/Apis";
import config from "../../../../Container/config";

// import TwitterLogin from "react-twitter-login";
import DialogAlert from '../../../common/Alerts/Dialog';
import { styled } from '@mui/material/styles';
import { EmojiEmotionsOutlined, HighlightOff, PlayArrow, RemoveCircle } from '@material-ui/icons';
import { Image } from '@mui/icons-material';
import { PlayCircle } from '@mui/icons-material';
import NoResult from '../../../common/NoResult';
import pageIcon from '../../../../Assets/images/no-result/pages.svg';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const emojis = [
    '😀', '😁', '😂', '😃', '😉', '😋', '😎', '😍', '😗', '🤗', '🤔', '😣', '😫', '😴', '😌', '🤓',
    '😛', '😜', '😠', '😇', '😷', '😈', '👻', '😺', '😸', '😹', '😻', '😼', '😽', '🙀', '🙈',
    '🙉', '🙊', '👼', '👮', '🕵', '💂', '👳', '🎅', '👸', '👰', '👲', '🙍', '🙇', '🚶', '🏃', '💃',
    '⛷', '🏂', '🏌', '🏄', '🚣', '🏊', '⛹', '🏋', '🚴', '👫', '💪', '👈', '👉', '👉', '👆', '🖕',
    '👇', '🖖', '🤘', '🖐', '👌', '👍', '👎', '✊', '👊', '👏', '🙌', '🙏', '🐵', '🐶', '🐇', '🐥',
    '🐸', '🐌', '🐛', '🐜', '🐝', '🍉', '🍄', '🍔', '🍤', '🍨', '🍪', '🎂', '🍰', '🍾', '🍷', '🍸',
    '🍺', '🌍', '🚑', '⏰', '🌙', '🌝', '🌞', '⭐', '🌟', '🌠', '🌨', '🌩', '⛄', '🔥', '🎄', '🎈',
    '🎉', '🎊', '🎁', '🎗', '🏀', '🏈', '🎲', '🔇', '🔈', '📣', '🔔', '🎵', '🎷', '💰', '🖊', '📅',
    '✅', '❎', '💯',
]

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: '#44b700',
        color: '#44b700',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: 'ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        },
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
        },
    },
}));
const CustomCard = styled(CardHeader)(({ theme }) => ({
    alignItems: "flex-start",
    '& .MuiCardHeader-action': {
        width: "60%"
    }
}));
const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 22,
    height: 22,
    border: `2px solid ${theme.palette.background.paper}`,
    backgroundColor: "#ddd",
    padding: "4px"
}));
const BigAvatar = styled(Avatar)(({ theme }) => ({
    width: 30,
    height: 30,
    backgroundColor: "#aaa",
}));
const BigAvatarLG = styled(Avatar)(({ theme }) => ({
    width: 80,
    height: 80,
    backgroundColor: "#aaa",
    borderRadius: "10px",
    '& img': {
        objectFit: "cover !important"
    }
}));

export default function Marketing() {
    const { enqueueSnackbar } = useSnackbar();
    const snack = (msg, variant) => {
        enqueueSnackbar(msg, { variant });
    }
    let navigate = useNavigate()
    const location = useLocation()
    const searchParams = new URLSearchParams(window.location.search)
    const [loadingBar, setloadingBar] = React.useState(true);
    const [loadingBtn, setloadingBtn] = React.useState(false);
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const [showList, setshowList] = useState(true)
    const [openModal, setopenModal] = useState(false)
    const [activelist, setActivelist] = useState({})
    const [preview, setPreview] = useState([])
    const [selectedFile, setSelectedFile] = useState([])
    const [selectedVideoFile, setSelectedVideoFile] = useState(null)
    const [previewVideo, setPreviewVideo] = useState(null)
    const [params, setParams] = useState({
        media_source_name: "",
        sort_key: "",
        sort_value: "desc",
        page: 1
    })
    const [openId, setopenId] = useState({
        open: false,
    })
    const [value, setValue] = useState({})
    const [title, setTitle] = useState("")
    const [openIndex, setopenIndex] = useState("")
    const [anchorEl, setAnchorEl] = React.useState({
        more: null,
        emoji: null,
        manage: null,
    });
    const open = Boolean(anchorEl.more);
    const open2 = Boolean(anchorEl.manage);
    const inputRef = useRef([]);
    const [list, setlist] = useState([])
    const [token_secret, setToken_secret] = useState("")
    const [user, setUser] = useState(null)
    const [posts, setPosts] = useState([])

    const [count, setCount] = useState(1)
    const [totalOrders, setTotalOrders] = useState(0)
    const [limit, setLimit] = useState(process.env.REACT_APP_LIMIT || 20)

    const [popupClosed, setPopupClosed] = useState(false);
    const [progressRes, setProgressRes] = useState([])
    const [showDialog, setshowDialog] = React.useState(false);
    const [content, setContent] = React.useState({
        title: "Are you sure?",
        message: "",
        data: "",
        type: ""
    });
    const [showError, setshowError] = useState(false)
    
    const ModalClose = () => {
        setopenModal(false)
        setActivelist("")
        setopenIndex("")
        setSelectedFile([])
        setPreview([])
        setSelectedVideoFile(null)
        setPreviewVideo(null)
        setValue({})
        handleClose()
        setloadingBtn(false)
        setshowList(true)
        getMediaList()
        getMediaPosts(location.search)
    }
    const [socialData, setsocialData] = useState([
        {
            id: "001",
            image: "https://static.wixstatic.com/media/191602_0bbce4b8fba4497a84c150fd8121a1c6~mv2.png",
            content: "Lorem ipsum dolor sit amet.",
            by: "Camilyo kumar",
            date: new Date(),
            is_publish: true,
            platform: "fb"
        }
    ])
    const responseInstagram = (response) => {
        console.log(response);
    };


    const handleClick = (event, data, i) => {
        setAnchorEl({ ...anchorEl, [data]: event.currentTarget });
        setopenIndex(i)
    };
    const handleClick2 = (event, data, i) => {
        setAnchorEl({ ...anchorEl, [data]: event.currentTarget });
        setopenId({
            ...openId,
            open: true,
            index: i
        })
    };
    const handleClose = () => {
        setopenId({
            open: false,
            index: ""
        })
        setAnchorEl({
            more: null,
            emoji: null,
            manage: null,
        });
        setopenIndex("")
    };


    const authHandler = (err, data) => {
        console.log(err, data);
    };

    const responseFacebook = (response) => {
        setUser(response)
    }
    // const createPost = (data) => {
    //     console.log("createPost", data)
    //     setActivelist(data)
    //     setTitle(`Create and publish post on ${data?.media_source_name || "social media"}`)
    //     setopenModal(true)
    // }
    const createPost = () => {
        setTitle('Create and publish post on social media.')
        setopenModal(true)
    }
    const getMediaList = () => {
        Api.GetApi(config.SOCIAL_MEDIA).then(res => {
            setloadingBar(false)
            console.log("res SOCIAL_MEDIA: ", res)
            if (res.error === true) {
                snack(res.response.data.error, 'error');
            } else {
                let arr = []
                res.data.data.filter((x) => x?.social_media_id > 2).map((opt) => {
                    arr.push({ ...opt, checked: opt.is_connected ? true : false })
                })
                setlist(arr)
            }
        })
    }
    const getMediaPosts = (param) => {
        Api.GetApi(`${config.SOCIAL_MEDIA}/posts${param}`).then(res => {
            setloadingBar(false)
            setloadingBtn(false)
            console.log("res SOCIAL_MEDIA: ", res)
            if (res.error === true) {
                snack(res.response.data.error, 'error');
            } else {
                setPosts(res.data.data.rows)
                setProgressRes([])
                setTotalOrders(res.data.data?.count)
                let newCount = Math.ceil(parseInt(res.data.data?.count) / limit)
                if (newCount !== 0) {
                    setCount(newCount)
                } else {
                    setCount(1)
                }
            }
        })
    }
    const getTwitterLink = () => {
        Api.GetApi(config.TWITTER_AUTH_ILNK).then(res => {
            console.log("res TWITTER_AUTH_ILNK: ", res)
            if (res.error === true) {
                snack(res.response.data.error, 'error');
            } else {
                // http://192.168.0.23:8006/
                // const popup = window.open("http://netest.localhost:3001/admin/settings/marketing?oauth_token=111&oauth_verifier=876", 'example', 'width=300,height=300')
                const popup = window.open(res.data.data?.url, 'example', 'width=500,height=300')
                // localStorage["oauth_token_secret"] = res.data.data?.oauth_token_secret
                const checkPopupClosed = setInterval(() => {
                    if (popup.closed) {
                        clearInterval(checkPopupClosed);
                        setPopupClosed(true);
                        console.log('Popup closed');
                    }
                }, 500); // Check every 500ms if the popup is closed
            }
        })
    }
    const getYoutubeLink = () => {
        Api.GetApi(config.YOUTUBE_AUTH_ILNK).then(res => {
            console.log("res YOUTUBE_AUTH_ILNK: ", res)
            if (res.error === true) {
                snack(res.response.data.error, 'error');
            } else {
                const popup = window.open(res.data.data, 'youtube', 'width=500,height=300')
                const checkPopupClosed = setInterval(() => {
                    if (popup.closed) {
                        clearInterval(checkPopupClosed);
                        setPopupClosed(true);
                        console.log('Popup closed');
                    }
                }, 500); // Check every 500ms if the popup is closed
            }
        })
    }
    const deleteConnect = (id) => {
        Api.DeleteApi(`${config.SOCIAL_MEDIA}/disconnect/${id}`).then(res => {
            console.log("res delete SOCIAL_MEDIA: ", res)
            if (res.error === true) {
                snack(res.response.data.error, 'error');
            } else {
                getMediaList()
                closeDialog()
                ModalClose()
            }
        })
    }
    const deletePostFn = (url, id) => {
        Api.DeleteApi(`${config.SOCIAL_MEDIA}/${url}/${id}`).then(res => {
            console.log("res delete post: ", res)
            if (res.error === true) {
                snack(res.response.data.error, 'error');
            } else {
                getMediaPosts(location.search)
                closeDialog()
                ModalClose()
            }
        })
    }

    const loginTwitter = (url, data) => {
        setloadingBar(true)
        Api.PostApi(url, data).then(res => {
            console.log("res twitter: ", res)
            if (res.error === true) {
                snack(res.response.data.error, 'error');
                setTimeout(() => {
                    window.close()
                }, 2000);
            } else {
                window.close()
            }
        })
    }
    const publishPostFn = (api, url, data, obj) => {
        setloadingBtn(true)
        api(url, data).then(res => {
            console.log("res tweet: ", res)
            // setloadingBtn(false)
            let arr = []
            if (res.error) {
                snack(res.response.data.error, 'error');
                // progress.map((opt) => {
                //     if (opt.social_media_id == obj.social_media_id) {
                //     }
                // })
                progressRes.push({ ...obj, error: true, loading: false, errorMsg: res.response.data.error })
                setProgressRes([...new Set(progressRes)]);
            } else {
                // snack(res.data.data, 'success');
                if (res.data.data) {
                    // progress.map((opt) => {
                    //     if (opt.social_media_id == obj.social_media_id) {
                    //         arr.push({ ...opt, loading: false, error: false })
                    //     }
                    // })
                    progressRes.push({ ...obj, loading: false, error: false, data: res.data.data })
                    setProgressRes([...new Set(progressRes)]);
                }
            }
            // setProgressRes(arr)
        })
    }

    useEffect(() => {
        if (progressRes.length == list.filter((x) => x.checked).length) {
            setloadingBtn(false)
        }
    }, [progressRes])
    const connect = (data) => {
        if (data?.social_media_id == 3) {
            getTwitterLink()
        }
        if (data?.social_media_id == 4) {
            getYoutubeLink()
        }
    }

    const disconnect = (data) => {
        setshowDialog(true)
        setContent({ ...content, 'message': `You want to disconnect ${data?.media_source_name}.`, data: data, type: "disconnect" })
    }
    const confirmDialog = () => {
        if (content.type == "disconnect") {
            if (content.data.social_media_id) {
                deleteConnect(content.data.social_media_id)
            }
        } else {
            if (content.data.post_id) {
                let url = ""
                if (content.data.media_source_name == "Youtube") {
                    url = "youtube/delete"
                } else {
                    url = "twitter/delete"
                }
                deletePostFn(url, content.data.post_id)
            }
        }
    }
    const closeDialog = () => {
        setshowDialog(false);
        setContent({
            title: "Are you sure?",
            message: "",
            data: "",
            type: ""
        })
    }

    const handleChange = (e, data) => {
        setValue({
            ...value,
            [data.social_media_id]: {
                ...value[data.social_media_id],
                [e.target.name]: e.target.value
            }
        })
    }
    console.log("value:", value)

    const handleFocus = (e, i) => {
        if (inputRef.current[i]) {
            const input = inputRef.current[i];
            const length = input.value.length;
            input.setSelectionRange(length, length);
        }
    };

    const clickemoji = (emo, data, i) => {
        console.log("inputRef", i)
        if (inputRef.current[i]) {
            const cursor = inputRef.current[i].selectionEnd;
            let text = value[data.social_media_id].caption
            if (text) {
                text = value[data.social_media_id].caption.slice(0, cursor) + emo + value[data.social_media_id].caption.slice(cursor);
            } else {
                text = emo
            }
            // setValue({ ...value, caption: text })
            setValue({
                ...value,
                [data.social_media_id]: {
                    ...value[data.social_media_id],
                    caption: text
                }
            })
            handleClose()
            setTimeout(() => {
                inputRef.current[i].focus()
            }, 100);
        }
    };

    const onSelectFile = (e) => {
        e.preventDefault()
        const fileobj = [];
        let files = Array.from(e.target.files);
        console.log("files: ", files)
        fileobj.push(files);
        let reader;
        let selectedFiles = [...selectedFile]
        for (var i = 0; i < fileobj[0].length; i++) {
            if (files[i] && files[i].size <= 20000000) {
                selectedFiles.push(files[i])
                setSelectedFile(selectedFiles)
                reader = new FileReader();
                reader.readAsDataURL(fileobj[0][i]);
                let url = URL.createObjectURL(files[i])
                reader.onload = event => {
                    preview.push(event.target.result.startsWith("data:video") ? url : event.target.result);
                    setPreview([...new Set(preview)]);
                }
            }
        }
    }
    const onSelectVideoFile = (e) => {
        e.preventDefault()
        let reader = new FileReader();
        let file = e.target.files[0];
        let url = URL.createObjectURL(e.target.files[0])
        if (file && file.size <= 20000000) {
            setSelectedVideoFile(file)
            reader.onloadend = () => {
                setPreviewVideo(url)
            }
            reader.readAsDataURL(file)
            e.target.value = ""
        }
    }

    const deleteimage = (i) => {
        let selectedFiles = [...selectedFile]
        selectedFiles.splice(i, 1)
        preview.splice(i, 1)
        setPreview([...new Set(preview)]);
        setSelectedFile(selectedFiles)
    }
    const deleteVideo = () => {
        setPreviewVideo(null);
        setSelectedVideoFile(null)
    }

    const publishPost = () => {
        let data = new FormData()
        if (activelist.media_source_name == "Youtube") {
            if (selectedVideoFile) {
                data.append('video', selectedVideoFile);
            }
            data.append("title", value.title)
            data.append("description", value.caption)
            publishPostFn(Api.PostApiFormdata, config.YOUTUBE_UPLOAD, data)
        } else {
            if (selectedFile.length > 0) {
                for (var i = 0; i < selectedFile.length; i++) {
                    data.append('tweet_images', selectedFile[i]);
                }
            }
            data.append("tweetText", value.caption)
            publishPostFn(Api.PostApiFormdata, config.TWITTER_TWEET, data)
        }
    }

    //Set/Delete param
    const setparam = (key, val) => {
        searchParams.set(key, val)
        const to = { pathname: location.pathname, search: searchParams.toString() };
        navigate(to, { replace: true });
    }
    const deleteparam = (key) => {
        searchParams.delete(key)
        const to = { pathname: location.pathname, search: searchParams.toString() };
        navigate(to, { replace: true });
    }

    const handleChangePage = (_event, newPage) => {
        setloadingBar(true)
        console.log("newPage: ", newPage)
        setParams({ ...params, "page": newPage })
        setparam("page", newPage)
    };

    const deletePost = (data) => {
        setshowDialog(true)
        setContent({ ...content, 'message': `You want to delete this post.`, data: data, type: "delete" })
    }


    const savebtnFunct = async () => {
        if (showList) {
            let arr = []
            list.map((opt, i) => {
                if (opt.checked && opt.is_connected) {
                    if (!value[opt.social_media_id]?.caption || (opt.media_source_name == "Youtube" && (!value[opt.social_media_id]?.title || !previewVideo))) {
                        setshowList(true)
                        setshowError(true)
                        arr.push({ ...opt })
                    } else {
                        setshowError(false)

                        if (opt.media_source_name == "Youtube") {
                            let data = new FormData()
                            if (selectedVideoFile) {
                                data.append('video', selectedVideoFile);
                            }
                            data.append("title", value[opt.social_media_id]?.title)
                            data.append("description", value[opt.social_media_id]?.caption)
                            publishPostFn(Api.PostApiFormdata, config.YOUTUBE_UPLOAD, data, opt)
                        } else {
                            let data = new FormData()
                            if (selectedFile.length > 0) {
                                for (var i = 0; i < selectedFile.length; i++) {
                                    data.append('tweet_images', selectedFile[i]);
                                }
                            }
                            data.append("tweetText", value[opt.social_media_id]?.caption)
                            publishPostFn(Api.PostApiFormdata, config.TWITTER_TWEET, data, opt)
                        }
                        arr.push({ ...opt, loading: true })
                        setshowList(false)
                    }
                }
                else {
                    arr.push({ ...opt })
                }
            })
            setlist(arr)
        } else {
            ModalClose()
            handleClose()
            getMediaPosts(location.search)
        }
    }

    const handleSwitch = (e, data) => {
        let arr = []
        list.map((opt) => {
            if (opt.is_connected && data.social_media_id == opt.social_media_id) {
                arr.push({ ...opt, checked: e.target.checked })
            } else {
                arr.push({ ...opt })
            }
        })
        setlist(arr)
    }

    useEffect(() => {
        if (popupClosed) {
            console.log('Popup has been closed');
            getMediaList()
            // Add your event logic here
        }
    }, [popupClosed]);

    useEffect(() => {
        getMediaList()
        if (searchParams.get("oauth_token") && searchParams.get("oauth_verifier")) {
            let data = {
                "oauth_token": searchParams.get("oauth_token"),
                "oauth_verifier": searchParams.get("oauth_verifier")
            }
            loginTwitter(config.TWITTER_LOGIN, data)
        }
        if (searchParams.get("code")) {
            let data = {
                "code": searchParams.get("code")
            }
            loginTwitter(config.YOUTUBE_LOGIN, data)
        }
    }, [])
    useEffect(() => {
        if (!searchParams.get("code") && !searchParams.get("oauth_token") && !searchParams.get("oauth_verifier")) {
            getMediaPosts(location.search)
        }
    }, [params])

    console.log("text value: ", value)
    console.log("activelist: ", activelist)
    console.log("posts: ", posts)
    console.log("preview: ", preview)
    console.log("list: ", list)
    console.log("progressRes: ", progressRes)
    console.log("location.search: ", location.search)


    return (
        <>
            {loadingBar ?
                <LinearProgress className="top_loader" color="info" sx={{ mt: "-25px", mb: "21px" }} />
                :
                <Container maxWidth="lg">
                    <Box sx={{ mb: 2 }}>
                        <Grid container spacing={2} columns={12}>
                            <Grid item xs={8}>
                                <Typography variant="h5" component="div"> Social media marketing</Typography>
                            </Grid>
                            <Grid item xs={4} sx={{ textAlign: { sm: 'right' }, flexShrink: { sm: 0 } }}>
                                {list.filter((x) => x?.is_connected).length ?
                                    <Button variant="contained" onClick={createPost}>Create new post</Button>
                                    : null
                                }
                            </Grid>
                        </Grid>
                    </Box>
                    <Card sx={{ mb: 2 }}>
                        <CardContent>
                            <Box sx={{ mb: 2 }}>
                                {/* <Typography variant="h6" component="div" sx={{ mb: 3 }}> Connect and manage social accounts</Typography> */}
                                <Grid container spacing={5} columns={12}>
                                    {list.map((opt, i) => {
                                        return (
                                            <Grid item key={i} sx={{ maxWidth: 200 }}>
                                                <Box sx={{ textAlign: "center" }}>
                                                    <Box sx={{ filter: prefersDarkMode && opt.media_source_name !== "Youtube" ? "invert(1)" : "none" }}>
                                                        {!opt.is_connected ?
                                                            <img src={opt.logo} alt='' height={30} />
                                                            :
                                                            <Badge
                                                                overlap="circular"
                                                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                                badgeContent={
                                                                    <SmallAvatar alt="" src={opt.logo} />
                                                                }
                                                            >
                                                                <BigAvatar alt="" src={opt?.account_details?.profile_image_url || opt?.account_details?.picture || ""} />
                                                            </Badge>
                                                        }
                                                    </Box>
                                                    <Typography variant="p" component="div" sx={{ mt: 1 }}>{opt.account_details?.name || opt.media_source_name}</Typography>
                                                    {opt.is_connected ?
                                                        <>
                                                            <Button
                                                                id="manage"
                                                                aria-controls={open ? "basic-manage" : undefined}
                                                                aria-haspopup="true"
                                                                aria-expanded={open ? "true" : undefined}
                                                                onClick={(e) => handleClick(e, "manage", i)}
                                                                color="warning"
                                                            >
                                                                Manage
                                                            </Button>
                                                            <Menu
                                                                id="basic-manage"
                                                                anchorEl={anchorEl.manage}
                                                                open={open2 && openIndex == i}
                                                                onClose={handleClose}
                                                                MenuListProps={{
                                                                    "aria-labelledby": "manage"
                                                                }} >
                                                                {/* <MenuItem onClick={() => createPost(opt)}>Create post
                                                                </MenuItem> */}
                                                                <MenuItem sx={{ color: 'red' }} onClick={() => disconnect(opt)}>Disconnect
                                                                </MenuItem>
                                                            </Menu>
                                                        </>
                                                        :
                                                        <Button className='noHover' variant='text' onClick={() => connect(opt)}>Connect</Button>
                                                    }
                                                </Box>
                                            </Grid>
                                        )
                                    })}
                                    {/* <Grid item>
                                <Box sx={{ textAlign: "center" }}>
                                    <img src={fb} alt='facebook' width={40} />
                                    <Typography variant="p" component="div" sx={{ mt: 1 }}>Facebook</Typography>
                                    <Box className='instagram_button'>
                                        <FacebookLogin
                                            // appId={"2186023731771912"}
                                            appId={"7597212230344086"}
                                            autoLoad={false}
                                            fields="name, email, picture"
                                            scope="public_profile,email"
                                            callback={responseFacebook}
                                            textButton="Connect"
                                            cssClass="connect_btn"
                                        />
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item>
                                <Box sx={{ textAlign: "center" }}>
                                    <img src={instagram} alt='Instagram' width={40} />
                                    <Typography variant="p" component="div" sx={{ mt: 1 }}>Instagram</Typography>
                                    <Box className='instagram_button'>
                                        <InstagramLogin
                                            clientId="316867874774971"
                                            onSuccess={responseInstagram}
                                            onFailure={responseInstagram}
                                            buttonTheme="simple"
                                        >
                                            <Button className='noHover' variant='text'>Connect</Button>
                                        </InstagramLogin>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item>
                                <Box sx={{ textAlign: "center" }}>
                                    <img src={x_social} alt='Twitter' width={40} />
                                    <Typography variant="p" component="div" sx={{ mt: 1 }}>X (Twitter)</Typography>
                                    <Button className='noHover' variant='text' onClick={connectTwitter}>Connect</Button>
                                </Box>
                            </Grid>
                            <Grid item>
                                <Box sx={{ textAlign: "center" }}>
                                    <img src={yt} alt='Youtube' width={40} />
                                    <Typography variant="p" component="div" sx={{ mt: 1 }}>Youtube</Typography>
                                    <Button className='noHover' variant='text' onClick={connectYoutube}>Connect</Button>
                                </Box>
                            </Grid> */}
                                </Grid>
                            </Box>
                        </CardContent>
                    </Card>
                    <Card sx={{ mb: 2 }}>
                        <Box sx={{ p: 2 }}>
                            <Typography variant="h6" component="div" sx={{ mb: 0 }}> Social media activities</Typography>
                        </Box>
                        {/* <MediaPosts data={posts} deletePost={deletePost} /> */}
                        <Box sx={{ width: '100%', typography: 'body1' }}>
                            {posts.length ?
                                <TableContainer>
                                    <Table stickyHeader>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ backgroundColor: prefersDarkMode ? "#121212" : "#f1f1f1" }}>
                                                    Content
                                                </TableCell>
                                                <TableCell style={{ backgroundColor: prefersDarkMode ? "#121212" : "#f1f1f1" }}>
                                                    Status
                                                </TableCell>
                                                <TableCell style={{ backgroundColor: prefersDarkMode ? "#121212" : "#f1f1f1" }}>
                                                    Date
                                                </TableCell>
                                                <TableCell style={{ backgroundColor: prefersDarkMode ? "#121212" : "#f1f1f1" }}>

                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {posts.map((data, i) => {
                                                return (
                                                    <TableRow key={data.id}>
                                                        <TableCell size="small" sx={{ display: "flex", verticalAlign: 'top', width: "100%", minWidth: 300 }}>
                                                            <Box>
                                                                {data.media_source_name == "Youtube" ?
                                                                    <Box className='preview_social youtube post'>
                                                                        <img src={data?.post_data?.snippet?.thumbnails?.default?.url} alt="" width="80" style={{ border: prefersDarkMode ? "1px solid #212121" : "1px solid #eee", borderRadius: "3px", maxHeight: "80px", cursor: "pointer" }} />
                                                                        <PlayCircle />
                                                                    </Box>
                                                                    :
                                                                    <Box className='preview_social youtube post'>
                                                                        <Badge
                                                                            overlap="circular"
                                                                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                                            badgeContent={
                                                                                <SmallAvatar alt="" src={data?.social_media_connection?.logo} />
                                                                            }
                                                                        >
                                                                            <BigAvatar>
                                                                                <Image />
                                                                            </BigAvatar>
                                                                        </Badge>
                                                                    </Box>
                                                                }
                                                            </Box>
                                                            <Box sx={{ pl: 2 }}>
                                                                <Typography variant="body1" component="div" sx={{
                                                                    mb: 0, overflow: "hidden",
                                                                    textOverflow: "ellipsis",
                                                                    display: "-webkit-box",
                                                                    "-webkit-line-clamp": "1",
                                                                    "-webkit-box-orient": "vertical"
                                                                }}>
                                                                    {data?.post_data?.snippet?.title || data?.post_data?.text}
                                                                </Typography>
                                                                {data?.post_data?.tweetUrl ?
                                                                    <Typography variant="caption" component="div" sx={{ opacity: 0.5 }}>
                                                                        {data?.post_data?.tweetUrl}
                                                                    </Typography>
                                                                    : null
                                                                }
                                                                <Typography variant="subtitle2" component="div" sx={{ display: "flex", alignItems: "center", mt: 0.5 }}>
                                                                    {data?.social_media_connection?.media_source_name == "Youtube" ?
                                                                        <Avatar src={data?.social_media_connection?.logo} variant="square" sx={{ width: "auto", maxHeight: 10, mr: 1 }} />
                                                                        : "@"
                                                                    }
                                                                    {data?.social_media_connection?.account_details?.name}
                                                                </Typography>
                                                            </Box>
                                                        </TableCell>
                                                        <TableCell size="small" sx={{ verticalAlign: 'top', width: "200px" }}>
                                                            <Chip label={data.published ? "Published" : "Unpublished"} variant='contained' color={!data?.published ? "error" : "success"} />
                                                        </TableCell>
                                                        <TableCell size="small" sx={{ verticalAlign: 'top', width: "300px" }}>
                                                            <Typography variant="subtitle2" component="div">
                                                                {moment(data?.post_data?.snippet?.publishedAt || data.createdAt).format("lll")}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell size="small" sx={{ verticalAlign: 'top', width: "100px" }}>
                                                            <Button
                                                                id="MoreAction"
                                                                aria-controls={open ? "basic-MoreAction" : undefined}
                                                                aria-haspopup="true"
                                                                aria-expanded={open ? "true" : undefined}
                                                                onClick={(e) => handleClick(e, "more", i)}
                                                                endIcon={<MoreVertIcon />}
                                                                color="secondary"
                                                                sx={{ minWidth: "auto", pl: 0 }}
                                                            >
                                                            </Button>
                                                            <Menu
                                                                id="basic-MoreAction"
                                                                anchorEl={anchorEl.more}
                                                                open={open && openIndex == i}
                                                                onClose={handleClose}
                                                                MenuListProps={{
                                                                    "aria-labelledby": "MoreAction"
                                                                }} >
                                                                {data?.post_data?.youtubeVideoUrl || data?.post_data?.tweetUrl ?
                                                                    <MenuItem sx={{ p: 0 }}>
                                                                        <Button color='primary' sx={{ minWidth: "100px", px: 2, justifyContent: "flex-start" }} component={Link} to={data?.post_data?.youtubeVideoUrl || data?.post_data?.tweetUrl} target='_blank'>View post</Button>
                                                                    </MenuItem>
                                                                    : null
                                                                }
                                                                <MenuItem sx={{ p: 0 }}>
                                                                    <Button color='error' sx={{ minWidth: "100px", px: 2, justifyContent: "flex-start" }} onClick={() => deletePost(data)}>Delete</Button>
                                                                </MenuItem>
                                                            </Menu>
                                                        </TableCell>

                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                :
                                <>
                                    <Box sx={{ mt: 0, mb: 4, textAlign: "center" }}>
                                        <NoResult
                                            icon={pageIcon}
                                            title={"No record found."}
                                            subtitle={"Please connect your account with social media plateform to manage your posts."}
                                            btn={false}
                                        />
                                    </Box>
                                </>
                            }
                        </Box>

                        {posts.length > 0 ?
                            <Box sx={{ display: "flex", justifyContent: "space-between", gap: "10px", flexWrap: "wrap", p: 2, alignItems: "center" }}>
                                <Typography variant='body2' component={"div"} sx={{ mb: 0 }}>Showing {posts.length} of {totalOrders} orders</Typography>
                                <Pagination count={count} page={params.page} showFirstButton showLastButton onChange={handleChangePage} />
                            </Box>
                            : null
                        }
                    </Card>

                    <PopupModal
                        open={openModal}
                        ModalClose={ModalClose}
                        title={title}
                        hideFooter={false}
                        cancelbtn={showList ? true : false}
                        cancelbtnTxt="Cancel"
                        savebtn={true}
                        savebtnTxt={showList ? "Publish" : "Got it"}
                        savebtnFunct={savebtnFunct}
                        loadingBtn={loadingBtn}
                        size="xl"
                    >
                        {showList ?
                            <>
                                {list.filter((x) => x.is_connected).map((opt, i) => {
                                    return (
                                        <Card sx={{ mb: 2 }} key={i}>
                                            <Accordion expanded={opt.checked}>
                                                <CardHeader
                                                    sx={{ backgroundColor: prefersDarkMode ? "#212121" : "#fafafa" }}
                                                    avatar={
                                                        <Avatar src={opt?.logo} variant="square" sx={{ width: 24, height: 24 }} />
                                                    }
                                                    action={
                                                        <FormGroup>
                                                            <FormControlLabel control={
                                                                <Switch checked={opt.checked} onChange={(e) => handleSwitch(e, opt)} />
                                                            } />
                                                        </FormGroup>
                                                    }
                                                    title={opt.account_details?.name || opt.media_source_name}
                                                />

                                                <CardContent>
                                                    <Grid container spacing={5} columns={12}>
                                                        <Grid item md={6} xs={12}>
                                                            {opt.media_source_name == "Youtube" ?
                                                                <TextField
                                                                    id="outlined-multiline-flexible"
                                                                    label="Video title *"
                                                                    fullWidth
                                                                    onChange={(e) => handleChange(e, opt)}
                                                                    name="title"
                                                                    value={value[opt.social_media_id]?.title}
                                                                    sx={{ mb: 4 }}
                                                                    error={showError && !value[opt.social_media_id]?.title}
                                                                    helperText={showError && !value[opt.social_media_id]?.title ? "Can't be blank" : ""}
                                                                />
                                                                : null
                                                            }
                                                            <TextField
                                                                id="outlined-multiline-flexible"
                                                                label="Caption"
                                                                multiline
                                                                fullWidth
                                                                rows={6}
                                                                inputRef={el => inputRef.current[i] = el}
                                                                onFocus={(e) => handleFocus(e, i)}
                                                                autoFocus
                                                                onChange={(e) => handleChange(e, opt)}
                                                                name="caption"
                                                                value={value[opt.social_media_id]?.caption}
                                                                error={showError && !value[opt.social_media_id]?.caption}
                                                                helperText={showError && !value[opt.social_media_id]?.caption ? "Can't be blank" : ""}
                                                            />
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    backgroundColor: prefersDarkMode ? "#212121" : "#f9f9f9",
                                                                    borderRadius: "0 0 10px 10px",
                                                                    '& svg': {
                                                                        m: 1,
                                                                    },
                                                                }}
                                                            >
                                                                <IconButton className='noHover' color='primary' onClick={(e) => handleClick2(e, "emoji", i)}>
                                                                    <EmojiEmotionsOutlined />
                                                                </IconButton>
                                                                <Popover
                                                                    id={"open1"}
                                                                    open={openId.open && openId.index == i}
                                                                    anchorEl={anchorEl.emoji}
                                                                    onClose={handleClose}
                                                                    anchorOrigin={{
                                                                        vertical: 'top',
                                                                        horizontal: 'left',
                                                                    }}
                                                                    transformOrigin={{
                                                                        vertical: 'bottom',
                                                                        horizontal: 'left',
                                                                    }}
                                                                >
                                                                    <Box sx={{ maxWidth: 300 }}>
                                                                        {emojis.map((emo, ind) => {
                                                                            return (
                                                                                <Button sx={{ minWidth: "auto", p: "3px" }} key={ind} onClick={() => clickemoji(emo, opt, i)}>
                                                                                    {emo}
                                                                                </Button>
                                                                            )
                                                                        })}
                                                                    </Box>
                                                                </Popover>
                                                                <Divider orientation="vertical" variant="middle" flexItem sx={{ borderColor: 'divider' }} />
                                                                <Button variant="text" startIcon={<Image />} className="add_media">
                                                                    {/* <Box className="add_media"> */}
                                                                    {opt.media_source_name == "Youtube" ?
                                                                        <input
                                                                            accept="video/mp4,video/x-m4v,video/*"
                                                                            type='file'
                                                                            onChange={!previewVideo ? onSelectVideoFile : null}
                                                                            disabled={previewVideo}
                                                                        />
                                                                        :
                                                                        <input
                                                                            multiple
                                                                            accept=".jpg,.jpeg,.png,.webp,.svg,video/mp4,video/x-m4v,video/*"
                                                                            type='file'
                                                                            onChange={preview.length < 4 ? onSelectFile : null}
                                                                            disabled={preview.length > 3}
                                                                        />
                                                                    }
                                                                    <label>
                                                                        Add media
                                                                    </label>
                                                                </Button>

                                                            </Box>
                                                            {showError && opt.checked && !previewVideo ?
                                                                <Typography variant="caption" color={"error"} component="div" sx={{ mb: 0, mt: 2 }}>
                                                                    Add media file
                                                                </Typography>
                                                                : null
                                                            }
                                                            {opt.media_source_name !== "Youtube" && preview.length ?
                                                                <Box sx={{ mt: 2 }}>
                                                                    {preview.map((med, i) => {
                                                                        return (
                                                                            <Badge
                                                                                sx={{ mr: 2 }}
                                                                                key={i}
                                                                                overlap="circular"
                                                                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                                                badgeContent={
                                                                                    <IconButton sx={{ backgroundColor: "#ff0000 !important", color: "#fff", width: 20, height: 20, top: 10, right: -10 }} onClick={() => deleteimage(i)}>
                                                                                        <HighlightOff />
                                                                                    </IconButton>
                                                                                }
                                                                            >
                                                                                <BigAvatarLG alt="" src={med || ""} />
                                                                            </Badge>
                                                                        )
                                                                    })}

                                                                </Box>
                                                                : null
                                                            }
                                                            {opt.media_source_name == "Youtube" && previewVideo ?
                                                                <Box sx={{ mt: 2 }}>
                                                                    <Badge
                                                                        sx={{ mr: 2 }}
                                                                        overlap="circular"
                                                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                                        badgeContent={
                                                                            <IconButton sx={{ backgroundColor: "#ff0000 !important", color: "#fff", width: 20, height: 20, top: 10, right: -10 }} onClick={deleteVideo}>
                                                                                <HighlightOff />
                                                                            </IconButton>
                                                                        }
                                                                    >

                                                                        <BigAvatarLG alt="" src={""} sx={{ backgroundColor: "#000" }}>
                                                                            <PlayArrow />
                                                                        </BigAvatarLG>
                                                                    </Badge>
                                                                </Box>
                                                                : null
                                                            }
                                                            {/* <Box sx={{ mt: 3 }}>

                                                    <LoadingButton
                                                        sx={{ whiteSpace: 'nowrap' }}
                                                        color="primary"
                                                        className='float_btn'
                                                        onClick={publishPost}
                                                        loading={loadingBtn}
                                                        loadingIndicator="Please wait..."
                                                        variant="contained"
                                                    >
                                                        Publish
                                                    </LoadingButton>
                                                </Box> */}
                                                        </Grid>
                                                        <Grid item md={6} xs={12}>
                                                            <Box sx={{ backgroundColor: prefersDarkMode ? "#212121" : "#eee", p: 2 }}>
                                                                <Typography variant="subtitle2" component={"div"}>Preview</Typography>
                                                                <Typography variant="caption" component={"div"}>Your post might be look different once live.</Typography>
                                                                {opt.media_source_name == "Youtube" ?
                                                                    <Card sx={{ maxWidth: 345, p: 2, mt: 1 }}>
                                                                        <Box className='preview_social youtube'>
                                                                            <video autoPlay loop playsInline muted src={previewVideo}></video>
                                                                        </Box>
                                                                        <CardContent sx={{ p: 0, py: 2 }}>
                                                                            <Typography variant="p" component={"strong"} sx={{ mb: 0 }}>
                                                                                {value[opt.social_media_id]?.title || "Video title here"}
                                                                            </Typography>
                                                                        </CardContent>
                                                                        <Divider />
                                                                        <CardActions sx={{ py: 2 }}>
                                                                            <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                                                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                                                                    <BigAvatar alt="" src={opt?.account_details?.profile_image_url || opt?.account_details?.picture || ""} />
                                                                                    <Typography variant="p" component="div" sx={{ m: 0, ml: 1 }}>{opt.account_details?.name || opt.media_source_name}</Typography>
                                                                                </Box>
                                                                                <Box className='subscribe_btn'>
                                                                                    Subscribe
                                                                                </Box>
                                                                            </Box>
                                                                        </CardActions>
                                                                        <Typography variant="subtitle" component={"div"} sx={{ mb: 0 }}>
                                                                            {value[opt.social_media_id]?.caption || ""}
                                                                        </Typography>
                                                                    </Card>
                                                                    :
                                                                    <Card sx={{ maxWidth: 345, p: 2, mt: 1 }}>
                                                                        <Box sx={{ display: "flex", width: "100%" }}>
                                                                            <Box sx={{ flex: "0 0 35px" }}>
                                                                                <BigAvatar alt="" sx={{ width: 25, height: 25 }} src={opt?.account_details?.profile_image_url || opt?.account_details?.picture || ""} />
                                                                            </Box>
                                                                            <Box>
                                                                                <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                                                                                    <Typography variant="subtitle2" component="div" sx={{ m: 0 }}>{opt.account_details?.name || opt.media_source_name}</Typography>
                                                                                    <Typography variant="caption" color={"#868aa5"} component="div" sx={{ m: 0, ml: 1 }}>@{opt.account_details?.screen_name || ""}</Typography>
                                                                                    <Typography variant="caption" color={"#868aa5"} component="div" sx={{ m: 0, ml: 1 }}>Just now</Typography>
                                                                                </Box>

                                                                                <Typography variant="subtitle" component={"div"} sx={{ mb: 1 }}>
                                                                                    {value[opt.social_media_id]?.caption || ""}
                                                                                </Typography>

                                                                                <Box className='preview_social twitter' data-length={preview.length}>
                                                                                    {/* <CardMedia
                                                                    component="img"
                                                                    height="200"
                                                                    image={preview}
                                                                    alt=""
                                                                /> */}
                                                                                    {preview.map((img, ind) => {
                                                                                        return (
                                                                                            <Box key={img} className='preview_social_img'>

                                                                                                {img.startsWith("blob:") ?
                                                                                                    <video autoPlay loop playsInline muted src={img}></video>
                                                                                                    :
                                                                                                    <img src={img} alt='' />
                                                                                                }
                                                                                            </Box>
                                                                                        )
                                                                                    })}
                                                                                </Box>
                                                                            </Box>
                                                                        </Box>
                                                                    </Card>
                                                                }
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </Accordion>
                                        </Card>
                                    )
                                })}
                            </>
                            :
                            progressRes.length ?
                                <Box>
                                    {/* <Typography variant="subtitle2" component="div" sx={{ mb: 0 }}>Here is your publishing progress</Typography> */}
                                    {progressRes.map((opt, i) => {
                                        { console.log("progress opt:", opt) }
                                        return (
                                            <Card key={i} sx={{ my: 2 }}>
                                                <CustomCard
                                                    sx={{ backgroundColor: prefersDarkMode ? "#212121" : "#fafafa" }}
                                                    avatar={
                                                        <Box>
                                                            {opt.media_source_name == "Youtube" ?
                                                                <Box className='preview_social youtube post'>
                                                                    <img src={opt?.data?.snippet?.thumbnails?.default?.url} alt="" width="80" style={{ border: prefersDarkMode ? "1px solid #212121" : "1px solid #eee", borderRadius: "3px", maxHeight: "80px", cursor: "pointer" }} />
                                                                    <PlayCircle />
                                                                </Box>
                                                                :
                                                                <Box className='preview_social youtube post'>
                                                                    <Badge
                                                                        overlap="circular"
                                                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                                        badgeContent={
                                                                            <SmallAvatar alt="" src={opt?.logo} />
                                                                        }
                                                                    >
                                                                        <BigAvatar>
                                                                            <Image />
                                                                        </BigAvatar>
                                                                    </Badge>
                                                                </Box>
                                                            }
                                                        </Box>
                                                    }
                                                    action={
                                                        // <Button
                                                        //     size="small"
                                                        //     color={opt.loading ? "info" : opt.error ? "error" : "primary"}
                                                        //     variant="text"
                                                        //     className='noHover'
                                                        // // startIcon={opt.loading ? <RemoveCircle /> : opt.error ? <Error /> : <CheckCircle />}
                                                        // >
                                                        //     {opt.loading ? "Processing" : opt.error ? "Failed" : "Published"}
                                                        // </Button>
                                                        <>
                                                            <Box sx={{ textAlign: "right" }}>
                                                                <Chip label={opt.loading ? "Processing" : opt.error ? "Failed" : "Published"} color={opt.loading ? "info" : opt.error ? "error" : "success"} />
                                                                <Typography variant="subtitle2" component="div" sx={{ mt: 1 }}>
                                                                    {moment(opt?.data?.snippet?.publishedAt || opt.createdAt).format("lll")}
                                                                </Typography>
                                                            </Box>
                                                            {opt.error && opt.errorMsg ?
                                                                <Alert severity="error" sx={{ mt: 2 }}>{opt.errorMsg}</Alert>
                                                                :
                                                                null
                                                            }
                                                        </>
                                                    }
                                                    // title={opt.account_details?.name || opt.media_source_name}
                                                    title={
                                                        <Box sx={{ pl: 2 }}>
                                                            <Typography variant="body1" component="div" sx={{
                                                                mb: 0, overflow: "hidden",
                                                                textOverflow: "ellipsis",
                                                                display: "-webkit-box",
                                                                "-webkit-line-clamp": "1",
                                                                "-webkit-box-orient": "vertical"
                                                            }}>
                                                                {opt?.data?.snippet?.title || opt?.data?.text}
                                                            </Typography>
                                                            {opt?.data?.tweetUrl ?
                                                                <Typography variant="caption" component="div" sx={{ opacity: 0.5 }}>
                                                                    {opt?.data?.tweetUrl}
                                                                </Typography>
                                                                : null
                                                            }
                                                            <Typography variant="subtitle2" component="div" sx={{ display: "flex", alignItems: "center", mt: 0.5 }}>
                                                                {opt?.media_source_name == "Youtube" ?
                                                                    <Avatar src={opt?.logo} variant="square" sx={{ width: "auto", maxHeight: 10, mr: 1 }} />
                                                                    : "@"
                                                                }
                                                                {opt?.account_details?.name}
                                                            </Typography>
                                                        </Box>
                                                    }
                                                />

                                            </Card>
                                        )
                                    })}
                                    {loadingBtn ?
                                        <Skeleton variant="text" height={60} sx={{ mt: 2 }} width={"100%"} />
                                        : null
                                    }
                                </Box>
                                :
                                <Skeleton variant="text" height={60} width={"100%"} />
                        }

                    </PopupModal>

                    <DialogAlert
                        showDialog={showDialog}
                        closeDialog={closeDialog}
                        content={content}
                        confirmDialog={confirmDialog}
                    />
                </Container >
            }
        </>
    )
}
