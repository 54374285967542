import React from "react";
import { Box, Typography } from "@mui/material";
import MiniEditor from "../../../../common/CustomEditor/MiniEditor";

export default function TextEditor({ data, editorState, updateTextDescription, onContentStateChange, onEditorBlur }) {
    console.log("data TextEditor: ", data)
    return (
        <Box sx={{ pb: 3 }}>
            <Typography variant="caption" component="div" sx={{ p: "0 0 0 10px", mb: 0, display: "block" }}>
                {data.label}
            </Typography>
            <MiniEditor
                editorState={editorState}
                onContentStateChange={(e)=>onContentStateChange(e, data.name)}
                updateTextDescription={(e)=>updateTextDescription(e, data.name)}
                onEditorBlur={(e, editorState)=>onEditorBlur(e, editorState, data.name)}
            />
        </Box>
    )
}