import React, { useState } from "react";
import { styled } from '@mui/material/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Delete, PhotoCamera } from "@material-ui/icons";
import CheckIcon from '@mui/icons-material/Check';
import { Box } from "@mui/system";
import { Card, CardContent, Typography, CardMedia, CardActions, Button, TextField, Divider, IconButton, ImageList, ImageListItem, ImageListItemBar } from "@mui/material";
import gallery from "../../gallery";
import * as Api from "../../../../../Container/config/Apis";
import config from "../../../../../Container/config";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useSelector } from "react-redux";

export default function ImagePicker({ data, doneUpload, handleAltChange }) {
    const [itemData, setItemData] = useState([]);
    const dataVal = useSelector((state) => state.dataValue)
    const { enqueueSnackbar } = useSnackbar();
    const snack = (msg, variant) => {
        enqueueSnackbar(msg, { variant });
    }
    const [activeImage, setActiveImage] = useState("")
    const showUploader = (id) => {
        document.getElementById(id).classList.add("active")
    }
    const backToImage = (id) => {
        document.getElementById(id).classList.remove("active")
    }
    const Input = styled('input')({
        display: 'none',
    });

    const clickImage = (index, img) => {
        let newArr = []
        itemData.map((opt) => {
            newArr.push({ ...opt, 'current': false })
        })
        newArr[index].current = true
        setItemData(newArr)
        setActiveImage(img)
        doneUpload(data?.id, img)
    }

    const getAssets = (type) => {
        Api.GetApi(`${config.TEMPLATE_GALLERY}?page=1`).then(async res => {
            if (res.error === true) {
                snack(res.response.data.error, 'error');
            } else {
                if (res.data.data) {
                    if (type == "image") {
                        let newArr = []
                        res.data.data.map((opt) => {
                            newArr.push({ ...opt, 'current': false })
                        })
                        newArr[0].current = true
                        console.log("newArr: ", newArr)
                        setItemData(newArr)
                        setActiveImage(newArr[0]?.path)
                        doneUpload(data?.id, newArr[0]?.path)
                    } else {
                        setItemData(res.data.data)
                    }
                }
            }
        })
    }
    const handleImageChange = (e) => {
        let reader = new FileReader();
        let file = e.target.files[0];
        console.log("file: ", file)
        reader.onloadend = () => {
            let data = new FormData();
            data.append("gallery", file)
            Api.PostApiFormdata(config.TEMPLATE_GALLERY1, data).then(async res => {
                if (res.error === true) {
                    snack(res.response.data.error, 'error');
                } else {
                    if (res.data.data) {
                        // setActiveImage(reader.result)
                        // if (dataVal && (dataVal?.store_id || dataVal?.store?.storeId)) {
                        getAssets("image")
                        // }
                    }
                }
            })
        }
        reader.readAsDataURL(file)
    }

    useEffect(() => {
        // if (dataVal && (dataVal?.store_id || dataVal?.store?.storeId)) {
        getAssets("")
        // }
    }, [])

    console.log("image data:111111111", data)
    console.log("itemData", itemData)
    return (
        <Box sx={{ mb: 3 }}>
            <Box id={data.id} className="uploader_outer">
                <Card sx={{ pb: 1 }} className="pre_uploader">
                    <Box sx={{ p: 1, position: "relative", background: `var(--white)` }}>
                        {data.value && data.value !== "" ?
                            <>
                                <CardMedia
                                    component="img"
                                    width={data.width}
                                    image={data.value}
                                    alt={data.alt}
                                    sx={{ maxHeight: data.height, objectFit: "contain" }}
                                />
                                <CardActions sx={{ px: 2 }} className="img_delete_editor">
                                    {/* <Button fullWidth variant="contained" onClick={() => doneUpload(data.id, "")}>Remove</Button> */}
                                    <IconButton className='img_delete' color="error" onClick={() => doneUpload(data.id, "")}><Delete /></IconButton>
                                </CardActions>
                            </>
                            :
                            <Typography variant="p" component="strong" sx={{ p: 1 }}>
                                No image found!
                            </Typography>
                        }
                    </Box>
                    <Divider sx={{ pt: 0, mb: 1 }} />
                    {data.note !== "" &&
                        <CardContent sx={{ p: 1 }}>
                            <Typography variant="caption" component="div">
                                <i>{data.note}</i>
                            </Typography>
                            {/* <TextField
                            label="Alt text"
                            defaultValue={data.alt}
                            onChange={(e) => handleAltChange(e, data.alt_name)}
                            variant="outlined"
                            fullWidth
                            size="small"
                        /> */}
                        </CardContent>
                    }
                    <CardActions sx={{ px: 2 }}>
                        <Button fullWidth variant="contained" onClick={() => showUploader(data.id)}>Change</Button>
                    </CardActions>
                </Card>
                <Card sx={{ p: 2 }} className="post_uploader">
                    <Box sx={{ display: 'flex', justifyContent: "space-between", width: "100%" }}>
                        <Button startIcon={<ArrowBackIcon />} onClick={() => backToImage(data.id)}>
                            Back
                        </Button>
                        {/* <Button variant="contained" disabled={activeImage === "" ? true : false} onClick={() => doneUpload(data.id, activeImage)}>
                            Done
                        </Button> */}
                    </Box>
                    <Divider sx={{ pt: 1, mb: 1 }} />
                    <Box sx={{ border: "#ccc 2px dashed", textAlign: 'center', p: 1, cursor: 'pointer' }}>
                        <label htmlFor={`img_${data.id}`}>
                            <Input accept="image/*" id={`img_${data.id}`} type="file" onChange={handleImageChange} />
                            {activeImage === "" ?
                                <>
                                    <IconButton color="primary" aria-label="upload picture" component="span">
                                        <PhotoCamera />
                                    </IconButton>
                                    <Typography variant="button" gutterBottom component="div">
                                        Upload new
                                    </Typography>
                                </>
                                :
                                <Box className="edit_selected_image">
                                    <PhotoCamera fontSize="large" />
                                    <img src={activeImage} alt="" className="img-fluid" width={"100%"} />
                                </Box>
                            }
                        </label>
                    </Box>
                    <Box sx={{ width: '100%', maxHeight: 250, overflowY: 'auto', my: 1 }}>
                        {itemData.length > 0 ?
                            <ImageList variant="masonry" cols={3} gap={4} sx={{ mt: 0 }}>
                                {itemData.map((item, index) => (
                                    <ImageListItem key={index} onClick={() => clickImage(index, item?.path)} sx={{ background: "#d9d3d3", padding: "2px" }}>
                                        <img
                                            src={`${item?.path}?w=248&fit=crop&auto=format`}
                                            srcSet={`${item?.path}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                            alt={`Media${index + 1}`}
                                            loading="lazy"
                                        />
                                        {item.current &&
                                            <ImageListItemBar
                                                actionIcon={
                                                    <IconButton
                                                        sx={{ color: '#fff', p: 0 }}
                                                        aria-label={`info about ${item.title}`}
                                                    >
                                                        <CheckIcon />
                                                    </IconButton>
                                                }
                                            />
                                        }
                                    </ImageListItem>
                                ))}
                            </ImageList>
                            :
                            <Typography variant="button" component="div" sx={{ mt: 3 }}>No media added</Typography>
                        }
                    </Box>

                </Card>
            </Box>
        </Box>
    )
}