import React from 'react'
import { Box, Typography, Container, Grid, Card, CardContent } from '@mui/material';
import variables from '../../../../ThemeEditor/Schema/variables';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

export default function VariablesDoc() {
  return (
    <Container maxWidth="lg">
      <Box sx={{ mb: 2 }}>
        <Grid container spacing={2} columns={12}>
          <Grid item xs={6}>
            <Typography variant='h5' component={"div"}>Email variables reference</Typography>
          </Grid>
          <Grid item xs={6} sx={{ textAlign: { sm: 'right' }, flexShrink: { sm: 0 } }}>   </Grid>
        </Grid>
      </Box>
      <Box>
        <Grid container spacing={2} columns={12}>
          <Grid item md={12}>
            <Card sx={{ mb: 2 }}>
              <CardContent>
                {/* <Typography variant='h6' component={"div"} sx={{ mb: 2 }}>On this page</Typography>
                <ul>
                  {variables.map((item, i) => {
                    return (
                      <li key={i}><a href={`#${item.link}`}>{item.title}</a></li>
                    )
                  })}
                </ul> */}

                <Box>
                  {variables.map((item, i) => {
                    return (
                      <Box key={i} sx={{ pb: 3 }} id={item.link}>
                        <Typography variant='h6' component={"div"} sx={{mb:1}}>{item?.title}</Typography>
                        <Typography variant='p' component={"div"} sx={{mb:1}}>{item?.subtitle}</Typography>
                        <TableContainer>
                          <Table size="small" >
                            <TableHead>
                              <TableRow>
                                <TableCell padding="0">
                                  Variable
                                </TableCell>
                                <TableCell padding="0">
                                  Description
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {item.list.map((opt) => {
                                return (
                                  <TableRow key={opt.name}>
                                    <TableCell padding="0" width={"300px"}>
                                      <pre className='pre_tag'>{opt.name}</pre>
                                    </TableCell>
                                    <TableCell padding="0">
                                      {opt.description}
                                    </TableCell>
                                  </TableRow>
                                )
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box>
                    )
                  })}
                </Box>

              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Container>
  )
}
