import React, { useEffect, useState, } from 'react';
import {
    Box, Button, Grid, Card, CardContent, FormControl,
    Divider, Typography, TextField, Container, LinearProgress,
    Radio, RadioGroup, FormControlLabel, InputAdornment, FormHelperText, IconButton, Switch,
    FormGroup,
    Accordion,
    AccordionSummary,
    Avatar,
    Chip,
    AccordionDetails,
    useMediaQuery
} from '@mui/material';
import * as Api from "../../../../Container/config/Apis";
import config from '../../../../Container/config';
import { useSnackbar } from 'notistack';
import _ from "underscore";
import { decryption } from '../../../../modules/module';
import MaterialUISwitch from '../../../common/Switch/SwitchStyle';
import { IoEyeOffOutline, IoEyeOutline } from 'react-icons/io5';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import DoneIcon from '@mui/icons-material/Done';
import { useSelector } from 'react-redux';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import shiprocket_logo from "../../../../Assets/images/shiprocket_logo.svg";
import swift_logo from "../../../../Assets/images/swift_logo.svg";
import { NavLink } from 'react-router-dom';


const Partners = () => {
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const dataVal = useSelector((state) => state.dataValue)
    const { enqueueSnackbar } = useSnackbar();
    const snack = (msg, variant) => {
        enqueueSnackbar(msg, { variant });
    }
    const [loadingBar, setloadingBar] = React.useState(true);
    const [showError, setshowError] = React.useState(false);
    const [inputError, setInputError] = useState(false)
    const [srDetail, setSRDetail] = useState({})
    const [swiftDetail, setSwiftDetail] = useState({})
    const [showForm, setShowForm] = useState(false)
    const [showForm2, setShowForm2] = useState(false)
    const [showPassword, setshowPassword] = useState(false)
    const [showPassword2, setshowPassword2] = useState(false)
    const [body, setBody] = useState({
        shipping_method: "free",
    })
    const [input, setInput] = useState({
        email: "",
        password: ""
    })
    const [input2, setInput2] = useState({
        email: "",
        password: "",
        client_id: ""
    })
    const [value, setValue] = useState({
        charges: {
            charges: ""
        },
        minimum_cart_value: {
            charges: "",
            minimum_cart_value: ""
        },
    })

    const [shippingList, setshippingList] = useState([
        { data_id: 1, name: "Shiprocket", logo: shiprocket_logo, connected: false },
        { data_id: 2, name: "Goswift", logo: swift_logo, connected: false },
    ])

    const [shippingData, setshippingData] = useState([])
    const getShippingDetail = () => {
        Api.GetApi(config.SHIPPING).then(res => {
            setloadingBar(false)
            console.log("res SHIPPING: ", res)
            if (res.error === true) {
                snack(res.response.data.error, 'error');
            } else {
                if (res.data.data) {
                    setshippingData(res.data.data)
                    let arr = []
                    shippingList.map((opt) => {
                        let val = { ...opt }
                        res.data.data.filter((x) => x.shipping_source == opt.name).map((obj) => {
                            val = { ...val, ...obj }
                        })
                        arr.push(val)
                    })
                    setshippingList(arr)

                    // Api.GetApi(config.SHIPROCKET_DETAIL).then(res => {
                    //     console.log("res SHIPROCKET_detail: ", res)
                    //     if (res.error === true) {
                    //         snack(res.response.data.error, 'error');
                    //     } else {
                    //         if (res.data.data) {
                    //             setSRDetail(res.data.data)
                    //             setInput({ ...input, email: res.data.data.email, password: res.data.data.password })
                    //             setShowForm(false)
                    //         } else {
                    //             setShowForm(true)
                    //         }
                    //     }
                    // })
                }
            }
        })
    }

    useEffect(() => {
        shippingList.map((opt) => {
            if (opt.name == "Shiprocket") {
                if (opt.email && opt.password) {
                    setSRDetail(opt)
                    setInput({ ...input, email: opt.email, password: opt?.password })
                    setShowForm(false)
                } else {
                    setShowForm(true)
                }
            } else {
                if (opt.email && opt.password) {
                    setSwiftDetail(opt)
                    setInput2({ ...input2, email: opt.email, password: opt?.password, client_id: opt?.client_id || "" })
                    setShowForm2(false)
                } else {
                    setShowForm2(true)
                }
            }
        })
    }, [shippingList])


    const updateShipping = (data) => {
        Api.PutApi(config.SHIPPING_METHOD, data).then(res => {
            setloadingBar(false)
            console.log("res shipping: ", res)
            if (res.error === true) {
                snack(res.response.data.error, 'error');
            } else {
                if (res.data.data) {
                    snack(decryption(res.data.data), 'success');
                }
            }
        })
    }

    const handleInputChange = (e) => {
        setInput({ ...input, [e.target.name]: e.target.value })
        setInputError(false)
    }
    const handleInput2Change = (e) => {
        setInput2({ ...input2, [e.target.name]: e.target.value })
        setInputError(false)
    }

    const handleIsActive = (e, data) => {
        setSRDetail({ ...srDetail, is_active: !srDetail.is_active })
        statusUpdate(e.target.checked, data?.shipping_source)
    }
    const handleIsActive2 = (e, data) => {
        setSwiftDetail({ ...swiftDetail, is_active: !swiftDetail.is_active })
        statusUpdate(e.target.checked, data?.shipping_source)
    }
    const connectSR = () => {
        if (input?.email == "" || input?.password == "") {
            setInputError(true)
        } else {
            setInputError(false)
            Api.PostApi(config.SHIPROCKET_CONNECT, input).then(res => {
                console.log("res SHIPROCKET_CONNECT: ", res)
                if (res.error === true) {
                    snack(res.response.data.error, 'error');
                } else {
                    if (res.data.data) {
                        snack(res.data.data, 'success');
                        getShippingDetail()
                    }
                }
            })
        }
    }
    const connectSwift = () => {
        if (input2?.email == "" || input2?.password == "" || input2?.client_id == "") {
            setInputError(true)
        } else {
            setInputError(false)
            console.log("input2", input2)
            Api.PostApi(config.GOSWIFT_CONNECT, input2).then(res => {
                console.log("res GOSWIFT_CONNECT: ", res)
                if (res.error === true) {
                    snack(res.response.data.error, 'error');
                } else {
                    if (res.data.data) {
                        snack(res.data.data, 'success');
                        getShippingDetail()
                    }
                }
            })
        }
    }
    const statusUpdate = (status, name) => {
        Api.PutApi(config.SHIPPING_UPDATE, { is_active: status, shipping_source: name }).then(res => {
            console.log("res SHIPROCKET_UPDATE: ", res)
            if (res.error === true) {
                snack(res.response.data.error, 'error');
            } else {
                if (res.data.data) {
                    snack(res.data.data, 'success');
                    getShippingDetail()
                }
            }
        })
    }

    const saveShipping = () => {
        if (body.shipping_method == "charges" && (value.charges?.charges == "" || value.charges?.charges == 0)) {
            setshowError(true)
        } else if (body.shipping_method == "minimum_cart_value" && (value.minimum_cart_value?.charges == "" || value.minimum_cart_value?.minimum_cart_value == "" || value.minimum_cart_value?.charges == 0 || value.minimum_cart_value?.minimum_cart_value == 0)) {
            setshowError(true)
        } else {
            let data = {
                ...body,
                ...value[body.shipping_method]
            }
            console.log("data: ", data)
            updateShipping(data)
        }
    }
    const url = `https://api.${Api.appURL}/v1/webhook/${dataVal && dataVal?.store_id || dataVal?.store?.storeId || ""}`

    const [copied, setcopied] = useState(false)
    const copiedFn = (type) => {
        setcopied(true)
        setTimeout(() => {
            setcopied(false)
        }, 2000);
    }

    const [expanded, setExpanded] = React.useState(false);

    const handleAccordion =
        (panel) => (event, isExpanded) => {
            setExpanded(isExpanded ? panel : false);
        };

    console.log("body: ", body)
    console.log("value: ", value)
    console.log("srDetail: ", srDetail)
    console.log("swiftDetail: ", swiftDetail)
    console.log("showError: ", showError)
    console.log("shippingData: ", shippingData)
    console.log("shippingList: ", shippingList)

    useEffect(() => {
        getShippingDetail()
    }, []);

    return (
        <>
            {loadingBar ?
                <LinearProgress className="top_loader" color="info" sx={{ mt: "-25px", mb: "21px" }} />
                :
                null
            }
            <Container maxWidth="lg">
                <Box sx={{ mb: 2 }}>
                    <Grid container spacing={2} columns={12}>
                        <Grid item xs={6}>
                            <Typography variant="h5" component="div"> Delivery partners</Typography>
                        </Grid>
                        <Grid item xs={6} sx={{ textAlign: { sm: 'right' }, flexShrink: { sm: 0 } }}>   </Grid>
                    </Grid>
                </Box>
                <Box>
                    {shippingList.map((data, index) => {
                        return (
                            <Accordion disableGutters key={index} expanded={expanded === data.data_id} onChange={handleAccordion(data.data_id)}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ minHeight: "60px" }}
                                    aria-controls={`panel${index}-content`} id={`panel1a-header${index}`}>
                                    <Box sx={{ display: "flex", alignItems: "center", }}>
                                        <Avatar src={data.logo} sx={{ width: "100px", height: "inherit", maxHeight: '90px', maxWidth: '100px', filter: prefersDarkMode ? "invert(1)":"none" }} variant="square" />
                                        {data.is_active ?
                                            // <CheckOutlinedIcon color="success" sx={{ ml: 2, }} /> 
                                            <Chip label={"Connected"} size='small' color='success' sx={{ letterSpacing: 0.1, fontSize: "10px", ml: 3, cursor: "pointer" }} />
                                            : null}
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails sx={{ borderTop: "1px solid #ddd" }}>
                                    {data.name == "Shiprocket" ?
                                        <>
                                            <Box sx={{ mb: 0, display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
                                                <Box>
                                                    <Typography variant="h6" component="div" gutterBottom>{data?.name}</Typography>
                                                </Box>
                                                {!_.isEmpty(srDetail) && srDetail?.email !== "" && srDetail?.password !== "" ?
                                                    <Box>
                                                        <FormGroup>
                                                            <FormControlLabel control={
                                                                <Switch checked={srDetail.is_active} onChange={(e) => handleIsActive(e, data)} />
                                                            } label={srDetail.is_active ? "Enabled" : "Disabled"} />
                                                        </FormGroup>
                                                    </Box>
                                                    : null

                                                }
                                            </Box>
                                            {!showForm ?
                                                <Box>
                                                    <Typography variant="p" component="strong" gutterBottom>{srDetail.email}</Typography>
                                                    <Button sx={{ ml: 2 }} onClick={() => setShowForm(true)}>Edit</Button>
                                                </Box>
                                                :
                                                <Grid container spacing={2} columns={12}>
                                                    <Grid item md={6}>
                                                        <Typography sx={{ mb: 2 }} variant="caption" component="div" gutterBottom>Enter your shiprocket account details to connect with your store.</Typography>
                                                        <FormControl fullWidth sx={{ mb: 3 }} >
                                                            <TextField
                                                                label="Enter email address"
                                                                type="email"
                                                                id="email"
                                                                name="email"
                                                                size="small"
                                                                value={input.email}
                                                                onChange={handleInputChange}
                                                            />
                                                            {inputError && input.email == "" ?
                                                                <FormHelperText error={true}>Email can't be blank</FormHelperText>
                                                                : null
                                                            }
                                                        </FormControl>
                                                        <FormControl fullWidth sx={{ mb: 2 }} >
                                                            <Box sx={{ display: "flex" }}>
                                                                <TextField
                                                                    label="Enter password"
                                                                    type={showPassword ? "text" : "password"}
                                                                    id="password"
                                                                    name="password"
                                                                    size="small"
                                                                    value={input.password}
                                                                    onChange={handleInputChange}
                                                                    sx={{ width: "100%" }}
                                                                />
                                                                <IconButton sx={{ ml: 2 }} onClick={() => setshowPassword(!showPassword)}>
                                                                    {showPassword ?
                                                                        <IoEyeOutline />
                                                                        :
                                                                        <IoEyeOffOutline />
                                                                    }
                                                                </IconButton>
                                                            </Box>
                                                            {inputError && input.password == "" ?
                                                                <FormHelperText error={true}>Password can't be blank</FormHelperText>
                                                                : null
                                                            }
                                                        </FormControl>
                                                        <Box sx={{ textAlign: "left", mb: 2 }}>
                                                            <Button variant="contained" color="primary" onClick={connectSR}>{srDetail?.email && srDetail?.password ? "Update" : "Connect"}</Button>
                                                            {srDetail && srDetail?.email && srDetail?.password ?
                                                                <Button variant="outlined" sx={{ ml: 2 }} color="error" onClick={() => setShowForm(false)}>Cancel</Button>
                                                                : null
                                                            }
                                                        </Box>
                                                        <Typography variant="body2" component="div" gutterBottom>Note: Add following URL on your Shiprocket account to track your shipment details.
                                                            {/* <br /><a href="/" className='link'>Click here</a> to learn more. */}
                                                        </Typography>
                                                        <Typography variant="button" component="div" gutterBottom sx={{ wordBreak: "break-all" }}>
                                                            {url} {" "}
                                                            <CopyToClipboard text={url} onCopy={() => copiedFn('email')}>
                                                                {copied ?
                                                                    <DoneIcon sx={{ width: "15px" }} />
                                                                    :
                                                                    <ContentCopyOutlinedIcon sx={{ width: "15px" }} />
                                                                }
                                                            </CopyToClipboard>
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            }
                                        </>
                                        :
                                        <>
                                            <Box sx={{ mb: 0, display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
                                                <Box>
                                                    <Typography variant="h6" component="div" gutterBottom>{data.name}</Typography>
                                                </Box>
                                                {!_.isEmpty(swiftDetail) && swiftDetail?.email !== "" && swiftDetail?.password !== "" ?
                                                    <Box>
                                                        <FormGroup>
                                                            <FormControlLabel control={
                                                                <Switch checked={swiftDetail.is_active} onChange={(e) => handleIsActive2(e, data)} />
                                                            } label={swiftDetail.is_active ? "Enabled" : "Disabled"} />
                                                        </FormGroup>
                                                    </Box>
                                                    : null

                                                }
                                            </Box>
                                            {!showForm2 ?
                                                <Box>
                                                    <Typography variant="p" component="strong" gutterBottom>{swiftDetail.email}</Typography>
                                                    <Button sx={{ ml: 2 }} onClick={() => setShowForm2(true)}>Edit</Button>
                                                </Box>
                                                :
                                                <Grid container spacing={2} columns={12}>
                                                    <Grid item md={6}>
                                                        <Typography sx={{ mb: 2 }} variant="caption" component="div" gutterBottom>Before connect please make sure you have entered your GST details <NavLink to="/admin/settings/taxes" className={"link"}>here.</NavLink></Typography>
                                                        <FormControl fullWidth sx={{ mb: 3 }} >
                                                            <TextField
                                                                label="Enter client ID"
                                                                type="text"
                                                                id="client_id"
                                                                name="client_id"
                                                                size="small"
                                                                value={input2.client_id}
                                                                onChange={handleInput2Change}
                                                            />
                                                            {inputError && input2.client_id == "" ?
                                                                <FormHelperText error={true}>Client ID can't be blank</FormHelperText>
                                                                : null
                                                            }
                                                        </FormControl>
                                                        <FormControl fullWidth sx={{ mb: 3 }} >
                                                            <TextField
                                                                label="Enter email address"
                                                                type="email"
                                                                id="email"
                                                                name="email"
                                                                size="small"
                                                                value={input2.email}
                                                                onChange={handleInput2Change}
                                                            />
                                                            {inputError && input2.email == "" ?
                                                                <FormHelperText error={true}>Email can't be blank</FormHelperText>
                                                                : null
                                                            }
                                                        </FormControl>
                                                        <FormControl fullWidth sx={{ mb: 2 }} >
                                                            <Box sx={{ display: "flex" }}>
                                                                <TextField
                                                                    label="Enter password"
                                                                    type={showPassword2 ? "text" : "password"}
                                                                    id="password"
                                                                    name="password"
                                                                    size="small"
                                                                    value={input2.password}
                                                                    onChange={handleInput2Change}
                                                                    sx={{ width: "100%" }}
                                                                />
                                                                <IconButton sx={{ ml: 2 }} onClick={() => setshowPassword2(!showPassword2)}>
                                                                    {showPassword2 ?
                                                                        <IoEyeOutline />
                                                                        :
                                                                        <IoEyeOffOutline />
                                                                    }
                                                                </IconButton>
                                                            </Box>
                                                            {inputError && input2.password == "" ?
                                                                <FormHelperText error={true}>Password can't be blank</FormHelperText>
                                                                : null
                                                            }
                                                        </FormControl>
                                                        <Box sx={{ textAlign: "left", mb: 2 }}>
                                                            <Button variant="contained" color="primary" onClick={connectSwift}>{swiftDetail?.email && swiftDetail?.password ? "Update" : "Connect"}</Button>
                                                            {swiftDetail && swiftDetail?.email && swiftDetail?.password ?
                                                                <Button variant="outlined" sx={{ ml: 2 }} color="error" onClick={() => setShowForm2(false)}>Cancel</Button>
                                                                : null
                                                            }
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            }
                                        </>

                                    }
                                </AccordionDetails>
                            </Accordion>
                        )
                    })}


                </Box>
            </Container>



        </>
    );
}

export default Partners