import React from "react";
import errorPage from "../../../../../../../../Assets/images/404page3.svg";
import * as heading from "../Typography/Exports"

export default function NoPageFE() {
    return (
        <div className="py-5 text-center">
            <div sx={{ textAlign: "center" }}>
                <img src={errorPage} style={{ width: 400, height: 300, margin: "auto" }} />
            </div>
            <heading.Headings
                option={"h5"}
                ishtml={false}
                type="div"
                data={"There's no page at this address"}
                classname="mt-3"
            />
            <heading.Button
                type="a"
                link={"/"}
                data={"Back to home"}
                classname="btn btn-sm btn-primary mt-3"
            />
        </div>
    )
}