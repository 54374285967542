import React from "react";
import { Avatar, Button, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import { Box } from "@mui/system";

export default function NoResult(props) {
    return (
        <Box sx={{ p: 5, textAlign: "center" }} className={"NoResult_block"}>
            <Avatar sx={{ width: 120, height: 120, margin: "0 auto 20px" }} variant="square" src={props.icon}></Avatar>
            <Typography variant="h6" component={"div"} sx={{ mb: 1 }}>{props.title}</Typography>
            {props.subtitle ?
                <Typography variant="p" component={"div"}>{props.subtitle}</Typography>
                : null
            }
            {props.btn ?
                <Button variant="contained" sx={{ mt: 2 }} color="primary" component={NavLink} to={props.link}>{props.btnText}</Button>
                : null
            }
        </Box>
    )
}