import React, { useCallback, useEffect, useState } from "react"
import { ListItem, ListItemButton, ListItemIcon, ListItemText, List, Button } from "@mui/material";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { renderImport } from "../../../../../Schema/Exports";
import { ImageCard } from "../../../../../../Pages/Product/ImageCard";
import update from "immutability-helper";
import { Box } from "@mui/system";
import _ from "underscore";

export default function ListItems({ data, addNewItem, editItem, array, activeDetail, disableBtn, deleteItem, moveOption, confirmDelete, cancelDelete, isDelete }) {
    const params = new URLSearchParams(window.location.search)
    console.log("activeDetail: ", activeDetail)
    console.log("activeDetail array: ", array)
    const [list, setList] = useState([])
    // const moveOption = useCallback((dragIndex, hoverIndex) => {
    //     console.log(dragIndex, hoverIndex)
    //     setList((prevCards) =>
    //         update(prevCards, {
    //             $splice: [
    //                 [dragIndex, 1],
    //                 [hoverIndex, 0, prevCards[dragIndex]]
    //             ]
    //         })
    //     )
    // }, [list])

    useEffect(() => {
        setList(array)
        // if(document.querySelectorAll(".item_drag.active_item").length > 0){
        //     document.querySelector
        // }
    }, [array])

    console.log("array: ", array)
    console.log("list: ", list)

    return (
        <List sx={{ m: 0, p: 0, pb: 2 }} className="list_items">
            {list.length > 0 && _.sortBy(list, "position").map((opt, index) => {
                return (
                    <React.Fragment key={index}>
                        <ListItem disablePadding className={`item_drag ${params.get('item') == opt.id || params.get('column') == opt.id ? "active_item" : "drag_item"}`}>
                            {isDelete == opt?.id ?
                                <ListItemButton sx={{ p: 1, width: "100%", borderBottom: '#e1e1e1 1px solid', display: "flex", justifyContent: "space-between" }}>
                                    <Button variant="outlined" size="small" fullWidth onClick={() => confirmDelete(opt)}>Confirm</Button>
                                    <Button variant="contained" size="small" fullWidth color="error" sx={{ ml: 2 }} onClick={cancelDelete}>Dismiss</Button>
                                </ListItemButton>
                                :
                                <ImageCard
                                    index={index}
                                    id={opt.id}
                                    text={
                                        <ListItemButton sx={{ p: 0.5, width: "100%", borderBottom: '#e1e1e1 1px solid' }}>
                                            <ListItemIcon sx={{ minWidth: "30px" }}>
                                                <DragIndicatorIcon fontSize="small" />
                                            </ListItemIcon>
                                            {/* <ListItemText primary={`${data.label} ${opt.id}`} /> */}
                                            <ListItemText primary={`${data?.label} ID: ${opt.id}`} sx={{color: `var(--black)`}} />
                                            <ListItemIcon>
                                                {params.get('item') == opt.id || params.get('column') == opt.id ?
                                                    <CloseIcon fontSize="inherit" color="error" sx={{ minWidth: "30px" }} onClick={() => editItem(opt, "close")} />
                                                    :
                                                    <>
                                                        <EditIcon fontSize="inherit" sx={{ minWidth: "30px" }} onClick={() => editItem(opt, "open")} />
                                                        <DeleteIcon fontSize="inherit" sx={{ minWidth: "30px" }} color="error" onClick={() => deleteItem(opt)} />
                                                    </>
                                                }
                                            </ListItemIcon>
                                        </ListItemButton>
                                    }
                                    moveCard={moveOption}
                                />
                            }
                        </ListItem>
                        {params.get('item') == opt.id || params.get('column') == opt.id ?
                            <Box sx={{ pt: 2 }}>
                                {renderImport(activeDetail)}
                            </Box>
                            : null
                        }
                    </React.Fragment>
                )
            })}
            {!disableBtn && !params.get('item') &&
                <ListItem disablePadding>
                    <ListItemButton sx={{ p: 0.5, borderBottom: '#eee 1px solid' }} onClick={addNewItem}>
                        <ListItemIcon sx={{ minWidth: "30px" }}>
                            <AddCircleOutlineIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="Add new" sx={{color: `var(--black)`}} />
                    </ListItemButton>
                </ListItem>
            }
        </List>
    )
}